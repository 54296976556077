<template>
    <div class="mb-5">
        <div>{{ masterclass.id }}</div>
        <div>{{ masterclass.max_attendees }}</div>
    </div>
</template>

<script>
export default {
    name: 'MasterclassSubscriptionForm',
    props: {
        user: Object,
        masterclass: Object
    },
    data() {
        return {
            attendees: []
        }
    },
    created() {
        this.getAttendees()
    },
    methods: {
        getRemainingSeats: function() {

        },
        getAttendees: function() {
            axios.get('http://atelier.cycleurope-v8.mac/formations/records/' + this.masterclass.id)
                .then((response) => {
                    console.log(response.data)
                    this.attendees = response.data
                })
        }
    }
}
</script>