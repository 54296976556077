<template>
  <div class="Wrapper">
    <div class="row">
    <div v-show="searchType !== null" class="col-12 mb-3">
      <button class="btn btn-flat btn-primary" v-on:click="handleResetSearch">
        <magnify class="me-2"></magnify>Nouvelle recherche
      </button>
    </div>
    <div class="col-12">
      <search-type-selector v-show="!searchType"></search-type-selector>
    </div>
    <div v-show="searchType === 'byBike'" class="col-12">
      <div class="row">
        <div class="col-xl-3">
          <bom-header v-show="selectedBrand"></bom-header>
        </div>
        <div class="col-xl-9">
          <brand-selector
            v-if="brands.length"
            v-show="!selectedBrand"
          ></brand-selector>
          <group-selector
            v-if="groups.length"
            v-show="selectedBrand && !selectedGroup"
          ></group-selector>
          <bom-selector
            v-if="boms.length"
            v-show="!selectedBom && selectedGroup"
          ></bom-selector>
          <bom-detail v-if="selectedBom"></bom-detail>
        </div>
      </div>
    </div>
    <div v-show="searchType === 'byComponent'" class="col-12">
      <div class="row">
        <div class="col-xl-3">
          <div class="card">
            <div class="card-body p-5">
              <div class="row">
                <div class="col-12 mb-3">
                  <h3>Quelle est la référence composant ?</h3>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="componentReference"
                  />
                </div>
                <div class="col-12">
                  <button
                    v-on:click="handleSearchComponents"
                    v-show="componentReferenceCanBeFetched"
                    class="btn btn-flat primary"
                  >
                    Rechercher ...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9">
          <compatible-boms v-show="compatibleBoms.length"></compatible-boms>
          <no-compatible-bom-found
            v-if="compatibleBoms.length == 0 && compatibleBomsQueryFetched"
          ></no-compatible-bom-found>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BomHeader from "./components/Header.vue";
import SearchTypeSelector from "./components/SearchTypeSelector.vue";
import BrandSelector from "./components/BrandsSelector.vue";
import GroupSelector from "./components/GroupSelector.vue";
import BomSelector from "./components/BomSelector.vue";
import BomDetail from "./components/BomDetail.vue";
import CompatibleBoms from "./components/CompatibleBoms.vue";
import NoCompatibleBomFound from "./components/NoCompatibleBomFound.vue";
import Magnify from "vue-material-design-icons/Magnify.vue";

export default {
  name: "BomWrapper",
  components: {
    BomHeader,
    SearchTypeSelector,
    BrandSelector,
    GroupSelector,
    BomSelector,
    BomDetail,
    CompatibleBoms,
    NoCompatibleBomFound,
    Magnify,
  },
  computed: {
    ...mapState("bom", [
      "brands",
      "groups",
      "bikes",
      "boms",
      "searchType",
      "selectedBrand",
      "selectedGroup",
      "selectedBom",
      "compatibleBoms",
      "compatibleBomsQueryFetched",
    ]),
    ...mapGetters("bom", [
      "componentReferenceCanBeFetched",
      "getComponentReference",
    ]),
    componentReference: {
      get() {
        return this.getComponentReference;
      },
      set(value) {
        this.$store.dispatch("bom/SET_COMPONENT_REFERENCE", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("bom/GET_BRANDS");
    this.$store.dispatch("bom/GET_BOMS");
  },
  methods: {
    handleSearchComponents() {
      this.$store.dispatch("bom/GET_COMPATIBLE_BOMS");
    },
    handleResetSearch() {
      this.$store.dispatch("bom/RESET_SEARCH");
    },
  },
};
</script>

<style lang="scss" scoped>
.Wrapper {
  display: block;
  position: relative;
}
</style>
