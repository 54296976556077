<template>
  <div>
    <div v-if="messages.length">
      <h2>Messages envoyés</h2>
      <message-card
        v-for="message in messages"
        :key="message.id"
        :message="message"
      />
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import MessageCard from "./MessageCard.vue";
export default {
  name: "MessagesList",
  components: { MessageCard },
  props: { messages: Array },
};
</script>
