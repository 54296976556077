<template>
  <div class="bom-item" :class="{ 'bom-head': item.um === 'head' }">
    <div class="row">
      <div v-if="item.um === 'head'" class="col-12">
        <h4>{{ item.designation }}</h4>
      </div>
      <div v-if="item.um === 'item'" class="col-xl-3">{{ item.reference }}</div>
      <div v-if="item.um === 'item'" class="col-xl-6">
        {{ item.designation }}
      </div>
      <div class="col-xl-3 text-end" v-if="item.um === 'item' && cart">
        <a href="" class="text-success">Commander</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BomItem",
  props: {
    item: {
      type: Object,
      default: null,
    },
    cart: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bom-item {
  border-bottom: 1px solid #ededed;
  &.bom-head {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid black;
    margin-bottom: 0.75rem;
  }
}
</style>
