export default {
    namespaced: true,
    state: {
        bikes: [],
        components: [],
        searchTerm: "",
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        }
    },
    actions: {
        GET_NEW_BIKES (context, payload) {
            console.log("action > GET_BIKES")
            context.commit('getNewBikes', payload)
        },
        UPDATE_SEARCH_TERM ({state, commit}, value) {
            console.log("action > UPDATE_SEARCH_TERM")
            commit('getFilteredBikes', value)
        }
    },
    mutations: {
        async getNewBikes (state, payload) {
            try {
                const bikesResponse = await axios.get("/api/v5/products/new?type=bike")
                state.bikes = bikesResponse.data
            } catch (err) {
                console.log(err)
            }
            console.log("mutation > getBikes")
        },
        async getFilteredBikes (state, value) {
            console.log("mutation > getFilteredBikes")

            state.searchTerm = value
            console.log(state.searchTerm.length)

            if (state.searchTerm.length >= 4) {
                console.log("ooook")
                console.log(state.searchTerm.length)
                try {
                    const bikesResponse = await axios.get(`/api/v5/products/?search=you518`)
                    console.log(bikesResponse.data)
                    state.bikes = bikesResponse.data
                } catch (err) {
                    console.log(err)
                }
            }
        }
    },
}