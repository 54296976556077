<template>
  <div>
    <h4>Informations</h4>
    <div class="mb-2">
      <h5 class="m-0 text-base">Nom du technicien en atelier</h5>
      <div>{{ store.contact }}</div>
    </div>
    <div class="mb-2">
      <h5 class="mb-0 text-base">Description</h5>
      <div v-if="store.comment" class="">
        <span class="content">{{ store.comment }}</span>
      </div>
      <div v-else class="">
        <small class="text-danger">Aucune description renseignée.</small>
      </div>
    </div>
    <div class="mb-2">
      <h5 class="mb-0 text-base">Actions effectuées</h5>
      <div v-if="store.actions" class="">
        <span class="content">{{ store.actions }}</span>
      </div>
      <div v-else class="">
        <small class="text-danger">Aucune action renseignée.</small>
      </div>
    </div>
    <div class="mb-2">
      <h5 class="mb-0 text-base">Codes erreur affichés</h5>
      <div v-if="store.errors" class="">
        <span class="content">{{ store.errors }}</span>
      </div>
      <div v-else class="">
        <small class="text-danger">Aucun code erreur renseigné.</small>
      </div>
    </div>
    <div class="mb-2" v-if="store.isBattery == 'yes' ">
      <h5 class="text-base m-0">Numéro de série de la batterie</h5>
      <div><span>{{ store.batterySerial }}</span></div>
    </div>
    <div class="mb-2" v-if="store.isBattery == 'yes' ">
      <h5 class="text-base m-0">Ampérage de la batterie</h5>
      <div><span>{{ store.batteryAmperage }}</span></div>
    </div>
  </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
</script>

<style lang="scss" scoped>
.content {
  font-size: 0.85rem;
}
</style>
