<template>
  <div id="shop-map"></div>
</template>

<script>
import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "ShopMap",
  props: ["latitude", "longitude"],
  data() {
    return {
      loading: false,
      location: "",
      access_token:
        "pk.eyJ1IjoiY3ljbGV1cm9wZSIsImEiOiJja2h1YWljdnUwaDJjMnFwaGc5am03eXkwIn0.HUnIZlMCD76nFYZfCOb-Jg",
      center: [this.longitude, this.latitude],
      map: {},
    };
  },
  mounted() {
    this.createMap();
    // let geocoder = new MapboxGeocoder({
    //   accessToken: this.access_token,
    //   mapboxgl: mapboxgl,
    //   marker: false,
    // });
    // this.map.addControl(geocoder);
    const marker = new mapboxgl.Marker({
      draggable: false,
      color: "#0C0C0D",
    })
      .setLngLat([this.longitude, this.latitude])
      .addTo(this.map);
  },
  methods: {
    async createMap() {
      try {
        mapboxgl.accessToken = this.access_token;
        this.map = new mapboxgl.Map({
          container: "shop-map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 13,
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#shop-map {
  height: 400px;
  border-radius: 0.5rem;
}
</style>
