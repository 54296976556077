<template>
    <div v-if="showModal">
        <transition name="fade">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">

              <div class="modal-header">
                <slot name="header">
                  <h3>{{ name }}</h3>
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                  {{ message }}
                </slot>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="showModal = false">
                    J'ai compris.
                  </button>
                  <button class="modal-default-button" @click="dismissForever">
                    J'ai compris, ne plus afficher cette info.
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>
    
<script>
    export default {
        name: 'Popin',
        props: {
          type: {
            type: String,
          },
          name: {
            type: String,
          },
          message: {
            type: String,
          }
        },
        data() {
            return {
                showModal: true,
            }
        },
        methods: {
            dismissForever() {
                axios.get('/announcements/dismiss-current')
                .then(response => {
                    console.log("response")
                    this.showModal = false
                })
            }
        }
    };
</script>
    
    
<style lang="css" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 880px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.3s ease;
    border-radius: 0.25rem;
}

@media screen and (max-width: 992px) {
    .modal-container {
        width: 90%;
    }
}

.modal-header h3 {
    margin-top: 0;
    color: #1f3c90;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
    border: 0;
    background: #1f3c90;
    color: #ffffff;
    padding: 0.75rem 2rem !important;
    border-radius: 0.15rem;
    outline: none;
    transition: all .25s;
}
.modal-default-button:hover {
    background: black;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>