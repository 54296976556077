<template>
    <div class="btn btn-topbar">
        <i class="fa fa-bell" :class="{'text-blue-600': enabled, 'text-gray-400': !enabled }"></i>
    </div>
</template>

<script>
import {serverBus} from '../../../frontend/app.js'
export default {
    name: 'MobileNotificationsIndicator',
    data() {
        return {
            enabled: null,
        }
    },
    created() {
        axios.get('/notifications/text/status')
        .then(response => {
            if (response.status == 200) {
                this.enabled = response.data
            }
        });
        serverBus.$on('update-text-notifications-status', (status) => {
            this.enabled = status
        })
    }
}
</script>