<template>
  <form :action="action" method="POST" @keydown.enter="$event.preventDefault()">
    <input type="hidden" name="_token" :value="csrfToken" />
    <SerialSelector />
    <MediaUploads v-if="store.serial"/>
    <div class="card p-4 p-md-5 mb-3" v-if="store.serial">
      <div class="mb-3">
        <label for="damages" class="form-label"><span class="fw-semibold">Quels sont les composants endommagés ?</span></label>
        <input type="text" id="damages" name="damages" class="form-control form-control-lg">
      </div>
      <div class="mb-3">
        <label for="labour" class="form-label"><span class="fw-semibold">Heures de Main d'œuvre</span></label>
        <input type="text" id="labour" name="labour" class="form-control form-control-lg">
      </div>
    </div>
    <div class="d-grid">
      <input type="submit" value="Envoyer" class="btn btn-primary" v-if="store.mediaUploadsCompleted">
    </div>
  </form>
</template>

<script setup>
import { useTransportDamageStore } from "../../../../store/useTransportDamageStore";
const store = useTransportDamageStore()
import { ref } from "vue";

import SerialSelector from "./SerialSelector.vue";
import MediaUploads from "./MediaUploads.vue";

const props = defineProps({
  action: {
    type: String,
    required: false,
  }
})

const csrfToken = ref(document.querySelector('meta[name="csrf-token"]').getAttribute('content'))

</script>