<template>
  <div>
    <div class="row justify-content-center">
        <div class="col-xl-10 text-center mb-3 px-5">
            <div class="fw-bold Header_Info"><small>Pour toute demande de garantie sur un composant d'une des marques ci-dessous, nous vous invitons à contacter directement leur Service Après-vente.</small></div>
        </div>
    </div>
    <infinite-slide-bar duration="20s">
        <div class="items">
            <div v-for="item in publishedAddressBook" :key="item.id" class="Item_Addressbok">
                <img :src="item.logo" alt="" />
            </div>
        </div>
    </infinite-slide-bar>
  </div>

</template>

<script>
import { mapState} from 'vuex'
import InfiniteSlideBar from 'vue-infinite-slide-bar'
export default {
    components: { InfiniteSlideBar },
    name: 'AddressBookSlider',
    computed: {
        ...mapState('statamic/addressbook', ['addressbook']),
        publishedAddressBook: function() {
            return this.addressbook.filter(a => a.status === 'published')
        }
    },
    created() {
        this.$store.dispatch('statamic/addressbook/GET_ADDRESSBOOK')
    }
}
</script>

<style lang="scss" scoped>
.Header_Info {
    line-height: 1rem;
}
.items {
    display: flex;
    gap: 0rem;
    justify-content: space-around;
}
.Item_Addressbok {
    width: 180px;
    padding: 0 1rem;
    background-color: white;
}
</style>