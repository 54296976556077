import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        questions: [],
        selectedQuestion: null
    },
    getters: {},
    actions: {
        async GET_QUESTIONS (context) {
            const questionsResponse = (await bearerTokenAuthAxios.get(`/api/v6/statamic/faq`))
            context.commit('setQuestions', questionsResponse.data)
            context.commit('setSelectedQuestion', questionsResponse.data[0])
        },
        SELECT_QUESTION (context, payload) {
            context.commit('setSelectedQuestion', payload)
        }
    },
    mutations: {
        setQuestions (state, payload) {
            state.questions = payload
        },
        setSelectedQuestion(state, payload) {
            state.selectedQuestion = payload

        }
    },
}