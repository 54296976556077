<template>
    <div class="Badge_Support" :class="[size, this.support]">{{ $t(`support.${this.support}`) }}</div>
  </template>
  
  <script>
  export default {
      name: 'SupportBadge',
      props: { support: { type: String, default: '' }, size: { type: String, default: 'Medium'}},
      computed: {
        label: function () {
            return this.support
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .Badge_Support {
    display: inline-block;
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    background-color: hsl(250, 18%, 95%);
    color: hsl(250, 68%, 30%);
    font-weight: 500;
    &.Small {
        padding: 0.35rem 0.85rem;
        font-size: 0.8rem;
        border-radius: 0.3rem;
    }   
    &.Medium {
        font-size: 1rem;
    }
    &.Large {
        padding: 0.75rem 1.25rem;
        font-size: 1.15rem;
        border-radius: 0.45rem;
    }
    &.accepted {
        background-color: hsl(110, 99%, 95%);
        color: hsl(110, 99%, 27%);
    }
    &.rejected {
        background-color: hsl(10, 99%, 95%);
        color: hsl(10, 99%, 37%);
    }
    &.delegate {
        background-color: hsl(209, 100%, 95%);
        color: hsl(209, 100%, 55%);
    }
}
</style>