<template>
    <div>Related ReturnOrder</div>
  </template>
  
  <script>
  export default {
      name: 'RelatedReturnOrder',
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>