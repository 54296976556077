<template>
  <div>
    <div><h6>Expertise</h6></div>
    <div>
        <StatusBadge :status="expertise.status" size="Small" v-if="expertise.status"/>
        <SupportBadge :support="expertise.support" size="Small" v-if="expertise.support"/>
    </div>
  </div>
</template>

<script>
import StatusBadge from './../../../Common/Badges/StatusBadge.vue'
import SupportBadge from './../../../Common/Badges/SupportBadge.vue'
export default {
    components: { StatusBadge, SupportBadge },
    name: 'RelatedExpertise',
    props: { expertise: { type: Object, default: null }}
}
</script>

<style lang="scss" scoped>
    h6 {
        font-size: 0.8rem;
    }
</style>