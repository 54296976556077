<template>
    <div class="fullscreen">
        <div class="spinner-grow text-success mb-4" role="status">
            <span class="sr-only">...</span>
        </div>
        <h4>{{ heading }}</h4>
        <span>{{ message }}</span>
    </div>
</template>

<script>
export default {
    name: 'FullScreenMessage',
    props: {
        heading: String,
        message: String,
    }
}
</script>

<style scoped>
h4 {
    color: rgb(17, 17, 88);
}
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.95);
    color: #111114;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
    padding: 4rem;
    text-align: center;
}
</style>