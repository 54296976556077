<template>
  <div class="Videos_Container">
    <div class="row">
      <h2 class="mb-3">Dernières vidéos</h2>
      <div class="Videos_List">
        <VideoCard :video="video" v-for="video in latestVideosToDisplay" :key="video.id"  class="video-item"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a href="/videos">Toutes les vidéos<fa-icon class="ms-2" icon="fa-solid fa-arrow-right" /></a>
      </div>
    </div>
    <VideoPlayer :video="selectedVideo" />
  </div>
</template>

<script>
import VideoCard from './VideoCard.vue'
import VideoPlayer from './VideoPlayer.vue';
import { mapState } from 'vuex'

export default {
    components: { VideoCard, VideoPlayer },
    name: 'VideosContainer',
    computed: {
        ...mapState('statamic/videos', ['latestVideos', 'selectedVideo']),
        latestVideosToDisplay() {
          return this.latestVideos.splice(0, 4);
        }
    },
    created() {
      this.$store.dispatch('statamic/videos/GET_LATEST_VIDEOS')
    }
}
</script>

<style lang="scss" scoped>
.Videos_List {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    .video-item:nth-child(4) {
      display: none;
    }
  }
  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .video-item:nth-child(4) {
      display: block;
    }
  }
}
</style>