<template>
  <div class="card p-0">
    <div>
      <div class="section" v-if="store.step >= 2">
        <CauseCard />
      </div>
      <div class="section" v-if="store.step >= 3">
        <ContextCard />
      </div>
      <div class="section" v-if="store.step >= 4">
        <SerialCard />
      </div>
      <div class="section" v-if="store.step >= 5">
        <InformationCard />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()

import CauseCard from "./CauseCard.vue";
import ContextCard from "./ContextCard.vue";
import SerialCard from "./SerialCard.vue";
import InformationCard from "./InformationCard.vue";

</script>


<style lang="scss" scoped>
.section {
  border-bottom: 1Px solid hsl(220deg, 50%, 90%);
  padding: 1rem 1.5rem;
  &:last-of-type {
    border-bottom: none;
  }
}
</style>