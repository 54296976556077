<template>
  <form :action="formAction" enctype="multipart/form-data" method="POST">
    <input type="hidden" name="_token" :value="csrfToken" />
    <div class="card mb-5">
      <div class="card-body mb-3">
        <h4 class="mb-3">Quel est le type de la déclaration ?</h4>
        <button class="btn btn-xl"
                :class="{'btn-primary': formChoice === 'single'}"
                @click.prevent="choosForm('single')">Déclaration unique</button>
        <button class="btn btn-xl"
                :class="{'btn-primary': formChoice === 'multiple'}"
                @click.prevent="choosForm('multiple')">Déclaration par lot</button>
      </div>
      <SingleReportFields v-if="formChoice === 'single'"/>
      <MassReportsFields v-if="formChoice === 'multiple'"/>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import SingleReportFields from "./includes/SingleReportFields.vue";
import MassReportsFields from "./includes/MassReportsFields.vue";
const props = defineProps({
  formAction: {type: String, required: true}
})
const csrfToken = ref(document.querySelector('meta[name="csrf-token"]').getAttribute('content'))
const formChoice = ref(null)

const choosForm = (choice) => {
  formChoice.value = choice
}
</script>