import axios from "axios"

export default {
    namespaced: true,
    state: {
        username: '',
        identities: [],
        identityValue: '',
        isFetching: false,
        errorMessage: null,
    },
    getters: {
        getIdentityValue (state) {
            return state.identityValue
        }
    },
    actions: {
        async GET_IDENTITIES ({state, commit}, payload) {
            commit('setUsername', payload)
            commit('setIsFetching', true)
            const identitiesResponse = await axios.get(`/admin/auth/user/identities/${state.username}`)
            commit('setIsFetching', false)
            commit('setIdentities', identitiesResponse.data)
        },
        SET_IDENTITY_VALUE (context, payload) {
            context.commit('setIdentityValue', payload)
        },
        async MAKE_PRIMARY ({state, commit, dispatch}, identity) {
            const identityResponse = await axios.post(`/admin/auth/user/identities/make-primary/${identity.id}`, {
                identity: identity,
                username: state.username,
                _method: 'patch'
            })
            dispatch('GET_IDENTITIES', state.username)
            commit('setIdentityValue', "")
        },
        async ADD_IDENTITY ({state, commit, dispatch}, value) {
            const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm")
            const phoneRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
            if (emailRegex.test(value)) {
                const emailIdentityResponse = await axios.post('/admin/auth/user/identities/email', {
                    username: state.username,
                    email: value
                })
                if (emailIdentityResponse.data.error) {
                    commit('setErrorMessage', emailIdentityResponse.data.error)
                    return;
                }
            } else if (phoneRegex.test(value)) {
                const phoneIdentityResponse = await axios.post('/admin/auth/user/identities/phone', {
                    username: state.username,
                    phone: value
                })
            }
            dispatch('GET_IDENTITIES', state.username)
            commit('setIdentityValue', "")
        },
        async DELETE_IDENTITY ({state, commit, dispatch}, identityId) {
            const identityResponse = await axios.post(`/admin/auth/user/identities/delete/${identityId}`, {
                identity: identityId,
                username: state.username,
                _method: 'delete'
            })
            dispatch('GET_IDENTITIES', state.username)
        },
    },
    mutations: {
        setUsername (state, payload) {
            state.username = payload
        },
        setIdentities (state, payload) {
            console.log(payload)
            state.identities = payload
        },
        setIdentityValue (state, payload) {
            state.identityValue = payload
        },
        setIsFetching (state, payload) {
            state.isFetching = payload
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload
        }
    }
}   