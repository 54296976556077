<template>
  <button class="Back_Button"><fa-icon icon="fa-solid fa-chevron-left" class="me-2"/>Retour</button>
</template>

<script>
export default {
    name: 'BackButton',
}
</script>

<style lang="scss" scoped>
.Back_Button {
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    background-color: hsl(250, 68%, 95%);
    border: 0;
    &:hover {
        background-color: rgb(221, 221, 246);
    }
}
</style>