<template>
  <div>
    <h4 class="mb-1">Produit concerné</h4>
    <div class="mb-2">
      <img :src="`https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/${store.serial.product.mmitno.substring(0, 6)}.jpg`"/>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item py-1"><span class="fw-bold">Numéro de série :</span> {{ store.serial.code }}</li>
      <li class="list-group-item py-1"><span class="fw-bold">Marque :</span> {{ store.serial.product.brand.name }}</li>
      <li class="list-group-item py-1"><span class="fw-bold">Référence :</span> {{ store.serial.product.mmitno }}</li>
      <li class="list-group-item py-1"><span class="fw-bold">Désignation :</span> {{ store.serial.product.m3designation }}</li>
    </ul>
  </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
</script>

<style lang="scss" scoped>
.list-group {
  font-size: 0.85rem;
  .list-group-item {
    padding: 0.25rem 0;
  }
}
</style>
