<template>
  <div>
    <div v-if="!rootCategory" class="row">
        <div class="col-xl-5 mb-3">
            <draggable v-model="rootCategories" ghost-class="ghost" handle=".handle">
                <transition-group>
                    <CategoryItem v-for="cat in rootCategories" :key="cat.id" :category="cat"/>
                </transition-group>
            </draggable>
        </div>
        <div class="col-xl-7 mb-3">
            <NoItem text="Naviguez dans les catégories et les sous-catégories pour trouver un document à télécharger." />
        </div>
    </div>
    <div v-else-if="!subCategory" class="row">
        <div class="col-12 mb-3">
            <h2 class="mb-3">{{ rootCategory.name }}</h2>
            <BackButton @click.native="handleUnsetRootCategory" />
        </div>
        <div v-if="subCategories.length" class="col-xl-5 mb-3">
            <draggable v-model="subCategories" ghost-class="ghost" hanle=".handle">
                <transition-group>
                    <SubCategoryItem v-for="cat in subCategories" :key="cat.id" :category="cat" />
                </transition-group>
            </draggable>
        </div>
        <div class="col-xl-7 mb-3">
            <DocumentsList />
        </div>
    </div>
    <div v-else-if="!finalCategory" class="row">
        <div class="col-12 mb-3">
            <h2 class="mb-3">{{ rootCategory.name }}</h2>
            <h3 class="mb-3">{{ subCategory.name }}</h3>
            <BackButton @click.native="handleUnsetSubCategory" />
        </div>
        <div v-if="finalCategories.length" class="col-xl-5 mb-3">
            <draggable v-model="finalCategories" ghost-class="ghost" hanle=".handle">
                <transition-group>
                    <FinalCategoryItem v-for="cat in finalCategories" :key="cat.id" :category="cat" />
                </transition-group>
            </draggable>
        </div>
        <div class="col-xl-7 mb-3">
            <DocumentsList />
        </div>
    </div>
    <div v-else class="row">
        <div class="col-12 mb-3">
            <h2 class="mb-3">{{ rootCategory.name }}</h2>
            <h3 class="mb-3">{{ subCategory.name }}</h3>
            <h4 class="mb-3">{{ finalCategory.name }}</h4>
            <BackButton @click.native="handleUnsetFinalCategory" />
        </div>
        <div class="col-12 mb-3">
            <DocumentsList />
        </div>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import CategoryItem from './CategoryItem.vue'
import SubCategoryItem from './SubCategoryItem.vue'
import FinalCategoryItem from './FinalCategoryItem.vue'
import DocumentsList from './DocumentsList.vue'
import NoItem from './NoItem.vue'
import BackButton from './BackButton.vue'
export default {
    components: { CategoryItem, SubCategoryItem, FinalCategoryItem, draggable, DocumentsList, NoItem, BackButton }, 
    name: 'DocumentsManager',
    computed: {
        ...mapState('documents', ['rootCategory', 'subCategory', 'finalCategory']),
        ...mapGetters('documents', ['getRootCategories', 'getSubCategories', 'getFinalCategories']),
        rootCategories: {
            get() {
                return this.getRootCategories
            },
            set(value) {
                this.$store.dispatch('documents/UPDATE_ROOT_CATEGORIES_ORDER', value)
            }
        },
        subCategories: {
            get() {
                return this.getSubCategories
            },
            set(value) {
                this.$store.dispatch('documents/UPDATE_SUB_CATEGORIES_ORDER', value)
            }
        },
        finalCategories: {
            get() {
                return this.getFinalCategories
            },
            set(value) {
                this.$store.dispatch('documents/UPDATE_FINAL_CATEGORIES_ORDER', value)
            }
        }
    },
    methods: {
        handleUnsetFinalCategory() {
            this.$store.dispatch('documents/UNSET_FINAL_CATEGORY')
        },
        handleUnsetSubCategory() {
            this.$store.dispatch('documents/UNSET_SUB_CATEGORY')
        },
        handleUnsetRootCategory() {
            this.$store.dispatch('documents/UNSET_ROOT_CATEGORY')
        }
    },
    created() {
        this.$store.dispatch('documents/GET_CATEGORIES')
        this.$store.dispatch('documents/GET_DOCUMENTS')
    }
}
</script>

<style lang="scss" scoped>
.ghost {
    background-color: rgb(67, 99, 195);
    color: white;
}
</style>