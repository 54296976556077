<template>
  <div class="Document_Item">
    <div class="Info">
        <!-- <div class="handle"><fa-icon icon="fa-solid fa-sort"></fa-icon></div>
        <div class="Dot_Status"
            :class="{Active: document.isPublished === true}"
            v-on:click="handleToggleStatus(document)"><fa-icon icon="fa-solid fa-circle"></fa-icon>
        </div> -->
        <div class="Name">{{ document.name }}</div>
    </div>
    <a :href="document.file" download target="_blank">Télécharger</a>

  </div>
</template>

<script>
export default {
    name: 'DocumentItem',
    props: { document: { type: Object, default: null }},
    methods: {
        handleToggleStatus(item) {
            this.$store.dispatch('documents/TOGGLE_DOCUMENT_STATUS', item)
        },
    }
}
</script>

<style lang="scss" scoped>
.Document_Item {
    display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-content: center;
        background: white;
        cursor: pointer;
        border-radius: 0rem;
        border: 1px solid hsl(220deg, 30%, 90%);
        border: 1px solid white;
        margin-bottom: 0.25rem;
        align-items: center;
        padding: 1rem 1.5rem;
        transition: all .2s;
        &:hover {
            border: 1px solid hsl(220deg, 80%, 60%);
            background: hsla(220, 100%, 50%, 0.1);
            color: hsl(220deg, 100%, 20%);
            a {
                color: hsl(220deg, 100%, 20%);
            }
        }
        div.Info {
            display: flex;
            align-items: center;
            justify-content: space-between !important;
            .Name {
                display: flex;
                align-content: center;
                font-weight: 600;
            }
            .handle {
                display: flex;
                width: 64px;
                height: 48px;
                cursor: pointer;
                align-items: center;
                justify-content: center;
            }
        }
    .Dot_Status {
        display: flex;
        width: 48px;
        height:  42px;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: rgb(235, 234, 230);
        &.Active {
            color: rgb(11, 215, 11);
        }
        .Inactive {
            color: rgb(235, 234, 230);
        }
    }
}
</style>