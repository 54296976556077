<template>
  <span class="Scrolling_Text_Item" v-show="isDisplayed">
    <span class="Title me-2">{{ content.title }}</span>
    <span v-html="content.content"></span>
  </span>
</template>

<script>
export default {
    name: 'ScrollingTextItem',
    props: { content: { type: Object, default: null }},
    computed: {
        isDisplayed: function() {
            return this.content.show_in_bar.value && this.content.status === "published"
        }
    }
}
</script>

<style lang="scss" scoped>
.Scrolling_Text_Item {
    display: inline-block;
    padding: 0.5rem 1rem;
    //background-color: hsl(250, 68%, 45%);
    color: hsl(250, 68%, 50%);
    .Title {
        font-weight: 600;
        color: hsl(250, 68%, 15%);
        text-transform: uppercase;
    }
}
</style>