import axios from "axios"
import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        tickets: [],
        isFetchingShop: false,
        shop: {},
        tempShop: {
            name: '',
            address1: '',
            address2: '',
            postalcode: '',
            city: '',
            phone: '',
            email: '',
        }
    },
    getters: {
        getShopName (state) {
            return state.tempShop.name
        },
        getShopAddress1 (state) {
            return state.tempShop.address1
        },
        getShopAddress2 (state) {
            return state.tempShop.address2
        },
        getShopPostalCode (state) {
            return state.tempShop.postalcode
        },
        getShopCity (state) {
            return state.tempShop.city
        },
        getShopPhone (state) {
            return state.tempShop.phone
        },
        getShopEmail (state) {
            return state.tempShop.email
        }
    },
    actions: {
        async GET_TICKETS (context, payload) {
            const ticketsResponse = (await bearerTokenAuthAxios.get(`/api/v5/customers/${payload}/support/tickets`)).data
            context.commit('setTickets', ticketsResponse)
        },
        async GET_SHOP (context, payload) {
            context.commit('setFetchingShopStatus', true)
            const shopResponse = (await bearerTokenAuthAxios.get(`/api/v5/customers/${payload}/shop`)).data
            context.commit('setShop', shopResponse)
            context.commit('setFetchingShopStatus', false)
        },
        SET_SHOP_NAME (context, payload) {
            context.commit('setShopName', payload)
        },
        SET_SHOP_ADDRESS_1 (context, payload) {
            context.commit('setShopAddress1', payload)
        },
        SET_SHOP_ADDRESS_2 (context, payload) {
            context.commit('setShopAddress2', payload)
        },
        SET_SHOP_POSTALCODE (context, payload) {
            context.commit('setShopPostalCode', payload)
        },
        SET_SHOP_CITY (context, payload) {
            context.commit('setShopCity', payload)
        },
        SET_SHOP_PHONE (context, payload) {
            context.commit('setShopPhone', payload)
        },
        SET_SHOP_EMAIL (context, payload) {
            context.commit('setShopEmail', payload)
        }
    },
    mutations: {
        setTickets (state, payload) {
            state.tickets = payload
        },
        setShop (state, payload) {
            state.shop = state.tempShop
            if (payload.length) {
                state.shop = state.tempShop = payload
            }
        },
        setFetchingShopStatus (state, payload) {
            state.isFetchingShop = payload
        },
        setShopName (state, payload) {
            state.tempShop.name = payload
        },
        setShopAddress1 (state, payload) {
            state.tempShop.address1 = payload
        },
        setShopAddress2 (state, payload) {
            state.tempShop.address2 = payload
        },
        setShopPostalCode (state, payload) {
            state.tempShop.postalcode = payload
        },
        setShopCity (state, payload) {
            state.tempShop.city = payload
        },
        setShopPhone (state, payload) {
            state.tempShop.phone = payload
        },
        setShopEmail (state, payload) {
            state.tempShop.email = payload
        }
    },
}