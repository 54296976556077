import { bottom } from "@popperjs/core"
import axios from "axios"
import { startCase, update } from "lodash"

export default {
    namespaced: true,
    state: {
        showOnlyActive: false,
        searchTerm: '',
        searchType: null,
        brands: [],
        selectedBrand: null,
        groups: [],
        filteredGroups: [],
        selectedGroup: null,
        boms: [],
        filteredBoms: [],
        selectedBom: null,
        bomItems: [],
        componentReference: "",
        compatibleBoms: [],
        compatibleBomsQueryFetched: false,
    },
    getters: {
        getBomItems: (state) => {
            return state.bomItems
        },
        getSearchTerm: (state) => {
            return state.searchTerm
        },
        groupsWithBoms (state) {
            return state.filteredGroups
        },
        getComponentReference(state) {
            return state.componentReference;
        },
        componentReferenceCanBeFetched(state) {
            return state.componentReference.length >= 8
        }
    },
    actions: {
        async UPDATE_BOM_ITEMS_ORDER (context, value) {
            context.commit('updateBomItemsOrder', value)
            const payload = { items: JSON.stringify(value) }
            const updateResponse = (await axios.post('/api/v5/boms/update-items-order', payload)).data
            console.log('Update response : ' + updateResponse)
        },
        GET_BRANDS (context) {
            context.commit('getBrands')
        },
        SET_GROUPS (context, value) {
            context.commit('setGroups', value)
        },
        GET_BOMS (context) {
            context.commit('getBoms')
        },
        SET_BRAND (context, value) {
            context.commit('setBrand', value)
        },
        UNSET_BRAND (context, value) {
            context.commit('unsetBrand', null)
        },
        SET_GROUP (context, value) {
            context.commit('setGroup', value)
        },
        UNSET_GROUP ( context, value) {
            context.commit('unsetGroup', null)
        },
        SET_SEARCH_TYPE (context, value) {
            context.commit('setSearchType', value)
        },
        SET_BOM (context, value) {
            context.commit('setBom', value)
        },
        UNSET_BOM (context, value) {
            context.commit('unsetBom', null)
        },
        GET_COMPATIBLE_BOMS ({state, commit}, value) {
            console.log("action > GET COMPATIBLE BOMS")
            commit('getCompatibleBoms', state.componentReference)
        },
        SET_COMPONENT_REFERENCE (context, value) {
            context.commit('setComponentReference', value)
        },
        RESET_SEARCH (context) {
            context.commit('resetSearch')
        },
        SET_SEARCH_TERM (context, value) {
            context.commit('setSearchTerm', value)
            context.commit('setFilteredBoms')

        },
        CLEAR_SEARCH_TERM (context) {
            context.commit('clearSearchTerm')
        },
        async TOGGLE_ITEM_STATUS (context, value) {            
            context.commit('toggleBomItemStatus', value)
            const payload = { item: JSON.stringify(value) }
            const updateResponse = (await axios.put('/api/v5/boms/update-item-status', payload)).data
            console.log(updateResponse)
        },
        async UPDATE_ITEM_LABEL (context, payload) {
            context.commit('updateItemLabel', payload)
            const postPayload = { item: JSON.stringify(payload) }
            const updateResponse = (await axios.put('/api/v5/boms/update-item-label', postPayload)).data
            console.log(updateResponse)
        },
        TOGGLE_SHOW_ONLY_ACTIVE (context) {
            context.commit('toggleShowOnlyActive')
        }
    },
    mutations: {
        updateBomItemsOrder (state, payload) {
            state.bomItems = payload
        },
        async getBrands( state ) {
            try {
                const brands = await axios.get('/api/v5/brands')
                state.brands = brands.data
            } catch (err) {
                console.log(`getBrands > error > ${err}`)
            }
        },
        setGroups (state, value) {
            state.groups = value
        },
        async getBoms (state) {
            console.log('getBoms')
            try {
                const boms = await axios.get('/api/v5/boms')
                state.boms = boms.data.boms
                state.filteredBoms = state.boms
            } catch (err) {
                console.log(err)
            }
        },
        setBrand (state, value) {
            state.selectedBrand = value
            state.groups = value.groups
            state.filteredBoms = state.boms.filter(bom => (bom.brand.code === state.selectedBrand.code))
            const filteredGroups = []
            state.filteredBoms.forEach(bom => {
                if(!state.filteredGroups.some(group => group.code === bom.group.code)) {
                    state.filteredGroups.push(bom.group)
                }
            }) 
            state.filteredGroups.sort((a, b) => {return a.name - b.name});
        },
        setFilteredBoms (state, value) {
            if (state.searchTerm.length >= 2) {
                const filteredBoms = state.boms.filter(bom => 
                    bom.product.mmitno.toLowerCase().includes(state.searchTerm.toLowerCase())
                    || bom.product.mmitds.toLowerCase().includes(state.searchTerm.toLowerCase())
                    || (bom.label != null && bom.label.toLowerCase().includes(state.searchTerm.toLowerCase()))
                )
                state.filteredBoms = filteredBoms
            } else {
                state.filteredBoms = []
            }
        },
        unsetBrand (state) {
            state.selectedBrand = null
            state.groups = []
            state.filteredGroups = []
            state.filteredBoms = state.boms
        },
        setGroup( state, value) {
            state.selectedGroup = value
            state.filteredBoms = state.filteredBoms.filter(bom => (bom.group.code === state.selectedGroup.code))
        },
        unsetGroup(state) {
            state.selectedGroup = null
            state.filteredBoms = state.boms.filter(bom => (bom.brand.code === state.selectedBrand.code))
        },
        setSearchType(state, value) {
            state.searchType = value
        },
        setBom (state, value) {
            console.log("set bom : " + value)
            state.selectedBom = value
            state.bomItems = state.selectedBom.items.sort((a,b) => a.position - b.position)
        },
        unsetBom (state) {
            state.selectedBom = null
        },
        setComponentReference(state, value) {
            state.componentReference = value
        },
        async getCompatibleBoms(state, value) {
            console.log("mutations > getCompatibleBoms")
            state.compatibleBomsQueryFetched = false
            try {
                const boms = await axios.get(`/api/v5/boms/compatible-with/${value}`)
                console.log(`/api/v5/boms/compatible-with/${value}`);
                state.compatibleBoms = boms.data.boms
                state.compatibleBomsQueryFetched = true
                console.log(boms.data.boms)
            } catch (err) {
                console.log(err)
            }
        },
        resetSearch (state) {
            state.filteredBoms = []
            state.filteredGroups = []
            state.selectedBrand = null
            state.selectedGroup = null
            state.selectedBom = null
            state.searchType = null
            state.componentReference = ""
            state.compatibleBomsQueryFetched = false
        },
        setSearchTerm (state, payload) {
            state.searchTerm = payload
        },
        clearSearchTerm (state) {
            state.searchTerm = ''
        },
        toggleBomItemStatus (state, payload) {
            const index = state.bomItems.indexOf(payload)
            Object.assign(state.bomItems[index], {active: !payload.active})
        },
        updateItemLabel (state, payload) {
            const index = state.bomItems.indexOf(payload)
            state.boms.forEach(bom => {
                bom.items.forEach(item => {
                    if (item.reference === payload.reference) {
                        console.log('another update')
                        Object.assign(item, {label: payload.label})
                    }
                })
            })
        },
        toggleShowOnlyActive (state) {
            state.showOnlyActive = !state.showOnlyActive
        }
    }
}