<template>
  <div class="No_Item">
    {{ text }}
  </div>
</template>

<script>
export default {
    name: 'NoItem',
    props: { text: { type: String, default: '' }}
}
</script>

<style lang="scss" scoped>
.No_Item {
    background-color: hsl(250, 68%, 95%);
    color: hsl(250, 68%, 45%);
    padding: 1rem 1.5rem;
    border-radius: 0rem;
    //border: 1px solid hsl(220deg, 80%, 60%);
    background: hsla(227, 27%, 94%, 0.1);
    color: hsl(220deg, 100%, 20%);
}
</style>