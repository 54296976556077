import axios from "axios"
import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        searchTerm: '',
        minSearchLength: 7,
        serials: [],
        selectedSerial: null,
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        }
    },
    actions: {
        SET_SEARCH_TERM ({commit, state, dispatch}, payload) {
            commit('setSearchTerm', payload)
            state.searchTerm.length >= state.minSearchLength && dispatch('GET_SERIALS', payload)
        },
        CLEAR_SEARCH_TERM (context) {
            context.commit('setSearchTerm', '')
        },
        async GET_SERIALS ({commit, state}, payload) {
            console.log('GET_SERIALS')
            console.log(`/api/v5/serials/${payload}`)
            const serialsResponse = (await bearerTokenAuthAxios.get(`/api/v5/serials/${payload}`)).data
            commit('setSerials', serialsResponse)
        },
        SELECT_SERIAL (context, payload) {
            context.commit('selectSerial', payload)
        }
    },
    mutations: {
        setSearchTerm (state, payload) {
            state.searchTerm = payload
        },
        setSerials (state, payload) {
            state.serials = payload
        },
        selectSerial (state, payload) {
            state.selectedSerial = payload
        }
    },
}
