<template>
  <div class="card p-4 p-md-5">
    <h3 class="mb-3"><span class="fw-semibold">2<i class="bi bi-chevron-compact-right"></i>Fichiers attachés</span></h3>
    <div class="mb-3">
      <label for="mu-1" class="form-label">
        <span class="fw-semibold">Photo du vélo</span>
      </label>
      <MediaUploader
          id="mu-1"
          upload-endpoint="/support/upload/media"
          @update="updateMediaList1"
      />
    </div>
    <div class="mb-3">
      <label for="mu-1" class="form-label">
        <span class="fw-semibold">Photo du conditionnement</span>
      </label>
      <MediaUploader
          id="mu-2"
          upload-endpoint="/support/upload/media"
          @update="updateMediaList2"
      />
    </div>
    <div class="mb-3">
      <label for="mu-1" class="form-label">
        <span class="fw-semibold">Photo du code chassis</span>
      </label>
      <MediaUploader
          id="mu-3"
          upload-endpoint="/support/upload/media"
          @update="updateMediaList3"
      />
    </div>
    <div class="mb-3">
      <label for="mu-1" class="form-label">
        <span class="fw-semibold">Bordereau de livraison</span>
      </label>
      <MediaUploader
          id="mu-4"
          upload-endpoint="/support/upload/media"
          @update="updateMediaList4"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useTransportDamageStore } from '@/store/useTransportDamageStore'
const store = useTransportDamageStore()
import MediaUploader from './MediaUploader.vue';

const canSubmit = computed(() => {
  return store.mediaList1.length
      && store.mediaList2.length
      && store.mediaList3.length
      && (store.selectedContext.identifier = 'avant-vente' || store.mediaList4.length)
})

const handleSubmit = () => {
  store.nextStep()
}

const updateMediaList1 = (m) => {
  store.mediaList1 = m
}

const updateMediaList2 = (m) => {
  store.mediaList2 = m
}

const updateMediaList3 = (m) => {
  store.mediaList3 = m
}

const updateMediaList4 = (m) => {
  store.mediaList4 = m
}

</script>

<style lang="scss" scoped>
.vue-dropzone {
  border-radius: 0.25rem;
  color: rgb(73, 79, 104) !important;
  line-height: 1.2rem;
  &.dropzone {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    &.dz-clickable {
      background: rgb(244, 246, 250) !important;
      &:hover {
        background: rgb(213, 218, 241) !important;
      }
    }
    .dz-default {
      &.dz-message {
        span {
          font-size: 0.85rem !important;
          line-height: 1.25rem !important;
        }
      }
    }
  }
}
</style>
