<template>
    <div class="col-12 mb-3">
        <div class="card sml-card">
            <div class="card-body">
                <div>{{ ticket.zendesk_id }}</div>
                <cause-badge :cause="ticket.cause" locale="fr" />
                <condition-badge :condition="ticket.condition" locale="fr"/>
                <div>{{ ticket.serial.code }}</div>
                <div>{{ ticket.serial.product.mmitno }} - {{ ticket.serial.product.mmitds }}</div>

                <div class="Badges">
                    <status-badge :status="ticket.status" />
                    <support-badge :support="ticket.returnorder.expertise.support" v-if="ticket.returnorder"/>
                    <support-badge :support="ticket.support" v-else />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CauseBadge from './CauseBadge.vue'
import ConditionBadge from './ConditionBadge.vue'
import StatusBadge from './StatusBadge.vue'
import SupportBadge from './SupportBadge.vue'

export default {
    name: 'TicketCard',
    components: { SupportBadge, StatusBadge, CauseBadge, ConditionBadge },
    props: { ticket: Object }
}
</script>

<style lang="scss" scoped>
.Badges {
    display: flex;
}
</style>