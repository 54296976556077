<template>
    <div :class="badgeClass">
        {{ badgeLabel }}
    </div>
</template>

<script>
export default {
    name: 'StatusBadge',
    props: {
        status: String,
    },
    computed: {
        badgeClass: function() {
            switch(this.status) {
                case 'new':
                    return 'badge sml-disc-badge sml-disc-badge-white sml-disc-skyblue-stroked'
                break
                case 'open':
                    return 'badge sml-disc-badge sml-disc-badge-skyblue'
                break
                case 'hold':
                case 'pending':
                    return 'badge sml-disc-badge sml-disc-badge-lightpurple'
                break
                case 'solved':
                    return 'badge sml-disc-badge sml-disc-badge-purple'
                break
                default:
                    return ''
            }
        },
        badgeLabel: function() {
            switch(this.status) {
                case 'new':
                    return 'Nouveau'
                break
                case 'open':
                    return 'Ouvert'
                break
                case 'hold':
                case 'pending':
                    return 'En attente'
                break
                case 'solved':
                    return 'Traité'
                break
                default:
                    return ''
            }
        }
    }
}
</script>