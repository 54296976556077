<template>
  <div class="Posts_Container">
    <div class="row">
      <h2 class="mb-3">Derniers articles</h2>
      <div class="Posts_List">
        <div v-for="post in latestPosts" :key="post.id">
          <PostCard :post="post" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a href="/posts">Tous les articles<fa-icon class="ms-2" icon="fa-solid fa-arrow-right" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from './PostCard.vue'
import { mapState } from 'vuex'
export default {
    components: { PostCard },
    name: 'LatestPostsContainer',
    computed: {
        ...mapState('statamic/posts', ['latestPosts'])
    },
    created() {
      this.$store.dispatch('statamic/posts/GET_LATEST_POSTS')
    }
}
</script>

<style lang="scss" scoped>
.Posts_List {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  margin-bottom: 1rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
  }
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>