import axios from "axios"
import { bearerTokenAuthAxios } from "../../clients"
export default {
    namespaced: true,
    state: {
        pos: [],
        searchTerm: '',
        filteredPos: [],
        selectedPos: null, 
        initSelectedPos: null,  
        assortments: [],
        selectedAssortment: null,
        showInvalid: false,
        showUnverified: false,
        minSearchTermLength: 5,
        isUpdating: false,    
    },
    getters: {
        getSearchTerm: (state) => {
            return state.searchTerm
        }
    },
    actions: {
        async GET_POS (context) {
            const posData = (await bearerTokenAuthAxios.get('/api/v6/pos')).data.data
            context.commit('setPos', posData)
        },
        async GET_ASSORTMENTS (context) {
            const assortmentsData = (await bearerTokenAuthAxios.get('/api/v6/sys/assortments/shopable')).data.data
            context.commit('setAssortments', assortmentsData)
            console.log(assortmentsData)
        },
        async TOGGLE_ACTIVATE_POS (context, pos) {
            const posData = (await bearerTokenAuthAxios.get(`/api/v5/pos/${pos}/activate`)).data.data
            context.commit('toggleActivatePos', posData)
        },
        async VERIFY_POS (context, pos) {
            const posData = (await bearerTokenAuthAxios.get(`/api/v5/pos/${pos}/verify`)).data.data
            context.commit('verifyPos', posData)
        },
        async UPDATE_POS (context, pos) {
            const posPayload = { pos: JSON.stringify(pos) }
            console.log(posPayload)
            context.commit('setUpdatingStatus', true)
            const posData = (await bearerTokenAuthAxios.put('/api/v6/pos/update', posPayload)).data
            context.commit('setUpdatingStatus', false)
            context.commit('updateSelectedPos')
            context.commit('setSelectedPos', null)
        },
        SELECT_ASSORTMENT (context, payload) {
            context.commit('setSelectedAssortment', payload)
            context.commit('filterResults')
        },
        SET_SEARCH_TERM ({state, commit}, value) {
            commit('setSearchTerm', value)
            commit('filterResults')
        },
        CLEAR_SEARCH_TERM (context) {
            context.commit('clearSearchTerm')
        },
        SELECT_POS (context, pos) {
            context.commit('setSelectedPos', pos)
        },
        TOGGLE_SHOW_INVALID (context) {
            context.commit('toggleShowInvalid')
            context.commit('filterResults')
        },
        TOGGLE_SHOW_UNVERIFIED (context) {
            context.commit('toggleShowUnverified')
            context.commit('filterResults')
        }
    },
    mutations: {
        setPos (state, payload) {
            state.pos = payload
            state.filteredPos = state.pos
        },
        setAssortments (state, payload) {
            state.assortments = payload
        },
        setSelectedAssortment (state, payload) {
            state.selectedAssortment = payload
        },
        setUpdatingStatus (state, updatingStatus) {
            state.isUpdating = updatingStatus
        },
        toggleActivatePos (state, payload) {

        },
        verifyPos (state, payload) {
            
        },
        setSearchTerm (state, payload) {
            state.searchTerm = payload
        },
        filterResults (state) {

            state.filteredPos = state.pos
            if (state.selectedAssortment) {
                state.filteredPos = state.filteredPos.filter(pos => pos.user.assortments.some(a => a.code === state.selectedAssortment.code ))
            }
            if (state.showUnverified) {
                state.filteredPos = state.filteredPos.filter(pos => !pos.verified)
            }
            if (state.showInvalid) {
                state.filteredPos = state.filteredPos.filter(pos => 
                    !pos.user.assortments.length
                    || (pos.user.assortments.some(a => !a.expiresAt || Date(a.expiredAt) < Date.now())))
            }
            if (state.searchTerm.length >= state.minSearchTermLength) {
                state.filteredPos = state.filteredPos.filter(pos => 
                        pos.user.username.includes(state.searchTerm)
                        || pos.user.name.toLowerCase().includes(state.searchTerm.toLowerCase())
                    )
            }
        },
        clearSearchTerm (state) {
            state.searchTerm = ''
            state.filteredPos = []
        },
        setSelectedPos (state, payload) {
            state.selectedPos = payload
            state.initSelectedPos = JSON.parse(JSON.stringify(payload))
        },
        updateSelectedPos (state) {
            var index = state.pos.findIndex(pos => pos.id == state.selectedPos.id)
            Object.assign(state.pos[index], state.selectedPos)
        },
        toggleShowUnverified (state) {
            state.showUnverified = !state.showUnverified
        },
        toggleShowInvalid (state) {
            state.showInvalid = !state.showInvalid
        }
    },
}