<template>
  <div class="card p-4 p-md-5">
    <div class="mb-3">
      <button type="button" class="btn btn-sm" @click.prevent="store.prevStep()"><i class="bi bi-chevron-left me-2"></i>Etape précédente</button>
    </div>
    <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Fichiers attachés</span></h3>
    <div class="mb-3">
      <label for="mu-1" class="form-label">
        <span class="fw-semibold">Photo du vélo complet</span>
      </label>
      <MediaUploader 
        id="mu-1"
        upload-endpoint="/support/upload/media"
        @update="updateMediaList1"
        />
    </div>
    <div class="mb-3">
      <label for="mu-2" class="form-label">
        <span class="fw-semibold">Photo du code chassis</span>
      </label>
      <MediaUploader 
        id="mu-2"
        upload-endpoint="/support/upload/media"
        @update="updateMediaList2"
        />
    </div>
    <div class="mb-3">
      <label for="mu-3" class="form-label">
        <span class="fw-semibold">Photo du défaut</span>
      </label>
      <MediaUploader 
        id="mu-3"
        upload-endpoint="/support/upload/media"
        @update="updateMediaList3"
        />
    </div>
    <div class="mb-3" v-if="store.selectedContext.identifier !== 'avant-vente'">
      <label for="mu-4" class="form-label">
        <span class="fw-semibold">Copie de la facture de vente au consommateur</span>
      </label>
      <MediaUploader 
        id="mu-4"
        upload-endpoint="/support/upload/media"
        @update="updateMediaList4"
        />
    </div>
    <div class="text-end">
      <button 
        type="button" 
        class="btn btn-primary" 
        v-bind:class="{ disabled: !canSubmit }"
        v-on:click="handleSubmit">
        Voir le récapitulatif de la demande et envoyer<i class="bi bi-chevron-right ms-2"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
import MediaUploader from '../MediaUploader.vue';

const canSubmit = computed(() => {
  return store.mediaList1.length
    && store.mediaList2.length
    && store.mediaList3.length
    && (store.selectedContext.identifier === 'avant-vente' || store.mediaList4.length)
})

const handleSubmit = () => {
  store.nextStep()
}

const updateMediaList1 = (m) => {
  store.mediaList1 = m
}

const updateMediaList2 = (m) => {
  store.mediaList2 = m
}

const updateMediaList3 = (m) => {
  store.mediaList3 = m
}

const updateMediaList4 = (m) => {
  store.mediaList4 = m
}

</script>

<style lang="scss" scoped>
.vue-dropzone {
  border-radius: 0.25rem;
  color: rgb(73, 79, 104) !important;
  line-height: 1.2rem;
  &.dropzone {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    &.dz-clickable {
      background: rgb(244, 246, 250) !important;
      &:hover {
        background: rgb(213, 218, 241) !important;
      }
    }
    .dz-default {
      &.dz-message {
        span {
          font-size: 0.85rem !important;
          line-height: 1.25rem !important;
        }
      }
    }
  }
}
</style>
