<template>
    <div :class="badgeClass">
        {{ badgeLabel }}
    </div>
</template>

<script>
export default {
    name: 'SupportBadge',
    props: {
        support: String,
    },
    computed: {
        badgeClass: function() {
            switch(this.support) {
                case 'accepted':
                    return 'badge sml-disc-badge sml-disc-badge-green'
                break
                case 'rejected':
                    return 'badge sml-disc-badge sml-disc-badge-peach'
                break
                case 'red':
                    return 'badge sml-disc-badge sml-disc-badge-purple'
                break
                case 'red_answered':
                    return 'badge sml-disc-badge sml-disc-badge-primary'
                break
                case 'delegate':
                    return ''
                break
                default:
                    return ''
            }
        },
        badgeLabel: function() {
            switch(this.support) {
                case 'accepted':
                    return 'Accepté'
                break
                case 'rejected':
                    return 'Refusé'
                break
                case 'red':
                    return 'Lu'
                break
                case 'red_answered':
                    return 'Répondu'
                break
                case 'delegate':
                    return ''
                break
                default:
                    return ''
            }
        }
    }
}
</script>