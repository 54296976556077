<template>
  <div class="row">
    <labour-request-card />
    <labour-request-card />
    <labour-request-card />
    <labour-request-card />
    <labour-request-card />
    <labour-request-card />
  </div>
</template>

<script>
import LabourRequestCard from './LabourRequestCard.vue';
export default {
    components: { LabourRequestCard },
    name: 'AvailableLabourRequests',
}
</script>

<style>

</style>