<template>
  <div class="card p-4 p-md-5">
    <div class="mb-3">
      <button type="button" class="btn btn-sm" @click.prevent="store.prevStep()"><i class="bi bi-chevron-left me-2"></i>Etape précédente</button>
    </div>  
    <div class="mb-3">
      <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Contexte</span></h3>
      <select id="context" name="context" class="form-control form-control-lg" @change="handleChange">
        <option v-for="context in store.contexts" :value="context.identifier" :key="context.identifier">{{ context.label }}</option>
        <option value="" selected disabled>Sélectionner</option>
      </select>
    </div>
    <div v-if="store.selectedContext && store.selectedContext.dateMandatory" class="mb-3">
        <label for="date_of_sale" class="mb-2"><span class="fw-semibold">Date de première mise en circulation</span></label>
        <input type="date" class="form-control form-control-lg" name="bike_sold_at" v-model="store.soldAt">
    </div>
    <div class="text-end">
      <button
        v-on:click.prevent="handleSubmit"
        class="btn btn-flat btn-primary"
        v-bind:class="{ disabled: !canSubmit }"
      >Etape suivante<i class="bi bi-chevron-right ms-2"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()

const handleChange = (e) => {
  store.setContext(e.target.value)
}

const handleSubmit = () => {
  store.nextStep()
}

const canSubmit = computed(() => {
  return store.selectedContext && (store.selectedContext.dateMandatory === true && store.soldAt || store.selectedContext.dateMandatory === false)
})

</script>

<style lang="scss" scoped></style>
