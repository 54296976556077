import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        good: null,
        goodDetail: null,
        goodWithComment: null,
        goodWithCommentDetail: null,
        goodWithoutComment: null,
        goodWithoutCommentDetail: null,
        bad: null,
        badDetail: null,
        badWithComment: null,
        badWithCommentDetail: null,
        badWithoutComment: null,
        badWithoutCommentDetail: null,
        offered: null,
        offeredDetail: null,
        unoffered: null,
        unofferedDetail:null,
    },
    getters: {},
    actions: {
        async GET_GOOD (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/good'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setGoodSatisfactionRating', response)
        },
        async GET_BAD (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/bad'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setBadSatisfactionRating', response)
        },
        async GET_GOOD_WITH_COMMENT (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/good-with-comment'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setGoodSatisfactionRatingWithComment', response)
        },
        async GET_BAD_WITH_COMMENT (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/bad-with-comment'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setBadSatisfactionRatingWithComment', response)
        },
        async GET_GOOD_WITHOUT_COMMENT (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/good-without-comment'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setGoodSatisfactionRatingWithoutComment', response)
        },
        async GET_BAD_WITHOUT_COMMENT (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/bad-without-comment'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setBadSatisfactionRatingWithoutComment', response)
        },
        async GET_OFFERED (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/offered'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setOfferedSatisfactionRating', response)
        },
        async GET_UNOFFERED (context) {
            const endpoint = '/api/v5/zendesk/satisfaction-rating/unoffered'
            const response = (await bearerTokenAuthAxios.get(endpoint)).data
            context.commit('setUnofferedSatisfactionRating', response)
        }
    },
    mutations: {
        setGoodSatisfactionRating (state, payload) {
            state.good = payload.count
            state.goodDetail = payload
        },
        setGoodSatisfactionRatingWithComment (state, payload) {
            state.goodWithComment = payload.count
            state.goodWithCommentDetail = payload
        },
        setGoodSatisfactionRatingWithoutComment (state, payload) {
            state.goodWithoutComment = payload.count
            state.goodWithCommentDetail = payload
        },
        setBadSatisfactionRating (state, payload) {
            state.bad = payload.count
            state.badDetail = payload
        },
        setBadSatisfactionRatingWithComment (state, payload) {
            state.badWithComment = payload.count
            state.badWithCommentDetail = payload
        },
        setBadSatisfactionRatingWithoutComment (state, payload) {
            state.badWithoutComment = payload.count
            state.badWithoutCommentDetail = payload
        },
        setOfferedSatisfactionRating (state, payload) {
            state.offered = payload.count
            state.offeredDetail = payload
        },
        setUnofferedSatisfactionRating (state, payload) {
            state.unoffered = payload.count
            state.unofferedDetail = payload
        },
    },
}