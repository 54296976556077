<template>
  <div>
    <div v-if="!selectedTicket && queried && tickets.length" class="row mb-3 mt-3">
      <h3 class="mb-3">Sélectionnez une demande de garantie éligible</h3>
      <div v-for="(ticket, t) in tickets" class="col-12 mb-2" :key="t">
        <div class="Card_Labour" @click="selectTicket(ticket)">
          <div class="Card_Labour__Body">
              <span class="fw-bold">Demande de garantie {{ ticket.zendesk_id }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!tickets.length && queried" class="row mb-3">
      <div class="col-12">
        <div class="alert alert-warning">
          Aucune demande de garantie n'est éligible actuellement.
        </div>
      </div>
    </div>
    <div v-if="selectedTicket" class="row mb-3">
      <h4 class="mb-3">Vous avez sélectionné</h4>
      <div class="col-12 mb-3">
        <div class="Card_Labour active">
          <div class="Card_Labour__Body">
            <div>
              Demande de garantie
              <span class="fw-bold"
                >{{ selectedTicket.zendesk_id }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <a href="#" @click.prevent="cancelSelectTicket()" class="btn btn-flat btn-flat-sm btn-danger">Annuler</a>
      </div>
    </div>
    <div v-if="selectedTicket" class="row mb-3">
      <input type="hidden" name="ticket" :value="selectedTicket.id" />
      <div class="col-12">
        <div class="card">
          <div class="card-body p-5">
            <div v-if="!component" class="col-12 mb-3">
              <div class="row">
                <label for="" class="col-12 col-form-label">Référence composant</label>
                <div class="col-12 col-md-6">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="componentInput"
                    name="component"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <button
                    class="btn btn-flat btn-primary"
                    @click.prevent="findComponent()"
                  >
                    Trouver la référence
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="col-12 mb-3">
              <input
                v-show="component"
                type="hidden"
                name="component"
                :value="component.id"
              />
              <h4>Composant concerné</h4>
              <div class="Card_Component">
                <div class="Card_Component__Body">
                  {{ component.mmitno }}
                  <br />{{ component.mmitds }}
                </div>
              </div>
            </div>
            <div v-if="component" class="col-12 mb-3">
              <div class="row d-flex align-items-center">
                <label for="" class="col-12 col-form-label">N° de commande</label>
                <div class="col-12 col-lg-6 mb-3">
                  <input v-model="orderNumber"
                    :options="options"
                    class="form-control form-control-lg"
                    name="order_number" placeholder="DT" />
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <span v-if="validOrderNumber"><fa-icon icon="fa-regular fa-circle-check" size="lg"/></span>
                </div>
              </div>
            </div>
            <div v-if="component" class="col-12 mb-3">
              <div class="row d-flex align-items-center">
                <label for="" class="col-12 col-form-label"
                  >N° Bon de livraison</label
                >
                <div class="col-12 col-lg-6 mb-3">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    v-model="deliveryNumber"
                    name="delivery_number"
                  />
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <span v-if="validDeliveryNumber"><fa-icon icon="fa-regular fa-circle-check" size="lg"/></span>
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="component">
              <div class="row">
                <div class="col-12"><span class="small">Pour information, le pictogramme <fa-icon icon="fa-regular fa-circle-check" /> signifie que les information remplies dans le champ correspondent au format attendues. Les informations recueillies seront ensuite vérifiées pour traiter votre demande.</span></div>
              </div>
            </div>
            <div class="row" v-if="formCanBeSubmitted">
              <div class="col-12">
                <button class="btn btn-lg btn-flat btn-flat-lg btn-primary px-4 py-3" type="submit">
                  Envoyer la demande de main d'œuvre
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
export default {
  components: { Cleave },
  name: "LabourRequestClaimer",
  data() {
    return {
      tickets: [],
      selectedTicket: null,
      queried: false,
      getTicketsError: false,
      componentInput: "",
      component: null,
      deliveryNumber: "",
      orderNumber: "",
      orderNumberMinLength: 7,
      deliveryNumberMinLength: 5,
      options: {
          creditCard: false,
          prefix: 'DT',
          delimiter: '',
          blocks: [8],
      }
    };
  },
  computed: {
    validOrderNumber: function () {
      return (this.orderNumber.startsWith('DT') || this.orderNumber.startsWith('dt') || this.orderNumber.startsWith('Dt') || this.orderNumber.startsWith('dT')) && this.orderNumber.length >= this.orderNumberMinLength;
    },
    validDeliveryNumber: function() {
      return this.deliveryNumber.length >= this.deliveryNumberMinLength
    },
    formCanBeSubmitted: function() {
      return this.selectedTicket && this.component && this.validOrderNumber && this.validDeliveryNumber
    }
  },  
  mounted() {
    this.getAvailableTickets();
  },
  methods: {
    getAvailableTickets() {
      this.getTicketsError = false;
      axios.get("/support/mo/available-tickets").then((response) => {
        this.queried = true;
        if (response.status == 200) {
          this.tickets = response.data;
        } else {
          getTicketsError = true;
        }
      });
    },
    selectTicket(ticket) {
      this.selectedTicket = ticket;
    },
    cancelSelectTicket() {
      this.selectedTicket = null;
      this.component = null;
      this.componentInput = null;
      this.orderNumber = null;
      this.deliveryNumber = null;
    },
    findComponent() {
      axios
        .get("/api/v2/products/components/" + this.componentInput)
        .then((response) => {
          const component = response.data;
          if (component.id) {
            this.component = component;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Card_Labour {
  background: white;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid hsl(240deg, 20%, 96%);
  &:hover {
    border: 1px solid hsl(240deg, 50%, 70%);
  }
  &__Body {
    padding: 2rem;
  }
  &.active {
    background-color: rgb(18, 137, 228);
    color: white;
  }
}
.Card_Component {
  background-color: hsl(240deg, 20%, 96%);
  border-radius: 0.5rem;
  &__Body {
    padding: 1.5rem;
  }
}
</style>
