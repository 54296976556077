<template>
  <div>{{ post.title }}</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'RelatedPost',
    props: ['id', 'post'],
    computed: {
        //...mapGetters('statamic/posts', ['getPostById'])
    },
    created() {
        //this.post = this.$store.getters.getPostById(this.id)
    }
}
</script>

<style>

</style>