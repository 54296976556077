<template>
  <div 
    class="Question"
    :class="activeClass"
    v-text="question.title" 
    v-on:click="handleSelect"></div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'Question',
    props: { question: { type: Object, default: null }},
    computed: {
        ...mapState('statamic/faq', ['selectedQuestion']),
        activeClass: function() {
            return this.question == this.selectedQuestion && 'active'
        }
    },
    methods: {
        handleSelect: function() {
            this.$store.dispatch('statamic/faq/SELECT_QUESTION', this.question)
        },
    }
}
</script>

<style lang="scss" scoped>
.Question {
    cursor: pointer;
    font-size: 1rem !important;
    color: hsl(220deg, 20%, 70%);
    transition: all .1s;
    &.active {
        color: hsl(220deg, 90%, 40%) !important;
    }
}
</style>