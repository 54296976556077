<template>
  <div>
    <messages-list v-if="queried" :messages="messages" />
  </div>
</template>

<script>
import MessagesList from "./MessagesList.vue";

export default {
  name: "MessagesContainer",
  components: { MessagesList },
  props: { user: Object },
  data() {
    return {
      queried: false,
      error: null,
      messages: [],
    };
  },
  created() {
    this.getMessages();
  },
  methods: {
    getMessages: function() {
      axios
        .get("/api/v3/support/tickets/user/" + this.user.username + "/messages")
        .then((response) => {
          this.messages = response.data;
          this.queried = true;
        })
        .catch((error) => {
          // error getting messages from DB
          this.error = error;
          this.queried = true;
        });
    },
  },
};
</script>
