<template>
    <div class="Category_Item" v-on:click="handleSelect">
        <div class="Info">
            <div class="Name">{{ category.name }}</div>
        </div>
        <fa-icon icon="fa-solid fa-chevron-right"></fa-icon>
    </div>
</template>
  
<script>
export default {
    name: 'SubCategoryItem',
    props: { category: {type: Object, default: null }},
    methods: {
    handleSelect() {
        this.$store.dispatch('documents/SET_SUB_CATEGORY', this.category)
    },
    handleToggleStatus(item) {
        this.$store.dispatch('documents/TOGGLE_CATEGORY_STATUS', item)
    },
    }
}
</script>

<style lang="scss" scoped>
    .Category_Item {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        border: 1px solid lightgray;
        align-items: center;
        background: white;
        cursor: pointer;
        border-radius: 0rem;
        border: 1px solid hsl(220deg, 30%, 90%);
        border: 1px solid white;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        transition: background-color 100ms ease-in-out;
        &:hover {
            border: 1px solid hsl(220deg, 80%, 60%);
            //background: hsla(220, 100%, 50%, 0.1);
        }
        div.Info {
            display: flex;
            align-items: center;
            .Name {
                display: flex;
                align-content: center;
                font-weight: 600;
            }
            .handle {
                display: flex;
                width: 64px;
                height: 48px;
                cursor: pointer;
                align-items: center;
                justify-content: center;
            }
        }
        .Select {
            padding: 0 2rem;
            &:hover {
                border: 0 !important;
            }
        }
        .Dot_Status {
            display: flex;
            width: 48px;
            height:  42px;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            color: rgb(235, 234, 230);
            &.Active {
                color: rgb(11, 215, 11);
            }
            .Inactive {
                color: rgb(235, 234, 230);
            }
        }
    }
    </style>