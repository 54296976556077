import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        messages: [],
    },
    getters: {},
    actions: {
        async GET_MESSAGES (context) {
            const messagesData = (await bearerTokenAuthAxios.get('/api/v6/statamic/messages')).data
            context.commit('setMessages', messagesData)
        }
    },
    mutations: {
        setMessages (state, payload) {
            state.messages = payload
        }
    },
}