import axios from "axios"
import Vue from "vue"
import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        data: [],
        total: null,
        selectedTicket: null,
        links: [],
        meta: [],
        offset: 0,
        searchTerm: '',
        searchPerformed: false,
        skipIntro: false,
        id: null,
        isLaPoste: false,
        isFetching: false,
        isSending: false,
        isSent: false,
        cause: "",
        context: "",
        contextNeedsDate: null,
        dateOfSale: null,
        serialNumber: "",
        product: null,
        contact: "",
        comment: "",
        commentMinLength: 20,
        actions: "",
        errorCodes: "",
        electricalIssue: "",
        batterySerialNumber: "",
        batteryAmperage: "",
        batteryIssueCause: "",
        report: false,
        kit: false,
        componentType: "",
        componentIssueCause: "",
        componentSerialNumber: "",
        uploads: {
            file1: {
                options: {
                    url: "/api/v2/tickets/uploadFile1",
                    thumbnailWidth: 300,
                    maxFilesize: 20,
                    maxFiles: 1,
                    acceptedFiles: 'image/*, application/pdf, .doc',
                    dictDefaultMessage: "Déposez votre photo ici ou cliquez pour parcourir...",
                    dictRemoveFile: "Supprimer",
                    addRemoveLinks: true,
                    paramName: "file1"
                },
                filename: ""
            },
            file2: {
                options: {
                    url: "/api/v2/tickets/uploadFile2",
                    thumbnailWidth: 300,
                    maxFilesize: 20,
                    maxFiles: 1,
                    acceptedFiles: 'image/*, application/pdf, .doc',
                    dictDefaultMessage: "Déposez votre photo ici ou cliquez pour parcourir...",
                    dictRemoveFile: "Supprimer",
                    addRemoveLinks: true,
                    paramName: "file2"
                },
                filename: ""
            },
            file3: {
                options: {
                    url: "/api/v2/tickets/uploadFile3",
                    thumbnailWidth: 300,
                    maxFilesize: 20,
                    maxFiles: 1,
                    acceptedFiles: 'image/*, application/pdf, .doc',
                    dictDefaultMessage: "Déposez votre photo ici ou cliquez pour parcourir...",
                    dictRemoveFile: "Supprimer",
                    addRemoveLinks: true,
                    paramName: "file3"
                },
                filename: ""
            },
            file4: {
                options: {
                    url: "/api/v2/tickets/uploadFile4",
                    thumbnailWidth: 300,
                    maxFilesize: 20,
                    maxFiles: 1,
                    acceptedFiles: 'image/*, application/pdf, .doc',
                    dictDefaultMessage: "Déposez votre photo ici ou cliquez pour parcourir...",
                    dictRemoveFile: "Supprimer",
                    addRemoveLinks: true,
                    paramName: "file4"
                },
                filename: ""
            },
            file5: {
                options: {
                    url: "/api/v2/tickets/uploadFile5",
                    thumbnailWidth: 300,
                    maxFilesize: 20,
                    maxFiles: 1,
                    acceptedFiles: 'image/*, application/pdf, .doc',
                    dictDefaultMessage: "Déposez votre photo ici ou cliquez pour parcourir...",
                    dictRemoveFile: "Supprimer",
                    addRemoveLinks: true,
                    paramName: "file5"
                },
                filename: ""
            },
        },
        user: null,
        form: {
            ticketCauses: [
                {label: 'Casse', identifier: 'casse'},
                {label: 'Réglage', identifier: 'reglage'},
                {label: 'Fonctionnement', identifier: 'fonctionnement'},
                {label: 'Peinture', identifier: 'peinture'},
                {label: 'Autre', identifier: 'autre'},
            ],
            ticketContexts: [
                {label: 'Avant vente', identifier: 'avant-vente', needsDate: false},
                {label: 'Après vente', identifier: 'apres-vente', needsDate: true},
            ],
            batteryIssueCauses: [
                {label: "Ne s'allume pas", identifier: 'BI001'},
                {label: "Ne prend pas la charge", identifier: 'BI002'},
                // {label: "Ne prend pas entièrement la charge", identifier: 'BI003'},
                {label: "Manque d'autonomie", identifier: 'BI004'},
                {label: "Autre", identifier: 'BI005'},
            ],
            componentTypes: [
                {label: "Display", identifier: "CT001"},
                {label: "Moteur", identifier: "CT002"},
                {label: "Capteur de pédalage", identifier: "CT003"},
                {label: "Capteur de vitesse", identifier: "CT004"},
                {label: "Faisceau moteur", identifier: "CT005"},
                {label: "Faisceau accessoire", identifier: "CT006"},
                {label: "Coupe-circuit de frein", identifier: "CT007"},
                {label: "Manette 0-6 kmh (VAE La Poste)", identifier: "CT008"},
                {label: "Eclairage", identifier: "CT009"},
            ],
            componentIssueCauses: [
                {label: "Ne s'allume pas", identifier: 'EI001'},
                {label: "N'assite pas", identifier: 'EI002'},
                {label: "Ne capte pas", identifier: 'EI003'},
                {label: "Problème d'étanchéité", identifier: 'EI004'},
                {label: "Problème de corrosion", identifier: 'EI005'},
                {label: "Problème de matière", identifier: 'EI006'},
                {label: "Bruit", identifier: 'EI007'},
                {label: "Câble pincé", identifier: 'EI008'},
                {label: "Câble coupé", identifier: 'EI009'},
                {label: "Composant cassé", identifier: 'EI010'},
                {label: "Autre", identifier: 'EI011'},
            ],
            electricalIssueOptions: [
                {label: "Batterie", identifier: "BATT"},
                //{label: "Composant électrique autre que la batterie", identifier: "ECOMP"},
                {label: "Ne concerne pas la batterie.", identifier: "NONE"},
            ],
        },
        laposte: ["YLZPHN4101", "YLZPHN4201", "YLZPHN4801", "YOZP014101", "YOZP014201", "YOZP014801"],
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        },
        ticketCauses (state) {
            return state.form.ticketCauses
        },
        ticketContexts (state) {
            return state.form.ticketContexts
        },
        batteryIssueCauses (state) {
            return state.form.batteryIssueCauses
        },
        componentIssueCauses (state) {
            return state.form.componentIssueCauses
        },
        componentTypes (state) {
            return state.form.componentTypes
        },
        getDateOfSale: (state) => {
            return state.dateOfSale
        },
        getSerialNumber: (state) => {
            return state.serialNumber
        },
        getContact: (state) => {
            return state.contact
        },
        getComment: (state) => {
            return state.comment
        },
        commentMinLength: (state) => {
            return state.commentMinLength
        },
        getActions: (state) => {
            return state.actions
        },
        getErrorCodes: (state) => {
            return state.errorCodes
        },
        getBatterySerialNumber: (state) => {
            return state.batterySerialNumber
        },
        getBatteryIssueCause: (state) => {
            return state.batteryIssueCause
        },
        getBatteryAmperage: (state) => {
            return state.batteryAmperage
        },
        getComponentSerialNumber: (state) => {
            return state.componentSerialNumber
        },
        getComponentType: (state) => {
            return state.componentType
        },
        getComponentIssueCause: (state) => {
            return state.componentIssueCause
        },
        getKit: (state) => {
            return state.kit  
        },
        getReport: (state) => {
            return state.report
        },
        productCanBeFetched: (state) => {
            return state.serialNumber.length >= 8
        },
        informationFormComplete: (state) => {
            return state.contact != "" && state.comment.length > state.commentMinLength;
        },
        electricalIssueFormComplete: (state) => {
            if (state.electricalIssue === 'BATT') {
                return state.batterySerialNumber !== "" && state.batteryAmperage !== "";
            } else if (state.electricalIssue === 'ECOMP') {
                return state.componentIssueCause !== "" && state.componentSerialNumber !== "";
            } else if (state.electricalIssue === 'NONE') {
                return true
            }
            return false
        },
        uploadsAreComplete: (state, getters) => {
            return getters.file1isValid 
            && getters.file2isValid 
            && getters.file3isValid 
            && getters.file4isValid 
            && getters.file5isValid
        },
        file1isMandatory: (state) => {
            return true
        },
        file2isMandatory: (state) => {
            return true
        },
        file3isMandatory: (state) => {
            return true
        },
        file4isMandatory: (state, getters) => {
            return state.context === "apres-vente"
        },
        file5isMandatory: (state, getters) => {
            return getters.report === true
        },
        file1isValid: (state, getters) => {
            if (getters.file1isMandatory) {
                return state.uploads.file1.filename != '' && state.uploads.file1.filename != null
            } return true
        },
        file2isValid: (state, getters) => {
            if (getters.file2isMandatory) {
                return state.uploads.file2.filename != '' && state.uploads.file2.filename != null
            } return true
        },
        file3isValid: (state, getters) => {
            if (getters.file3isMandatory) {
                return state.uploads.file3.filename != '' && state.uploads.file3.filename != null
            } return true
        },
        file4isValid: (state, getters) => {
            if (getters.file4isMandatory) {
                return state.uploads.file4.filename != '' && state.uploads.file3.filename != null
            } return true 
        },
        file5isValid: (state, getters) => {
            if (getters.file5isMandatory) {
                return state.uploads.file5.filename != '' && state.uploads.file5.filename != null
            } return true
        },
        canLoadMore (state) {
            return state.data.length < state.total
        }
    },
    actions: {
        SET_SKIP_INTRO (context, value) {
            console.log("SET_SKIP_INTRO")
            if (value === true) {
                context.commit("setStep", 1, {root: true})
            }
            context.commit('setSkipIntro', value)
        },
        SET_TICKET_CAUSE (context, cause) {
            context.commit('setTicketCause', cause);
        },
        SET_TICKET_CONTEXT (context, ticketContext) {
            context.commit('setTicketContext', ticketContext);
        },
        SET_DATE_OF_SALE (context, value) {
            context.commit('setDateOfSale', value);
        },
        SET_SERIAL_NUMBER (context, value) {
            context.commit('setSerialNumber', value);
        },
        FETCH_PRODUCT (context, value) {
            context.commit('fetchProduct', value);
        },
        async SAVE_TICKET_INFORMATION ({commit, state}, value) {
            try {
                console.log(state.contact)
                const ticketUpdate = bearerTokenAuthAxios.put(`/api/v5/support/tickets/drafts/${state.id}/`, {
                    contact: state.contact,
                    comment: state.comment,
                    actions: state.actions,
                    error_codes: state.errorCodes,
                    battery_sn: state.batterySerialNumber,
                    battery_amperage: state.batteryAmperage,
                    electrical_issue: state.electricalIssue,
                    battery_issue_cause: state.batteryIssueCause,
                    report: state.report,
                    kit: state.kit,
                    component_type: state.componentType,
                    component_issue_cause: state.componentIssueCause,
                    component_sn: state.componentSerialNumber
                })
                console.log((await ticketUpdate).data)
                commit('nextStep', null, {root: true})
            } catch (err) {
                console.log(err)
            }
        },
        async SET_CONTACT ({commit, state}, value) {
            commit('setContact', value);
            console.log(state.contact)
        },
        async SET_COMMENT ({commit, state}, value) {
            commit('setComment', value);
        },
        async SET_ACTIONS ({commit, state}, value) {
            commit('setActions', value);
        },
        async SET_ERROR_CODES ({commit, state}, value) {
            commit('setErrorCodes', value);
        },
        SET_ELECTRICAL_ISSUE(context, value) {
            context.commit('setElectricalIssue', value)
        },
        SET_BATTERY_SERIAL_NUMBER (context, value) {
            context.commit('setBatterySerialNumber', value)
        },
        SET_BATTERY_AMPERAGE (context, value) {
            context.commit('setBatteryAmperage', value)
        },
        SET_BATTERY_ISSUE_CAUSE (context, value) {
            context.commit('setBatteryIssueCause', value)
        },
        SET_COMPONENT_SERIAL_NUMBER (context, value) {
            context.commit('setComponentSerialNumber', value)
        },
        SET_COMPONENT_TYPE (context, value) {
            context.commit('setComponentType', value)
        },
        SET_COMPONENT_ISSUE_CAUSE (context, value) {
            context.commit('setComponentIssueCause', value)
        },
        SET_KIT (context, value) {
            context.commit('setKit', value)
        },
        SET_REPORT (context, value) {
            context.commit('setReport', value)
        },
        CREATE_TICKET (context) {
            context.commit('createTicket')
        },
        SET_USER (context, value) {
            context.commit('setUser', value)
        },
        async GET_TICKET (context, value) {
            try {
                const ticket = await bearerTokenAuthAxios.get("/api/v5/support/tickets/drafts/" + value)
                context.commit("setTicket", ticket.data.ticket)
                context.commit("setStep", 4, {root: true})
                context.commit("showForm", null, {root: true})
            } catch (err) {

            }
        },
        async SET_FILE_1 ({commit, state}, value) {
            if (value === "") {
                console.log("remove file : file = " + value)
                try {
                    const removeFile = await axios.get(`/api/v2/tickets/${state.id}/remove-file/1`)
                    commit('setFile1', value)
                } catch (err) {
                    console.log(err)
                }
            } else {
                commit('setFile1', value)
            }
        },
        async SET_FILE_2 ({commit, state}, value) {
            if (value === "") {
                try {
                    const removeFile = await axios.get(`/api/v2/tickets/${state.id}/remove-file/2`)
                    commit('setFile2', value)
                } catch (err) {
                    console.log(err)
                }
            } else {
                commit('setFile2', value)
            }
        },
        async SET_FILE_3 ({commit, state}, value) {
            if (value === "") {
                try {
                    const removeFile = await axios.get(`/api/v2/tickets/${state.id}/remove-file/3`)
                    commit('setFile3', value)
                } catch (err) {
                    console.log(err)
                }
            } else {
                commit('setFile3', value)
            }
        },
        async SET_FILE_4 ({commit, state}, value) {
            if (value === "") {
                try {
                    const removeFile = await axios.get(`/api/v2/tickets/${state.id}/remove-file/4`)
                    commit('setFile4', value)
                } catch (err) {
                    console.log(err)
                }
            } else {
                commit('setFile4', value)
            }
        },
        async SET_FILE_5 ({commit, state}, value) {
            if (value === "") {
                try {
                    const removeFile = await axios.get(`/api/v2/tickets/${state.id}/remove-file/5`)
                    commit('setFile5', value)
                } catch (err) {
                    console.log(err)
                }
            } else {
                commit('setFile5', value)
            }
        },
        async SEND_TICKET ({commit, state}, value) {
            commit('setSending', true)
            console.log("send ticket")
            try {
                const sendTicket = await axios.post(`/support/tickets/${state.id}/send`);
                //commit('setSending', false)
                commit('setSent', true)
            } catch (err) {
                console.log(err)
            }
        },
        async GET_TICKETS ({ commit, state}, payload) {
            commit('setFetchingStatus', true)
            const ticketsResponse = (await bearerTokenAuthAxios.get(`/api/v6/support/tickets?offset=${state.offset}&search_term=${state.searchTerm}`))
            commit('setTicketsData', ticketsResponse)
            commit('setFetchingStatus', false)
        },
        SET_SEARCH_TERM (context, payload) {
            context.commit('setSearchTerm', payload)
        },
        async PERFORM_SEARCH ({commit, state}, payload) {
            commit('setFetchingStatus', true)
            commit('setSearchPerformed', true)
            const ticketsReponse = (await bearerTokenAuthAxios.get(`/api/v6/support/tickets?offset=${state.offset}&search_term=${state.searchTerm}`))
            commit('setTicketsData', ticketsReponse)
            commit('setFetchingStatus', false)
        },
        async CLEAR_SEARCH (context, payload) {
            context.commit('setFetchingStatus', true)
            context.commit('setSearchTerm', '')
            context.commit('setSearchPerformed', false)
            context.commit('setSelectedTicket', null)
            const ticketsResponse = (await bearerTokenAuthAxios.get('/api/v6/support/tickets'))
            context.commit('setTicketsData', ticketsResponse)
            context.commit('setFetchingStatus', false)
        }
    },
    mutations: {
        setSkipIntro (state, value) {
            state.skipIntro = value
            if (state.skipIntro) {
                console.log("skipping intro ...")
            } else {
                console.log("not skipping intro ...")
            }
        },
        setTicketCause (state, cause) {
            state.cause = cause
        },
        setTicketContext (state, ticketContext) {
            state.context = ticketContext
            const context = state.form.ticketContexts.find(context => context.identifier === state.context)
            state.contextNeedsDate = context.needsDate
        },
        setDateOfSale (state, value) {
            state.dateOfSale = value
        },
        setSerialNumber (state, value) {
            state.serialNumber = value
        },
        setContact (state, value) {
            state.contact = value.trim()
        },
        setComment (state, value) {
            state.comment = value.trim()
        },
        setActions (state, value) {
            state.actions = value.trim()
        },
        setErrorCodes (state, value) {
            state.errorCodes = value.trim()
        },
        setElectricalIssue(state, value) {
            if (value === 'BATT') {
                state.componentIssueCause = "";
                state.componentSerialNumber = "";
                state.componentType = "";
            } else if (value === 'ECOMP') {
                state.batteryIssueCause = "";
                state.batterySerialNumber = "";
                state.batteryAmperage = "";
            } else if (value === 'NONE') {
                state.componentSerialNumber = "";
                state.componentType = "";
                state.batterySerialNumber = "";
                state.batteryAmperage = "";
                state.batteryIssueCause = "";
                state.componentIssueCause = "";
                state.kit = false;
                state.report = false;
            }
            state.electricalIssue = value
        },
        setBatteryIssueCause (state, value) {
            state.batteryIssueCause = value
        },
        setBatterySerialNumber (state, value) {
            state.batterySerialNumber = value
        },
        setBatteryAmperage (state, value) {
            state.batteryAmperage = value
        },
        setComponentIssueCause (state, value) {
            state.componentIssueCause = value
        },
        setComponentSerialNumber (state, value) {
            state.componentSerialNumber = value
        },
        setComponentType (state, value) {
            state.componentType = value
        },
        setKit (state, value) {
            state.kit = value
        },
        setReport (state, value) {
            state.report = value
        },
        setUser( state, value) {
            state.user = value
        },
        async fetchProduct (state, value) {
            try {
                state.isFetching = true
                const response = await axios.get(`/api/v2/products/search/${value}`)
                console.log(response.data)
                state.isFetching = false
                if (response.data.reference) {
                    state.product = response.data
                } else {

                }
            } catch (err) {
                state.isFetching = false
            }
        },
        createTicket (state, value) {
            console.log("createTicket")
            try {
                axios.post("/support/tickets", {
                    user: state.user,
                    cause: state.cause,
                    condition: state.context,
                    serial: state.serialNumber,
                    bike_sold_at: state.dateOfSale,
                }).then(response => {
                    console.log(response)
                    state.id = response.data.id
                    if (state.laposte.includes(state.product.reference)) {
                        state.isLaPoste = true
                    }

                })
                
            } catch (err) {
                console.log(err)
            }
        },
        setTicket (state, value) {
            state.id = value.id
            state.cause = value.cause.identifier
            state.context = value.condition.identifier
            state.product = value.product
            state.contact = value.contact
            state.comment = value.comment
            state.actions = value.procedure
            state.errorCodes = value.error_codes
            state.electricalIssue = value.electrical_issue,
            state.batteryIssueCause = value.battery_issue_cause,
            state.report = value.report,
            state.kit = value.kit,
            state.componentType = value.component_type,
            state.componentIssueCause = value.component_issue_cause,
            state.componentSerialNumber = value.component_sn,
            state.batterySerialNumber = value.battery_sn,
            state.batteryAmperage = value.battery_amperage,
            state.uploads.file1.filename = value.attachments.file1
            state.uploads.file2.filename = value.attachments.file2
            state.uploads.file3.filename = value.attachments.file3
            state.uploads.file4.filename = value.attachments.file4
            state.uploads.file5.filename = value.attachments.file5
            if (state.laposte.includes(state.product.reference)) {
                state.isLaPoste = true
            }
        },
        setFile1 (state, value) {
            state.uploads.file1.filename = value
        },
        setFile2 (state, value) {
            state.uploads.file2.filename = value
        },
        setFile3 (state, value) {
            state.uploads.file3.filename = value
        },
        setFile4 (state, value) {
            state.uploads.file4.filename = value
        },
        setFile5 (state, value) {
            state.uploads.file5.filename = value
        },
        setSending(state, value) {
            state.isSending = value
        },
        setSent(state, value) {
            state.isSent = value
        },
        setTicketsData (state, value) {
            if (state.searchTerm.length) {
                state.searchPerformed = true
            }
            if (value.data) {
                state.data = [...state.data, ...value.data.data]
                state.links = value.data.links
                state.data.meta = value.data.meta
                state.total = value.data.count
                state.offset++
            }
        },
        setSearchTerm (state, value) {
            if(state.searchTerm !== value) {
                state.offset = 0
            }
            state.searchTerm = value
        },
        setFetchingStatus (state, value) {
            state.isFetching = value
        },
        setSelectedTicket (state, value) {
            state.selectedTicket = value
        },
        setSearchPerformed (state, value) {
            state.searchPerformed = value
            state.data = []
        }
    }
}