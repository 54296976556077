import { ref, computed } from "vue"
import { defineStore } from "pinia"

export const useClaimStore = defineStore('claim', () => {
  const step = ref(1)
  const causes = ref([
    {identifier: 'casse', label: 'Casse'},
    {identifier: 'reglage', label: 'Réglage'},
    {identifier: 'fonctionnement', label: 'Fonctionnement'},
    {identifier: 'peinture', label: 'Peinture'},
    {identifier: 'autre', label: 'Autre'},
  ])
  const selectedCause = ref(null)
  const contexts = ref([
    {identifier: 'avant-vente', label: 'Avant vente', dateMandatory: false},
    {identifier: 'apres-vente', label: 'Après vente', dateMandatory: true}
  ])
  const soldAt = ref(null)
  const selectedContext = ref(null)
  const serialNumber = ref('')
  const serial = ref(null)
  const serialFetchError = ref('')
  const isFetchingSerial = ref(false)

  const contact = ref('')
  const commentMinLength = ref(20)
  const comment = ref('')
  const errors = ref('')
  const actions = ref('')

  const isBattery = ref(null)
  const batterySerial = ref('')
  const batteryAmperage = ref('')

  const laPoste = ref(["YLZPHN4101", "YLZPHN4201", "YLZPHN4801", "YOZP014101", "YOZP014201", "YOZP014801"])

  const mediaList1 = ref([])
  const mediaList2 = ref([])
  const mediaList3 = ref([])
  const mediaList4 = ref([])

  const nextStep = () => {
    step.value++
  }

  const prevStep = () => {
    step.value--
  }

  const setCause = (value) => {
    selectedCause.value = causes.value.find(cause => cause.identifier == value)
  }

  const setContext = (value) => {
    selectedContext.value = contexts.value.find(context => context.identifier == value)
  }

  const fetchSerial = async () => {
    serialFetchError.value = ''
    isFetchingSerial.value = true
    const response = await fetch(`/api/v2/products/search/${serialNumber.value}`)
    const responseData = await response.json()
    serialFetchError.value = !responseData.reference && 'Aucun chassis ne correspond au code renseigné.'
    serial.value = responseData.serial
    isFetchingSerial.value = false
  }

  const cancelSerial = () => {
    serial.value = null
    serialFetchError.value = ''
  }

  return {
    step,

    // Step 1 - Cause
    causes,
    selectedCause,

    // Step 2 - Contexte
    contexts,
    selectedContext,
    soldAt,

    // Step 3 - Numero de serie / Chassis
    serialNumber,
    serial,
    isFetchingSerial,
    serialFetchError,

    // Step 4 - Commentaire - Actions - Codes erreur
    contact,
    comment,
    commentMinLength,
    actions,
    errors,

    isBattery,
    batterySerial,
    batteryAmperage,

    // Step 5 - Fichiersd attachés
    mediaList1,
    mediaList2,
    mediaList3,
    mediaList4,
    laPoste,

    // Actions
    setCause,
    setContext,
    nextStep,
    prevStep,
    fetchSerial,
    cancelSerial
  }
})