<template>
  <div>
    <h3 class="mb-3">Quelle est la marque du vélo ?</h3>
    <div class="row">
      <div
        v-for="brand in brands"
        :key="brand.code"
        class="col-6 col-xl-3 mb-3 h-100"
      >
        <div
          class="Card_Brand h-100"
          v-on:click="handleSelectBrand(brand)"
        >
          <div
            class="Card_Brand__Body d-flex align-items-center justify-content-center"
          >
            <img :src="brand.logo" alt="" v-if="brand.logo" />
            <div v-else>{{ brand.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrandsList",
  computed: {
    ...mapState("bom", ["brands"]),
  },
  methods: {
    handleSelectBrand(value) {
      this.$store.dispatch("bom/SET_BRAND", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.Card_Brand {
  background-color: white;
  border-radius: 0rem;
  border: 1px solid white;
  //border: 1px solid hsl(220deg, 30%, 90%);
  &__Body {
    padding: 1.25rem 1.5rem;
  }
  &:hover {
    border: 1px solid hsl(220deg, 80%, 60%);
  }
}
</style>
