<template>
    <div>
        <span class="sml-fw-bold">{{ nameLabel }}</span>
    </div>
</template>

<script>
export default {
    name: 'ConditionBadge',
    props: {
        condition: Object,
        locale: String,
    },
    created() {

    },
    computed: {
        nameLabel: function() {
            switch(this.locale) {
                case 'fr':
                    return this.condition.name.fr
                break
                case 'en':
                    return this.condition.name.en
                break
                case 'es':
                    return this.condition.name.es
                break
                case 'de':
                    return this.condition.name.de
                break
                case 'nl':
                    return this.condition.name.nl
                break
                default:
                    return this.condition.name.fr
            }
        }
    },
    methods: {
        
    }
}
</script>