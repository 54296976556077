import axios from "axios"
import { post } from "jquery"
import { bearerTokenAuthAxios } from "../../../../clients"

export default {
    namespaced: true,
    state: {
        step: 1,
        warranty: null,
        type: null,
        customer: null,
        ticket: null,
        customersList: [],
        customerInput: '',
        component: null,
        componentsList: [],
        componentInput: '',
        customerInputMinLength: 6,
        componentInputMinLength: 6,
        searchPerformed: false,
        cares: [
            {identifier: 'care_internal_error', name: 'Erreur Interne', requiresError: true},
            {identifier: 'care_customer_error', name: 'Erreur Client', requiresError: true},
            {identifier: 'care_picking', name: 'Erreur Prépration Commande', requiresError: false},
            {identifier: 'care_transportation_damage', name: 'Avarie de Transport', requiresError: false},
            {identifier: 'care_return1', name: 'Retour Garantie > Expertise > Retour Client', requiresError: false},
            {identifier: 'care_return2', name: 'Retour Garantie > Expertise > Remise en stock et ou crédit', requiresError: false},
            {identifier: 'care_late_delivery', name: 'Livraison tardive', requiresError: false},
            {identifier: 'care_financial', name: 'Encours financier', requiresError: false},
            {identifier: 'care_redelivery', name: 'Re-livraison', requiresError: false},
            {identifier: 'care_demo_bikes', name: 'Vélos en prêt / Vélos salon', requiresError: false},
        ],
        errors: [
            {identifier: 'err_reference', name: 'Référence'},
            {identifier: 'err_size', name: 'Hauteur'},
            {identifier: 'err_color', name: 'Couleur'},
            {identifier: 'err_cancellation', name: 'Annulation de vente'},
            {identifier: 'err_compliance', name: 'Produit non-conforme'},
            {identifier: 'err_doubleorder', name: 'Comande en double'},
        ],
        care: null,
        error: null,
        sendComponent: false,
        sendComponentOrder: '',
        reason: '',
        comment: '',
        internalComment: '',
        isFetching: false
    },
    getters: {
        getCustomerInput (state) {
            return state.customerInput
        },
        getComponentInput (state) {
            return state.componentInput
        },
        getSendComponent (state) {
            return state.sendComponent
        },
        getSendComponentOrder (state) {
            return state.sendComponentOrder
        },
        getReason (state) {
            return state.reason
        },
        getComment (state) {
            return state.comment
        },
        getInternalComment (state) {
            return state.internalComment
        }
    },
    actions: {
        SET_WARRANTY (context, payload) {
            context.commit('setWarranty', payload)
        },
        SET_TYPE (context, payload) {
            context.commit('setType', payload)
        },
        SET_CUSTOMER_INPUT (context, payload) {
            context.commit('setCustomerInput', payload)
        },
        SET_COMPONENT_INPUT (context, payload) {
            context.commit('setComponentInput', payload)
        },
        CLEAR_SEARCH (context) {
            context.commit('setCustomerInput', payload)
            context.commit('setCustomersList', [])
            context.commit('setCustomer', null)
        },
        async GET_CUSTOMERS ({state, commit}) {
            commit('setFetchingStatus', true)
            const customersListResponse = (await bearerTokenAuthAxios.get(`/api/v6/auth/customers/search/${encodeURIComponent(state.customerInput)}`)).data
            commit('setCustomersList', customersListResponse)
            commit('setFetchingStatus', false)
        },
        async GET_COMPONENTS ({state, commit}) {
            commit('setFetchingStatus', true)
            const componentsListResponse = (await bearerTokenAuthAxios.get(`/api/v6/sys/products/search/${encodeURIComponent(state.componentInput)}`)).data
            commit('setComponentsList', componentsListResponse)
            commit('setFetchingStatus', false)
        },
        SET_CUSTOMER (context, payload) {
            context.commit('setCustomer', payload)
            context.commit('nextStep')
        },
        SET_COMPONENT (context, payload) {
            context.commit('setComponent', payload)
        },
        SET_TICKET (context, payload) {
            context.commit('setTicket', payload)
        },
        SET_CARE (context, payload) {
            context.commit('setCare', payload)
        },
        SET_ERROR (context, payload) {
            context.commit('setError', payload)
        },
        SET_SEND_COMPONENT (context, payload) {
            context.commit('setSendComponent', payload)
        },
        SET_SEND_COMPONENT_ORDER (context, payload) {
            context.commit('setSendComponentOrder', payload)
        },
        SET_REASON (context, payload) {
            context.commit('setReason', payload)
        },
        SET_COMMENT (context, payload) {
            context.commit('setComment', payload)
        },
        SET_INTERNAL_COMMENT (context, payload) {
            context.commit('setInternalComment', payload)
        },
        async CREATE_RETURNORDER ({state, commit}) {
            const postPayload = { returnorder: JSON.stringify(state) }
            const returnorderResponse = (await bearerTokenAuthAxios.post('https://atelier-dev.cycleurope.fr/api/v6/support/returnorders', postPayload)).data
            console.log(returnorderResponse)
        },
        NEXT_STEP (context) {
            context.commit('nextStep')
        },
        PREVIOUS_STEP (context) {
            context.commit('previousStep')
        }
    },
    mutations: {
        setWarranty (state, payload) {
            state.warranty = payload
        },
        setType (state, payload) {
            state.type = payload
        },
        setCustomerInput (state, payload) {
            state.customerInput = payload
        },
        setComponentInput (state, payload) {
            state.componentInput = payload
        },
        setCustomersList (state, payload) {
            state.customersList = payload.data
        },
        setComponentsList (state, payload) {
            state.componentsList = payload.data
        },
        setCustomer (state, payload) {
            state.customer = payload
        },
        setComponent (state, payload) {
            state.component = payload
        },
        setTicket (state, payload) {
            state.ticket = payload
        },
        setCare (state, payload) {
            state.care = payload
        },
        setError (state, payload) {
            state.error = payload
        },
        setSendComponent (state, payload) {
            state.sendComponent = payload
        },
        setSendComponentOrder (state, payload) {
            state.sendComponentOrder = payload
        },
        setReason (state, payload) {
            state.reason = payload
        },
        setComment (state, payload) {
            state.comment = payload
        },
        setInternalComment (state, payload) {
            state.internalComment = payload
        },
        nextStep (state) {
            state.step++
        },
        previousStep (state) {
            state.step--
        },
        setFetchingStatus (state, payload) {
            state.isFetching = payload
        }
    }
}