<template>
  <div>
    <div class="row">
      <div v-if="!product" class="col-12 mb-4">
        <div class="card sml-card sml-card-white">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <h3>Quel est le numéro de série du vélo ?</h3>
              </div>
              <div class="col-lg-6 mb-2">
                <input class="form-control form-control-lg" v-model="serial" />
              </div>
              <div class="col-lg-6 mb-2">
                <transition name="fade">
                  <button
                    v-if="serial.length >= 8"
                    class="btn btn-primary btn-block"
                    @click.prevent="checkSerial"
                  >
                    Vérifier le numéro de série
                  </button>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="Form_Wrapper">
        <div class="Sidebar">
          <div class="card">
            <div class="mb-3">
              <img
                  :src="
                    'https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/' +
                      this.product.reference.substring(0, 6) +
                      '.jpg'
                  "
                />
            </div>
            <div>
              <dl>
                <dt>Numéro de série :</dt>
                <dd>{{ serial }}</dd>
              </dl>
              <dl>
                <dt>Marque :</dt>
                <dd>{{ product.brand }}</dd>
              </dl>
              <dl>
                <dt>Référence :</dt>
                <dd>{{ product.reference }}</dd>
              </dl>
              <dl>
                <dt>Désignation :</dt>
                <dd>{{ product.m3designation }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="Form">
          <div class="card">
            <div class="Block_Upload">
              <div class="Block_Upload__Info">
                <h4>Photo du vélo</h4>
                <small>Fichiers acceptés : (.jpeg, .jpg, .png)</small>
              </div>
              <div class="Block_Upload__Dropzone">
                <vue-dropzone
                  ref="myVueDropzone1"
                  id="dropzone1"
                  :options="dropzone1Options"
                  :useCustomSlot="true"
                  v-on:vdropzone-success="upload1Success"
                  v-on:vdropzone-error="upload1Error"
                  v-on:vdropzone-removed-file="file1Removed"
                ></vue-dropzone>
              </div>
            </div>

            <div class="Block_Upload">
              <div class="Block_Upload__Info">
                <h4>Photo du conditionnement</h4>
                <small>Fichiers acceptés : (.jpeg, .jpg, .png)</small>
              </div>
              <div class="Block_Upload__Dropzone">
                <vue-dropzone
                  ref="myVueDropzone2"
                  id="dropzone2"
                  :options="dropzone2Options"
                  :useCustomSlot="true"
                  v-on:vdropzone-success="upload2Success"
                  v-on:vdropzone-error="upload2Error"
                  v-on:vdropzone-removed-file="file2Removed"
                ></vue-dropzone>
              </div>
            </div>

            <div class="Block_Upload">
              <div class="Block_Upload__Info">
                <h4>Photo du code chassis</h4>
                <small>Fichiers acceptés : (.jpeg, .jpg, .png)</small>
              </div>
              <div class="Block_Upload__Dropzone">
                <vue-dropzone
                  ref="myVueDropzone3"
                  id="dropzone3"
                  :options="dropzone3Options"
                  :useCustomSlot="true"
                  v-on:vdropzone-success="upload3Success"
                  v-on:vdropzone-error="upload3Error"
                  v-on:vdropzone-removed-file="file3Removed"
                ></vue-dropzone>
              </div>
            </div>

            <div class="Block_Upload">
              <div class="Block_Upload__Info">
                <h4>Bordereau de livraison</h4>
                <small>Fichiers acceptés : (.pdf)</small>
              </div>
              <div class="Block_Upload__Dropzone">
                <vue-dropzone
                  ref="myVueDropzone4"
                  id="dropzone4"
                  :options="dropzone4Options"
                  :useCustomSlot="true"
                  v-on:vdropzone-success="upload4Success"
                  v-on:vdropzone-error="upload4Error"
                  v-on:vdropzone-removed-file="file4Removed"
                ></vue-dropzone>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="Row_Input">
              <div class="Label">
                <label for="components">Composants endommagés :</label>
              </div>
              <div class="Input">
                <input
                  type="text"
                  name="components"
                  v-model="components"
                  class="form-control form-control-lg"
                />
              </div>
            </div>
            <div class="Row_Input">
              <div class="Label">
                <label for="components">Heures de Main d'œuvre :</label>
              </div>
              <div class="Input">
                <input
                  type="number"
                  name="labour"
                  v-model="labour"
                  class="form-control form-control-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <transition name="fade">
          <div v-if="serialError" class="alert alert-warning">
            Aucun produit ne correspond au numéro de série
            <span class="font-weight-bold">{{ serial }}</span>
          </div>
        </transition>
      </div>
    </div>
    <div class="row" v-if="product">
      <div
        class="col-lg-12 mb-2"
        v-if="
          product &&
            components.length &&
            labour.length &&
            file1 &&
            file2 &&
            file3 &&
            file4
        "
      >
        <button class="btn btn-lg btn-primary shadow-md px-4 py-2 btn-block">
          Envoyer la déclaration
        </button>
      </div>
    </div>
    <input type="hidden" name="serial" :value="serial" />
    <input v-if="file1" type="hidden" name="file1" :value="file1" />
    <input v-if="file2" type="hidden" name="file2" :value="file2" />
    <input v-if="file3" type="hidden" name="file3" :value="file3" />
    <input v-if="file4" type="hidden" name="file4" :value="file4" />
    <input type="hidden" name="username" :value="user" />
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "TransportDamageForm",
  props: {
    user: String,
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      serial: "",
      serialError: false,
      product: null,
      labour: "",
      components: "",
      dropzone1Options: {
        paramName: "file1",
        acceptedFiles: "image/jpeg, image/png",
        url: "/api/v2/transport-damages/uploadFile1",
        addRemoveLinks: true,
        maxFiles: 1,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
      },
      dropzone2Options: {
        paramName: "file2",
        acceptedFiles: "image/jpeg, image/png",
        url: "/api/v2/transport-damages/uploadFile2",
        addRemoveLinks: true,
        maxFiles: 1,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
      },
      dropzone3Options: {
        paramName: "file3",
        acceptedFiles: "image/jpeg, image/png",
        url: "/api/v2/transport-damages/uploadFile3",
        addRemoveLinks: true,
        maxFiles: 1,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
      },
      dropzone4Options: {
        paramName: "file4",
        acceptedFiles: "image/jpeg, image/png, application/pdf",
        url: "/api/v2/transport-damages/uploadFile4",
        addRemoveLinks: true,
        maxFiles: 1,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
      },
      file1: "",
      file2: "",
      file3: "",
      file4: "",
    };
  },
  methods: {
    checkSerial() {
      axios.get("/api/v2/products/search/" + this.serial).then((response) => {
        if (response.data) {
          this.product = response.data;
        } else {
          this.serialError = true;
          setTimeout(() => {
            this.serialError = false;
          }, 2000);
        }
      });
    },

    upload1Success(file, response) {
      this.file1 = response;
    },
    upload2Success(file, response) {
      this.file2 = response;
    },
    upload3Success(file, response) {
      this.file3 = response;
    },
    upload4Success(file, response) {
      this.file4 = response;
    },

    upload1Error() {},
    upload2Error() {},
    upload3Error() {},
    upload4Error() {},

    file1Removed() {
      this.file1 = "";
    },
    file2Removed() {
      this.file2 = "";
    },
    file3Removed() {
      this.file3 = "";
    },
    file4Removed() {
      this.file4 = "";
    },
  },
};
</script>


<style lang="scss" scoped>
.Form_Wrapper {
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 1200px) {
    grid-template-columns: auto 1fr;
    gap: 1rem;
  }
  .Sidebar {
    width: 100%;
    @media screen and (min-width: 1200px) {
      width: 320px;
    }
  }
  .Form {

  }
  .Row_Input {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
    gap: 0.25rem;
    @media screen and (min-width: 1400px) {
      grid-template-columns: auto 1fr;
      gap: 1rem;
      .Label {
        width: 240px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.Block_Upload {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
  &__Info {
    width: 180px;
    @media screen and (min-width: 1400px) {
      width: 240px;
    }
  }
  &__Dropzone {
    .vue-dropzone {
      background-color: hsla(220, 50%, 50%, 0.06);
      color: hsla(220, 100%, 20%, 1);
      &:hover {
        background-color: hsla(220, 100%, 50%, 0.09);
      }
    }
  }
}
</style>