import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        data: [],
        searchTerm: '',
        searchType: '',
        offset: 0,
        total: 0,
        isFetching: false,
        searchPerformed: false,
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        },
        canLoadMore (state) {
            return state.data.length < state.total
        }
    },
    actions: {
        async GET_DATA ({state, commit}, payload) {
            commit('setFetchingStatus', true)
            const response = (await bearerTokenAuthAxios.get(`/api/v6/eurostar/returns?offset=${state.offset}&search_type=${state.searchType}&search_term=${state.searchTerm}`))
            commit('setData', response)
            commit('setFetchingStatus', false)
        },
        async PERFORM_SEARCH ({commit, state}, payload) {
            commit('setSearchPerformedStatus', true)
            commit('setFetchingStatus', true)
            const reponse = (await bearerTokenAuthAxios.get(`/api/v6/eurostar/returns?offset=${state.offset}&search_type=${state.searchType}&search_term=${state.searchTerm}`))
            commit('setData', reponse)
            commit('setFetchingStatus', false)
        },
        async CLEAR_SEARCH ({commit, state}) {
            commit('setSearchTerm', '')
            commit('setSearchType', '')
            commit('setSearchPerformedStatus', false)
            commit('setFetchingStatus', true)
            const response = (await bearerTokenAuthAxios.get(`/api/v6/eurostar/returns?offset=${state.offset}&search_type=${state.searchType}&search_term=${state.searchTerm}`))
            commit('setData', response)
            commit('setFetchingStatus', false)
        },
        SET_SEARCH_TERM (context, payload) {
            context.commit('setSearchTerm', payload)
        },
        SET_SEARCH_TYPE (context, payload) {
            context.commit('setSearchType', payload)
        },
    },
    mutations: {
        setData (state, payload) {
            state.data = [...state.data, ...payload.data.data]
            state.total = payload.data.count
            state.offset++
        },
        setSearchTerm (state, value) {
            if(state.searchTerm !== value) {
                state.offset = 0
            }
            state.searchTerm = value
        },
        setSearchType (state, payload) {
            state.searchType = payload
        },
        setFetchingStatus (state, payload) {
            state.isFetching = payload
        },
        setSearchPerformedStatus (state, payload) {
            state.searchPerformed = payload
            state.data = []
        },
    }
}