import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        addressbook: [],
    },
    getters: {

    },
    actions: {
        async GET_ADDRESSBOOK (context ) {
            const addressbookResponse = (await bearerTokenAuthAxios.get('/api/v5/contacts')).data
            console.log(addressbookResponse)
            context.commit('setAddressBook', addressbookResponse)
        }
    },
    mutations: {
        setAddressBook (state, payload) {
            state.addressbook = payload
        }
    }
}