import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        available: [],
        isFetching: false,
    },
    getters: {

    },
    actions: {
        async GET_AVAILABLE_LABOURS({state, commit}) {
            commit('setFetching', true);
            commit('setFetching', false);
        }
    },
    mutations: {
        setFetching(payload) {
            state.isFetching = payload
        }
    }
}