<template>
  <div>
    <button
      v-for="button in buttons" :key="button.id"
      class="Button_Selector"
      @click="handleSelectSearchType(button.searchType)">{{ button.label }}</button>
  </div>
</template>

<script>
export default {
  name: 'SearchTypeButtons',
  data() {
    return {
      buttons: [
        {
          id: 'type_components',
          label: 'Liste des composants d\'un modèle',
          searchType: 'components',
        },
        {
          id: 'type_bikes',
          label: 'Liste des vélos compatibles avec une référence composant',
          searchType: 'bikes',
        }
      ]
    }
  },
  methods: {
    handleSelectSearchType(pSearchType) {
      this.$store.dispatch("bos/SET_SEARCH_TYPE", pSearchType);
    }
  }
}
</script>

<style lang="scss" scoped>
.Button_Selector {
  border: 0;
  padding: 1rem;
}
</style>