<template>
    <div class="col-xl-10">
        <div v-if="!method">
            <div class="row">
                <div class="col-xl-12 mb-3">
                    <h3>Comment souhaitez-vous déclarer les rapports de cadre ?</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 mb-3">
                    <div class="card sml-card sml-card-button sml-card-primary sml-card-shadowed">
                        <div class="card-body" @click.prevent="selectMethod('form')">Je remplis le formulaire avec les informations correspondantes.</div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="card sml-card sml-card-button sml-card-primary sml-card-shadowed">
                        <div class="card-body" @click.prevent="selectMethod('file')">J'envoie un fichier Excel à partir du modèle disponible.</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="method == 'form'">
            <div class="row">
                <div class="col-xl-12 mb-3">
                    <button class="btn fruity-button fruity-sm fruity-icemint" @click.prevent="cancelSelectMethod"><i class="fa fa-arrow-left me-2"></i>Choisir une autre méthode</button>
                </div>
            </div>
            <div class="row mb-4">
                <h4>V.A.E.</h4>
                <div class="col-xl-6 mb-3">
                    <label for="serial" class="form-label">Numéro de série</label>
                    <div class="col-12"><input type="text" name="serial" id="serial" class="form-control form-control-lg" required></div>
                </div>
            </div>
            <div class="row mb-4">
                <h4>Bureau de Poste</h4>
                <div class="col-xl-4 mb-3">
                    <label for="serial" class="form-label">Code REGATE</label>
                    <div class="col-12"><input type="text" name="regate" id="regate" class="form-control form-control-lg" required></div>
                </div>
                <div class="col-xl-2 mb-3">
                    <label for="serial" class="form-label">Code Postal Bureau</label>
                    <div class="col-12"><input type="text" name="postalcode" id="postalcode" class="form-control form-control-lg" required></div>
                </div>
                <div class="col-xl-6 mb-3">
                    <label for="serial" class="form-label">Ville Bureau</label>
                    <div class="col-12"><input type="text" name="city" id="city" class="form-control form-control-lg" required></div>
                </div>
            </div>
            <div class="row mb-4">
                <h4>Inspection du cadre</h4>
                <div class="col-xl-4 mb-3">
                    <label for="serial" class="form-label">Type d'inspection</label>
                    <div class="col-12">
                        <select name="type" id="type" class="form-control form-control-lg">
                            <option value="audit">Audit</option>
                            <option value="check">Contrôle</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <label for="serial" class="form-label">Date d'inspection</label>
                    <div class="col-12"><input type="date" name="date" id="date" class="form-control form-control-lg" required></div>
                </div>
                <div class="col-xl-2 mb-3">
                    <label for="serial" class="form-label">Micro-fissure</label>
                    <div class="col-12">
                        <select name="crack" id="crack" class="form-control form-control-lg">
                            <option value="1">Oui</option>
                            <option value="0">Non</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 mb-3">
                    <label for="length" class="form-label">Longueur</label>
                    <div class="col-12">
                        <select name="length" id="length" class="form-control form-control-lg">
                            <option value="1">1mm</option>
                            <option value="2">2mm</option>
                            <option value="3">3mm</option>
                            <option value="4">4mm</option>
                            <option value="5">5mm</option>
                            <option value="6">6mm</option>
                            <option value="7">7mm</option>
                            <option value="8">8mm</option>
                            <option value="9">9mm</option>
                            <option value="10">10mm</option>
                            <option value="11">11mm</option>
                            <option value="12">12mm</option>
                            <option value="13">13mm</option>
                            <option value="14">14mm</option>
                            <option value="15">15mm</option>
                            <option value="99">Supérieure à 15mm</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn fruity-button fruity-blueberry">Valider</button>
                </div>
            </div>
        </div>
        <div v-if="method == 'file'">
            <div class="row">
                <div class="col-xl-12 mb-3">
                    <button class="btn fruity-button fruity-sm fruity-icemint" @click.prevent="cancelSelectMethod"><i class="fa fa-arrow-left me-2"></i>Choisir une autre méthode</button>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <a href="/laposte/Tableau_Rapports_Exemple.xlsx" download class="btn fruity-button fruity-mint"><i class="fa fa-cloud me-2"></i>Télécharger le tableau exemple à remplir</a>

                </div>
            </div>
            <div class="row mb-4">
                <h4>Fichier à téléverser</h4>
                <div class="col-xl-6 mb-3">
                    <label for="serial" class="form-label">Choisir un fichier Excel (xls ou xlsx) à téléverser ...</label>
                    <div class="col-12"><input type="file" name="file" id="file" class="form-control form-control-lg" accept=".xls,.xlsx" required></div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn fruity-button fruity-blueberry">Envoyer</button>
                </div>
            </div>
        </div>
        <input type="hidden" name="method" :value="method">
    </div>
</template>

<script>
export default {
    name: 'ReportsForm',
    data() {
        return {
            method: null,
        }
    },
    created() {
        
    },
    methods: {
        selectMethod(pMethod) {
            this.method = pMethod
        },
        cancelSelectMethod() {
            this.method = null
        }
    }
}
</script>

<style lang="scss" scoped>
.card-button {
    border-radius: 0.25rem;
    transition: all .25s;
    font-size: 1.15rem;
    line-height: 1.25rem;
    text-align: left;
    border: 1px solid rgba(240, 233, 170, 0.8);
    cursor: pointer;
    &:hover {
        box-shadow: 0px 4px 10px rgba(240, 228, 118, 0.8);

    }
    .card-body {
        padding: 2rem;
    }
}
</style>