<template>
    <div class="row">
        <div class="col-xl-8">
            <ticket-card v-for="ticket of tickets" :ticket="ticket" :key="ticket.id"></ticket-card>
        </div>
        <div class="col-xl-4">
            <div class="card sml-card mb-2" v-for="draft of drafts" :key="draft.id">
                <div class="card-body">
                    <div><span class="sml-fw-bold">{{ draft.serial.code }}</span></div>
                    <div>{{ draft.serial.product.mmitno }}</div>
                    <div><span class="badge sml-badge badge-primary"><small>{{ formattedCreatedAt(draft) }}</small></span></div>
                    <div>
                        <button class="btn fruity-button fruity-blueberry fruity-sm">Editer</button>
                        <button class="btn fruity-button fruity-raspberry fruity-sm"><i class="fa fa-times me-2"></i>Supprimer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import TicketCard from './Components/TicketCard.vue';

export default {
    name: 'CustomerTickets',
    components: { TicketCard },
    props: { customer: Object },
    data() {
        return {
            tickets: [],
            drafts: [],
        }
    },
    created() {
        moment.locale(this.customer.locale)
        this.getCustomerTickets()
        this.getCustomerDrafts()
    },
    computed: {
        formattedCreatedAt: function() {
            return (draft) => moment(draft.created_at).format('LLLL')
        }
    },
    methods: {
        async getCustomerTickets() {
            const getCustomerTicketsUrl = "/api/v3/support/tickets/user/" + this.customer.username
            const tickets = await axios.get(getCustomerTicketsUrl)
            this.tickets = tickets.data
        },
        async getCustomerDrafts() {
            const getCustomerDraftsUrl = "/api/v3/support/tickets/user/" + this.customer.username + "/drafts"
            const drafts = await axios.get(getCustomerDraftsUrl)
            this.drafts = drafts.data
        }
    },
    moment
}
</script>