<template>
  <div class="Status_Badge">
    <span class="Status_Dot" :class="dotClass"></span>
    <span class="Status_Label">{{ $t("status." + status) }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: { locale: String, status: String },
  computed: {
    dotClass: function() {
      switch (this.status) {
        case "new":
          return "Status_New";
          break;
        case "open":
          return "Status_Open";
          break;
        case "hold":
        case "pending":
          return "Status_Hold";
          break;
        case "solved":
          return "Status_Solved";
          break;
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 1.25rem;
.Status_Badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  .Status_Dot {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: $size / 2;
    margin-right: 1rem;
    background-color: #aaaaaa;
    &.Status_New {
      background-color: #8edbff;
    }
    &.Status_Open {
      background-color: #61b8ff;
    }
    &.Status_Hold {
      background-color: #ffa061;
    }
    &.Status_Solved {
      background-color: #6243ec;
    }
  }
  .Status_Label {
    font-size: 0.75rem;
    font-family: "Cera Pro Bold" !important;
  }
}
</style>
