import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        satisfactionRatings: []
    },
    getters: {
        ratedSatisfactionRatings (state) {
            return state.satisfactionRatings.filter(rating => (
                rating.score === "good" || rating.score === "good_with_comment" || rating.score === "bad" || rating.score === "bad_with_comment"
            ))
        }
    },
    actions: {
        async GET_SATISFACTION_RATINGS(context) {
            const ratingsResponse = (await bearerTokenAuthAxios.get('/api/v5/support/satisfaction-ratings/all')).data
            context.commit('setSatisfactionRatings', ratingsResponse.data)
        }
    },
    mutations: {
        setSatisfactionRatings (state, payload) {
            state.satisfactionRatings = payload
        }
    },
}