<template>
  <div>Overhauls</div>
</template>

<script>
export default {
    name: 'OverhaulsContainer'
}
</script>

<style lang="scss" scoped>

</style>