import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        data: null,
        latestPosts: null,
        selectedPost: null,
        postsURL: '/api/v6/statamic/posts',
        latestsPostsURL: '/api/v6/statamic/posts/latests',
    },
    getters: {
        getPostById (state, payload) {
            return state.data.data.find(p => p.id === payload)
        }
    },
    actions: {
        SET_POSTS_URL (context, payload) {
            context.commit('setPostsURL', payload)
        },
        async GET_LATEST_POSTS ({state, commit}, payload) {
            console.log('get latests posts')
            console.log(state.latestsPostsURL)
            const postsResponse = (await bearerTokenAuthAxios.get(state.latestsPostsURL)).data
            console.log(postsResponse)
            commit('setData', postsResponse)
            commit('setLatestPosts', postsResponse)
        },
        async GET_DATA ({ state, commit}, payload) {
            console.log('GET_DATA');
            const postsResponse = (await bearerTokenAuthAxios.get(state.postsURL)).data
            commit('setData', postsResponse)
            if (window.location.href.substring(window.location.href.lastIndexOf('/')+1)) {
                commit('setSelectedPost', window.location.href.substring(window.location.href.lastIndexOf('/')+1))
            }
        },
        SET_SELECTED_POST (context, payload) {
            context.commit('setSelectedPost', payload)
        }
    },
    mutations: {
        setPostsURL (state, payload) {
            state.postsURL = payload
        },
        setLatestPosts (state, payload) {
            state.latestPosts = payload
        },
        setData (state, payload) {
            state.data = payload
        },
        setSelectedPost (state, payload) {
            if (typeof payload === 'string') {
                console.log('striiiing')
                state.selectedPost = state.data.data.find(p => p.slug === payload)
            } else {
                state.selectedPost = payload
            }
        }
    },
}