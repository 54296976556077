import axios from 'axios'

let token = document.head.querySelector('meta[name="csrf-token"]');

const bearerTokenAuthAxios = axios.create({
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
})

bearerTokenAuthAxios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${process.env.MIX_API_TOKEN}`,
    config.headers.delete['X-CSRF-Token'] = token.content;
    return config
});

const base64ZendeskUsername = Buffer.from('maxime.freydrich@cycleurope.fr', 'utf8').toString('base64');

const zendeskAxiosClient = axios.create({
    headers: {
        Accept: 'application/json',
    }
})

zendeskAxiosClient.interceptors.request.use(config => {
    config.headers.Authorization = `Basic ${base64ZendeskUsername}/token:${process.env.MIX_ZENDESK_API_TOKEN}`
    return config
})

export {
    bearerTokenAuthAxios,
    zendeskAxiosClient   
};
