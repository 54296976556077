import { render, staticRenderFns } from "./InformationCard.vue?vue&type=template&id=25939b28&scoped=true"
import script from "./InformationCard.vue?vue&type=script&setup=true&lang=js"
export * from "./InformationCard.vue?vue&type=script&setup=true&lang=js"
import style0 from "./InformationCard.vue?vue&type=style&index=0&id=25939b28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25939b28",
  null
  
)

export default component.exports