<template>
  <div>
    <div v-if="!validationCodeSent">
      <button class="btn btn-flat btn-primary" @click="requestValidationCode">
        Je valide la procédure de présentation
      </button>
    </div>
    <div v-if="validationCodeSent">
      <div v-if="!validated">
        <div class="mb-2">
          <input
            type="text"
            v-model="validationCode"
            class="form-control form-control-lg form-control-flat"
          />
        </div>
        <div class="mb-2">
          <button
            @click="submitValidationCode"
            class="btn btn-flat btn-primary"
          >
            Envoyer le code
          </button>
        </div>
      </div>
      <div v-if="validated && message.length">
        <span class="text-success">{{ message }}</span>
      </div>
      <div v-if="!validated && message.length">
        <span class="text-danger">{{ message }}</span>
        <a href="#" @click="requestValidationCode"
          >Recevoir un nouveau code de validation.</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationValidation",
  props: {
    user: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      validationCode: "",
      validationCodeSent: false,
      validated: false,
      message: "",
    };
  },
  methods: {
    async requestValidationCode() {
      const validationCode = await axios.get(
        "/api/v4/general/presentation-validation-code/" + this.user
      );
      this.validationCodeSent = true;
    },
    submitValidationCode() {
      axios
        .post("/api/v4/general/presentation-validation-code", {
          _method: "PATCH",
          username: this.user,
          validation_code: this.validationCode,
        })
        .then((response) => {
          console.log(response);
          if (response.data.validated) {
            this.validated = true;
            this.message =
              "Le code a été vérifié. Vous pouvez quitter cet onglet.";
          } else {
            this.validated = false;
            this.message = "Le code de vérification est incorrect.";
          }
        });
    },
  },
};
</script>
