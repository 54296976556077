<template>
  <div class="Container">
    <div class="row">
        <div class="col-xl-5 mb-5">
            <Question v-for="q in questions" :key="q.id" :question="q" />
        </div>
        <div class="col-xl-7 mb-5">
            <div class="Question" v-text="selectedQuestion?.title"></div>
            <div class="Content" v-html="selectedQuestion?.content"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Question from './Question.vue';
export default {
    components: { Question },
    name: 'FaqContainer',
    computed: {
        ...mapState('statamic/faq', ['questions', 'selectedQuestion'])
    },
    created() {
        this.$store.dispatch('statamic/faq/GET_QUESTIONS')
    }
}
</script>

<style lang="scss" scoped>
.Container {
    .Question {
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
}
</style>