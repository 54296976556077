<template>
  <div>
    <SearchTypeButtons />
  </div>
</template>

<script>
import SearchTypeButtons from './components/SearchTypeButtons.vue'
export default {
  name: 'BomsContainer',
  components: { SearchTypeButtons }
}
</script>