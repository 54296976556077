<template>
  <div class="Videos_Container">
    <div class="row">
      <div class="col-xl-3 mb-3" v-for="video in videos" :key="video.id">
        <VideoCard :video="video" />
      </div>
    </div>
    <VideoPlayer :video="selectedVideo" />
  </div>
</template>

<script>
import VideoCard from './VideoCard.vue'
import VideoPlayer from './VideoPlayer.vue';
import { mapState } from 'vuex'
export default {
    components: { VideoCard, VideoPlayer },
    name: 'VideosContainer',
    computed: {
        ...mapState('statamic/videos', ['videos', 'selectedVideo']),
    },
    created() {
        this.$store.dispatch('statamic/videos/GET_VIDEOS')
    }
}
</script>

<style lang="scss" scoped>
.Container {

}
</style>