<template>
  <div class="Card_Video h-100" v-on:click="handleSelect">
    <div class="Thumbnail">
      <PreYoutube :id="videoId" :interval="100" format="maxresdefault" mode="constant"/>
    </div>
    <div class="Card_Video__Body">
      <div><h3>{{ video.name }}</h3></div>
    </div>
  </div>
</template>

<script>
import PreYoutube from "vue-pre-youtube";

export default {
    components: { PreYoutube },
    name: 'VideoCard',
    props: { video: { type: Object, default: null }},
    computed: {
      videoId: function () {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = this.video.url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
      }
    },
    methods: {
      handleSelect() {
        this.$store.dispatch('statamic/videos/SELECT_VIDEO', this.video)
      }
    }
}
</script>

<style lang="scss" scoped>
.Card_Video {
  background-color: white;
    border-radius: 0rem;
    border: 1px solid hsl(220deg, 30%, 90%);
    border: 1px solid white;
    transition: box-shadow 0.5s ease-out, border 0.3s ease-out;
    overflow: hidden;
    cursor: pointer;
    &__Body {
      padding: 1.5rem 2rem;
    }
    &:hover {
        //box-shadow:  0px 4px 16px rgba(0,0,0, 0.08);
        border: 1px solid hsl(220deg, 80%, 60%);
    }
  .Thumbnail {
    display: inline-block;
    overflow: hidden;
    img {
      //margin: -9.375% 0;
    }
  }
  h3 {
      font-size: 1.25rem;
    }
}
</style>