<template>
  <div>
    <div v-if="support != null" class="Support_Badge">
      <span class="Support_Dot" :class="dotClass"></span>
      <span class="Support_Label">{{ $t("support." + support) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportBadge",
  props: { locale: String, support: String },
  computed: {
    dotClass: function() {
      switch (this.support) {
        case "accepted":
          return "Support_Accepted";
          break;
        case "rejected":
          return "Support_Rejected";
          break;
        case "red":
          return "Support_Red";
          break;
        case "red_answered":
          return "Support_Red_Answered";
          break;
        case "delegate":
          return "Support_Delegate";
          break;
        case "undefined":
          return "Support_Undefined";
          break;
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 1.25rem;
.Support_Badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  .Support_Dot {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: $size / 2;
    margin-right: 1rem;
    background-color: #aaaaaa;
    &.Support_Accepted {
      background-color: #5ef09b;
    }
    &.Support_Rejected {
      background-color: #ff6169;
    }
    &.Support_Red {
      background-color: #61abff;
    }
    &.Support_Red_Answered {
      background-color: #61abff;
    }
    &.Support_Delegate {
      background-color: #9243ec;
    }
    &.Support_Undefined {
      background-color: #d2cdd8;
    }
  }
  .Support_Label {
    font-size: 0.75rem;
    font-family: "Cera Pro Bold" !important;
  }
}
</style>
