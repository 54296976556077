<template>
    <Transition name="fade">
        <div v-if="video" class="Youtube_Full_Player">
            <div class="Video_Frame">
                <youtube :video-id="videoId" ref="youtube" :resize="true" :fitParent="true"></youtube>
            </div>
            <div>
                <button class="Simple" @click="goBack"><fa-icon icon="fa-solid fa-chevron-left" class="me-2" />Retour</button>
            </div>
        </div>
    </Transition>
</template>

<script>
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

export default {
    name: 'YoutubeVideoPlayer',
    props: { video: { type: Object, default: null }},
    computed: {
        videoId: function() {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = this.video.url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }
    },
    methods: {
        goBack() {
            this.$store.dispatch('statamic/videos/DESELECT_VIDEO')
        }
    }
}
</script>

<style lang="scss" scoped>
.Youtube_Full_Player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    background-color: hsla(0,0,0, 0.9);
    padding: 300px;
    color: white;
    button.Simple {
        border: 0;
        padding: 0.5rem 1rem;
        background: transparent;
        color: white;
    }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}
</style>