<template>
  <div class="Step" v-bind:class="{ active: isActive }">
      <div class="Step_Number">{{ activeStep }}</div>
      <div class="Step_Title">{{ title }}</div>
  </div>
</template>

<script setup>
import { useClaimStore } from '../../../../../store/useClaimStore'
const store = useClaimStore()
import { computed } from 'vue'
const props = defineProps({
  activeStep: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true,
  }
})

const isActive = computed(() => {
  return store.step == props.activeStep
})
</script>

<style lang="scss" scoped>

.Step {
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  display: none;
  @media screen and (min-width: 1200px) {
    display: flex;
  }
  &_Number {
    font-weight: 600;
    font-size: 1.25rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
  }
  &_Title {
    text-align: center;
    font-weight: 600;
    line-height: 1rem;
  }
  &.active {
    display: flex;
    background-color: hsl(220deg, 100%, 50%);
    color: white;
    transition: all 0.5s;
  }
}
</style>
