<template>
  <div class="row">
    <div
      v-for="bom in compatibleBoms"
      :key="bom.id"
      class="col-12 col-xl-6 mb-3"
    >
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-1 col-xl-2">
              <img
                :src="
                  'https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/' +
                    bom.product.mmitno.substring(0, 6) +
                    '.jpg'
                "
              />
            </div>
            <div class="col-11 col-xl-10">
              <div>{{ bom.product.mmitno }}</div>
              <div>
                <span class="fw-bold">{{
                  bom.label || bom.product.mmitds
                }}</span>
              </div>
              <div>
                <small>{{ bom.group.name }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompatibleBoms",
  computed: {
    ...mapState("bom", ["compatibleBoms"]),
  },
};
</script>
