<template>
  <div class="col-xl-6 col-xxl-4 mb-4">
    <div class="Post h-100">
        <div class="Info">
            <h3>{{ post.title }}</h3>
            <button v-on:click.prevent="handleSelectPost">
                Lire l'article
                <fa-icon class="ms-2" icon="fa-solid fa-arrow-right" />
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Post',
    props: { post: { type: Object, default: null}},
    methods: {
        handleSelectPost() {
            this.$store.dispatch('statamic/posts/SET_SELECTED_POST', this.post.slug)
        }
    }
}
</script>

<style lang="scss" scoped>
.Post {
    @media (min-width: 1200px) {
        border: 1px solid hsl(220deg, 30%, 90%);
        border-radius: 0.25rem;
    }
    .Info {
        @media (min-width: 1200px) {
            padding: 1.5rem 2rem;
        }
    }
    h3 {
        font-size: 1.35rem;
    }
    button {
        border: 0;
        background: transparent;
        color: hsl(220deg, 90%, 40%);
        padding: 0;
        &:hover {
            color: hsl(220deg, 100%, 50%);
        }
    }
}
</style>