<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info">
        Désolé, aucun modèle de vélo compatible avec la référence de composant
        <span class="fw-bold">{{ componentReference }}</span> n'a été trouvé.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NoCompatibleBomFound",
  computed: {
    ...mapState("bom", ["componentReference"]),
  },
};
</script>

<style lang="scss" scoped></style>
