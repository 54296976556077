<script setup>
import { ref, computed } from "vue";

const serial = ref('')
const regate = ref('')
const postalcode = ref('')
const city = ref('')
const type = ref('')
const date = ref(null)
const crackLength = ref(null)

const canSubmit = computed(() => {
  return serial.value.length >= 8
  && (regate.value.length === 6 || (postalcode.value.length === 5 && city.value.length >= 2))
  && type.value !== ''
  && date.value !== null
  && crackLength.value !== null
})

const handleChangeType = (e) => {
  type.value = e.target.value
}

</script>

<template>
  <div class="card-body">
    <h4 class="text-primary mb-3">Déclaration unique de cadre VAE La Poste</h4>
    <section :class="{'isValid': serial.length >=8}">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <label for="serial" class="form-label"><span class="fw-semibold">Code chassis :</span></label>
          <input type="text" name="serial" id="serial" class="form-control" maxlength="10" required v-model="serial"/>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div class="col-lg-3 mb-3">
          <label for="regate" class="form-label"><span class="fw-semibold">Code REGATE :</span></label>
          <input type="text" name="regate" id="regate" class="form-control" maxlength="6"v-model="regate"/>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="postalcode" class="form-label"><span class="fw-semibold">Code Postal :</span></label>
          <input type="text" name="postalcode" id="postalcode" class="form-control" maxlength="5" v-model="postalcode" />
        </div>
        <div class="col-lg-6 mb-3">
          <label for="city" class="form-label"><span class="fw-semibold">Ville :</span></label>
          <input type="text" name="city" id="city" class="form-control" v-model="city"/>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div class="col-lg-4 mb-3">
          <label for="type" class="form-label"><span class="fw-semibold">Type d'inspection :</span></label>
          <select name="type" id="type" class="form-control" @change="handleChangeType">
            <option value="" selected disabled>Sélectionner</option>
            <option value="a">Audit</option>
            <option value="c">Contrôle</option>
          </select>
        </div>
        <div class="col-lg-4 mb-3">
          <label for="date" class="form-label"><span class="fw-semibold">Date d'inspection :</span></label>
          <input type="date" name="date" id="date" class="form-control" v-model="date"/>
        </div>
        <div class="col-lg-4 mb-3">
          <label for="crack_length" class="form-label"><span class="fw-semibold">Longueur de la fissure (en mm) :</span></label>
          <input type="number" name="crack_length" id="crack_length" class="form-control" v-model="crackLength"/>
        </div>
      </div>
    </section>
    <div class="mb-3">
      <input type="submit" :value="canSubmit ? 'Envoyer le rapport de cadre' : 'Complétez le formulaire...'" class="btn btn-lg btn-primary" :disabled="!canSubmit">
    </div>
    <input type="hidden" name="declaration_type" value="single" />
  </div>
</template>

<style lang="scss" scoped>
section {
  &.isValid {
    border-left: 2px solid green;
  }
}
</style>