import axios from "axios"
import { bearerTokenAuthAxios } from "../../clients"

export default {
    namespaced: true,
    state: {
        categories: [],
        rootCategories: [],
        subCategories : [],
        finalCategories: [],
        rootCategory: null,
        subCategory: null,
        finalCategory: null,
        documents: [],
        selectedDocuments: [],
    },
    getters: {
      getRootCategories: (state) => {
        return state.rootCategories
      },
      getSubCategories: (state) => {
        return state.subCategories
      },
      getFinalCategories: (state) =>{
        return state.finalCategories
      },
      getSelectedDocuments: (state) => {
        return state.selectedDocuments
      }
    },
    actions: {
        async GET_CATEGORIES (context) {
            const categoriesResponse = (await bearerTokenAuthAxios.get(`/api/v5/documents/categories`)).data
            context.commit('setCategories', categoriesResponse.categories)
            context.commit('setRootCategories')
        },
        async GET_DOCUMENTS (context) {
          const documentsResponse = (await bearerTokenAuthAxios.get(`/api/v5/documents`)).data
          context.commit('setDocuments', documentsResponse.documents)
        },
        SET_ROOT_CATEGORY (context, payload) {
          context.commit('setRootCategory', payload)
          context.commit('setSubCategories', payload)
        },
        UNSET_ROOT_CATEGORY (context) {
          context.commit('unsetRootCategory')
        },
        async UPDATE_ROOT_CATEGORIES_ORDER (context, payload) {
          context.commit('updateRootCategoriesOrder', payload)
          const postPayload = { categories: JSON.stringify(payload) }
          const updateResponse = (await bearerTokenAuthAxios.post('/api/v5/documents/categories/sort', postPayload)).data
          console.log('Update response : ' + updateResponse)
        },
        SET_SUB_CATEGORY (context, payload) {
          context.commit('setSubCategory', payload)
          context.commit('setFinalCategories')
        },
        UNSET_SUB_CATEGORY (context) {
          context.commit('unsetSubCategory')
        },
        async UPDATE_SUB_CATEGORIES_ORDER (context, payload) {
          context.commit('updateSubCategoriesOrder', payload)
          const postPayload = { categories: JSON.stringify(payload) }
          const updateResponse = (await bearerTokenAuthAxios.post('/api/v5/documents/categories/sort', postPayload)).data
          console.log('Update response : ' + updateResponse)
        },
        SET_FINAL_CATEGORY (context, payload) {
          context.commit('setFinalCategory', payload)
        },
        UNSET_FINAL_CATEGORY (context) {
          context.commit('unsetFinalCategory')
        },
        async UPDATE_FINAL_CATEGORIES_ORDER (context, payload) {
          context.commit('updateFinalCategoriesOrder', payload)
          const postPayload = { categories: JSON.stringify(payload) }
          const updateResponse = (await bearerTokenAuthAxios.post('/api/v5/documents/categories/sort', postPayload)).data
          console.log('Update response : ' + updateResponse)
        },
        async UPDATE_DOCUMENTS_ORDER (context, payload) {
          context.commit('updateDocumentsOrder', payload)
          const postPayload = { documents : JSON.stringify(payload)}
          const updateResponse = (await bearerTokenAuthAxios.post('/api/v5/documents/sort', postPayload)).data
          console.log('Update response : ' + updateResponse)
        },
        async TOGGLE_DOCUMENT_STATUS (context, payload) {
          context.commit('toggleDocumentStatus', payload)
          const postPayload = { document: JSON.stringify(payload) }
          const updateResponse = (await axios.put('/api/v5/documents/update-item-status', postPayload)).data
        },
        async TOGGLE_CATEGORY_STATUS (context, payload) {
          context.commit('toggleCategoryStatus', payload)
          const postPayload = { category: JSON.stringify(payload) }
          const updateResponse = (await bearerTokenAuthAxios.put('/api/v5/documents/categories/update-item-status', postPayload)).data
          console.log('Response : ' + updateResponse)
        }
    },
    mutations: {
        setCategories (state, payload) {
            state.categories = payload
        },
        setRootCategories (state) {
          state.rootCategories = state.categories.filter(category => !category.parentId)
        },
        setRootCategory (state, payload) {
          state.rootCategory = payload
          state.selectedDocuments = state.documents.filter(document => document.categoryId === state.rootCategory.id)
        },
        unsetRootCategory (state) {
          state.rootCategory = null
          state.selectedDocuments = state.documents.filter(document => !document.categoryId)
        },
        setSubCategories (state, payload) {
          state.subCategories = state.categories.filter(category => category.parentId === payload.id)
        },
        setSubCategory (state, payload) {
          state.subCategory = payload
          state.selectedDocuments = state.documents.filter(document => document.categoryId === state.subCategory.id)
        },
        unsetSubCategory (state) {
          state.subCategory = null
          state.selectedDocuments = state.documents.filter(document => document.categoryId === state.rootCategory.id)
        },
        setFinalCategories (state) {
          state.finalCategories = state.categories.filter(category => category.parentId === state.subCategory.id)
        },
        setFinalCategory (state, payload) {
          state.finalCategory = payload
          state.selectedDocuments = state.documents.filter(document => document.categoryId === state.finalCategory.id)
        },
        unsetFinalCategory (state) {
          state.finalCategory = null
          state.selectedDocuments = state.documents.filter(document => document.categoryId === state.subCategory.id)
        },
        setDocuments (state, payload) {
          state.documents = payload
          state.selectedDocuments = state.documents.filter(document => !document.categoryId)
        },
        updateRootCategoriesOrder (state, payload) {
          state.rootCategories = payload
        },
        updateSubCategoriesOrder (state, payload) {
          state.subCategories = payload
        },
        updateFinalCategoriesOrder (state, payload) {
          state.finalCategories = payload
        },
        updateDocumentsOrder (state, payload) {
          state.selectedDocuments = payload
        },
        toggleDocumentStatus (state, payload) {
          console.log('Payload : ' + payload)
          const indexInDocuments = state.documents.indexOf(payload)
          Object.assign(state.documents[indexInDocuments], {isPublished: !payload.isPublished})
        },
        toggleCategoryStatus (state, payload) {
          console.log(payload.isPublished)
          const index = state.categories.indexOf(payload)
          Object.assign(state.categories[index], {isPublished: !payload.isPublished})
        }
    }
}