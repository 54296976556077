import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        statusShowing: 'PENDING',
        data: [],
        searchTerm: '',
        isFetching: false,
        offset: 0,
        count: null,
        countAccepted: null,
        countRejected: null,
        countPending: null,
        countBypassed: null,
        minSearchLength: 8,
        searchPerformed: false,
        selectedOverhaul: null,
        editingOverhaul: null,
        isUpdating: false,
    },
    getters: {
        pendingOverhauls (state) {
            //return state.overhauls.filter(overhaul => overhaul.status === 'PENDING')
        },
        bypassedOverhauls (state) {
            //return state.overhauls.filter(overhaul => overhaul.status === 'BYPASSED')
        },
        acceptedOverhauls (state) {
            //return state.overhauls.filter(overhaul => overhaul.status === 'ACCEPTED')
        },
        rejectedOverhauls (state) {
            //return state.overhauls.filter(overhaul => overhaul.status === 'REJECTED')
        },
        canLoadMore (state) {
            return state.data.length < state.count
        },
        getSearchTerm (state) {
            return state.searchTerm
        },
    },
    actions: {
        async GET_DATA ({state, commit}) {
            commit('setFetchingStatus', true);
            const responseData = (await bearerTokenAuthAxios.get(`/api/v6/laposte/overhauls?offset=${state.offset}&search_term=${state.searchTerm}`)).data
            commit('setData', responseData)
            commit('setFetchingStatus', false);
        },
        SET_SEARCH_TERM (context, payload) {
            context.commit('setSearchTerm', payload)
            //context.commit('filterOverhauls')
        },
        SET_STATUS_SHOWING (context, payload) {
            context.commit('setStatusShowing', payload)
        },
        async PERFORM_SEARCH ({commit, state}, payload) {
            commit('setSearchPerformedStatus', true)
            commit('setFetchingStatus', true)
            const response = (await bearerTokenAuthAxios.get(`/api/v6/laposte/overhauls?offset=${state.offset}&search_term=${state.searchTerm}`)).data
            commit('setData', response)
            commit('setFetchingStatus', false)
        },
        SET_SEARCH_PERFORMED_STATUS (context, payload) {
            context.commit('setSearchPerformedStatus', payload)
        },
        async CLEAR_SEARCH ({commit, state}) {
            commit('setSearchTerm', '')
            commit('setSearchPerformedStatus', false)
            commit('setFetchingStatus', true)
            const response = (await bearerTokenAuthAxios.get(`/api/v6/laposte/overhauls?offset=${state.offset}&search_term=${state.searchTerm}`)).data
            commit('setData', response)
            commit('setFetchingStatus', false)
        },
        SELECT_OVERHAUL (context, payload) {
            context.commit('setSelectedOverhaul', payload)
        },
        async UPDATE_OVERHAUL({state, commit}, payload) {
            const overhaulString = { overhaul: JSON.stringify(payload) }
            commit('setUpdatingStatus', true)
            const responseData = (await bearerTokenAuthAxios.put(`/api/v6/laposte/overhauls/update`, overhaulString)).data
            commit('setUpdatingStatus', false)
            commit('updateOverhaul', payload)
            commit('updateCounts')
            commit('setSelectedOverhaul', null)
        }
    },
    mutations: {
        setData (state, payload) {
            state.data = [...state.data, ...payload.data]
            state.count = payload.count
            state.countPending = payload.countPending
            state.countAccepted = payload.countAccepted
            state.countRejected = payload.countRejected
            state.countBypassed = payload.countBypassed
            state.offset++
        },
        setFetchingStatus (state, payload) {
            state.isFetching = payload
        },
        setSearchTerm (state, payload) {
            state.searchTerm = payload
        },
        setStatusShowing (state, payload) {
            state.statusShowing = payload
        },
        setUpdatingStatus (state, payload) {
            state.isUpdating = payload
        },
        setSearchPerformedStatus (state, payload) {
            state.searchPerformed = payload
            state.data = []
            state.offset = 0
        },
        setSelectedOverhaul (state, payload) {
            state.selectedOverhaul = payload
            state.editingOverhaul = payload
        },
        updateOverhaul (state, payload) {
            var index = state.data.findIndex(overhaul => overhaul.id == payload.id)
            Object.assign(state.data[index], {status: payload.status, comment: payload.comment, internalComment: payload.internalComment})
        },
        updateCounts (state, payload) {
            const initOverhaul = state.selectedOverhaul
            const newOverhaul = state.editingOverhaul
            switch (initOverhaul.status) {
                case 'PENDING':
                    state.countPending--
                    break;
                case 'ACCEPTED':
                    state.countAccepted--
                    break;
                case 'REJECTED':
                    state.countRejected--
                    break;
                case 'BYPASSED':
                    state.countBypassed--
                    break;
                default:
                    // Do nothing
            }
            switch (newOverhaul.status) {
                case 'PENDING':
                    state.countPending++
                    break;
                case 'ACCEPTED':
                    state.countAccepted++
                    break;
                case 'REJECTED':
                    state.countRejected++
                    break;
                case 'BYPASSED':
                    state.countBypassed++
                    break;
                default:
                    // Do nothing
            }
        }
    
    }
}