<template>
  <div class="card p-4 p-md-5">
    <div class="mb-3">
      <button type="button" class="btn btn-sm" @click.prevent="store.prevStep()"><i class="bi bi-chevron-left me-2"></i>Etape précédente</button>
    </div>
    <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Récapitulatif</span></h3>
    <SummaryCauseCard />
    <SummaryContextCard />
    <SummaryProductCard />
    <SummaryInformationCard />
    <SummaryBatteryInformationCard />
  </div>
</template>

<script setup>
import SummaryCauseCard from "./summary/CauseCard.vue";
import SummaryContextCard from "./summary/ContextCard.vue";
import SummaryProductCard from "./summary/ProductCard.vue";
import SummaryInformationCard from "./summary/InformationCard.vue";
import SummaryBatteryInformationCard from "./summary/BatteryInformationCard.vue";
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
</script>
