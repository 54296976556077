<template>
  <div class="Scrolling_Text_Container">
    <marquee-text :repeat="10" :duration="40">
        <ScrollingTextItem  v-for="content in messages" :key="content.id" :content="content"/>
    </marquee-text>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import ScrollingTextItem from './ScrollingTextItem.vue';
import { mapState } from 'vuex'
export default {
    components: { MarqueeText, ScrollingTextItem },
    name: 'ScrollingTextContainer',
    computed: {
      ...mapState('statamic/messages', ['messages'])
    },
    created() {
      this.$store.dispatch('statamic/messages/GET_MESSAGES')
    }   
}
</script>

<style>
.Scrolling_Text_Container {
    padding: 0.5rem;
    background-color: hsl(250, 70%, 96%);
    border: 1px solid hsl(250, 40, 50);
}
</style>