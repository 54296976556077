<template>
  <div>
    <h3 class="mb-3">Quel est le modèle de vélo ?</h3>

    <div class="row">
      <div
        v-for="bom in filteredBoms"
        :key="bom.product.mmitno"
        class="col-6 col-xl-4 mb-3 h-100"
      >
        <div class="Card_Bom" v-on:click="handleSelectBom(bom)">
          <div class="Card_Bom__Body">
            <div class="mb-3">
              <div>
                <span>{{ bom.product.mmitno }}</span>
              </div>
              <div>
                <span class="fw-bold">{{ bom.label || bom.product.name }}</span>
              </div>
            </div>
            <div>
              <img
                class="rounded"
                :src="
                  'https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/' +
                    bom.product.mmitno.substring(0, 6) +
                    '.jpg'
                "
                @error="noImageFound"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BomSelector",
  computed: {
    ...mapState("bom", ["boms", "filteredBoms"]),
  },
  methods: {
    handleSelectBom(bom) {
      this.$store.dispatch("bom/SET_BOM", bom);
    },
    noImageFound(e) {
      e.target.src = "/img/bike-placeholder.svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.Card_Bom {
  background-color: white;
  border-radius: 0rem;
  border: 1px solid white;
  //border: 1px solid hsl(220deg, 30%, 90%);
  &__Body {
    padding: 1.25rem 1.5rem;
  }
  &:hover {
    border: 1px solid hsl(220deg, 80%, 60%);
  }
}
</style>
