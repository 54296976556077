<template>
  <div
    v-if="electricalIssue == 'BATT' || electricalIssue == 'BATTERY'"
    class="row mb-3"
  >
    <div class="col-12">
      <div class="battery-card p-5">
        <h5>Informations relatives à la batterie concernée</h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <span class="fw-bold">Cause : </span>{{ batteryIssueCause }}
          </li>
          <li class="list-group-item">
            <span class="fw-bold">Numéro de série : </span
            >{{ batterySerialNumber }}
          </li>
          <li class="list-group-item">
            <span class="fw-bold">Ampérage : </span>{{ batteryAmperage }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SummaryBatteryInformationCard",
  computed: {
    ...mapState("ticket", [
      "electricalIssue",
      "batteryIssueCause",
      "batterySerialNumber",
      "batteryAmperage",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.battery-card {
  background-color: #f6f2f9;
  border-radius: 0.25rem;
}
.list-group {
  font-size: 0.85rem;
  .list-group-item {
    padding: 0.25rem 0;
    background: transparent;
  }
}
</style>
