import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        videos: [],
        latestVideos: [],
        selectedVideo: null,
    },
    getters: {},
    actions: {
        async GET_LATEST_VIDEOS (context, commit) {
            const videosResponse = (await bearerTokenAuthAxios.get('/api/v5/videos/latest')).data
            context.commit('getLatestVideos', videosResponse)
        },
        async GET_VIDEOS (context, commit) {
            const videosResponse = (await bearerTokenAuthAxios.get('/api/v5/videos')).data
            context.commit('getVideos', videosResponse)
        },
        SELECT_VIDEO (context, payload) {
            context.commit('selectVideo', payload)
        },
        DESELECT_VIDEO (context) {
            context.commit('deselectVideo')
        }
    },
    mutations: {
        getVideos (state, payload) {
            state.videos = payload
        },
        getLatestVideos (state, payload) {
            state.latestVideos = payload
        },
        selectVideo (state, payload) {
            state.selectedVideo = payload
        },
        deselectVideo (state) {
            state.selectedVideo = null
        }
    },
}