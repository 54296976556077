import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        data: [],
        searchTerm: '',
        selectedFilter: '',
        offset: 0,
        total: 0,
        isFetching: false,
        searchPerformed: false,
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        },
        canLoadMore (state) {
            return state.data.length < state.total
        }
    },
    actions: {
        async GET_MESSAGES ({state, commit}) {
            commit('setFetchingStatus', true)
            const messagesResponse = (await bearerTokenAuthAxios.get(`/api/v6/support/messages?offset=${state.offset}&search_term=${state.searchTerm}`))
            commit('setData', messagesResponse)
            commit('setFetchingStatus', false)
        },
        SET_FILTER (context, payload) {
            context.commit('setFilter', payload)
        },
        SET_SEARCH_TERM (context, payload) {
            context.commit('setSearchTerm', payload)
        },
        async PERFORM_SEARCH ({commit, state}, payload) {
            commit('setSearchPerformedStatus', true)
            commit('setFetchingStatus', true)
            const ticketsReponse = (await bearerTokenAuthAxios.get(`/api/v6/support/messages?offset=${state.offset}&search_term=${state.searchTerm}`))
            commit('setData', ticketsReponse)
            commit('setFetchingStatus', false)
        },
        async CLEAR_SEARCH ({commit, state}, payload) {
            commit('setFetchingStatus', true)
            commit('setSearchTerm', '')
            commit('setSearchType', '')
            commit('setSearchPerformedStatus', false)
            const ticketsResponse = (await bearerTokenAuthAxios.get(`/api/v6/support/messages?offset=${state.offset}&search_term=${state.searchTerm}`))
            commit('setData', ticketsResponse)
            commit('setFetchingStatus', false)
        }
    },
    mutations: {
        setData (state, payload) {
            state.data = [...state.data, ...payload.data.data]
            state.total = payload.data.count
            state.offset++
        },
        setSearchTerm (state, value) {
            if(state.searchTerm !== value) {
                state.offset = 0
            }
            state.searchTerm = value
        },
        setFetchingStatus (state, payload) {
            state.isFetching = payload
        },
        setSearchPerformedStatus (state, payload) {
            state.searchPerformed = payload
            state.data = []
        },
        setFilter (state, payload) {
            state.selectedFilter = payload
        }
    },
}