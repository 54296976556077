import { render, staticRenderFns } from "./SerialCard.vue?vue&type=template&id=296a1640&scoped=true"
import script from "./SerialCard.vue?vue&type=script&setup=true&lang=js"
export * from "./SerialCard.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SerialCard.vue?vue&type=style&index=0&id=296a1640&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296a1640",
  null
  
)

export default component.exports