<template>
  <div>
    <h2 class="mb-3">Suivi des échanges</h2>
    <div v-if="processing" class="alert alert-flat">Chargement ...</div>
    <div v-for="(comment, index) in comments" :key="comment.id">
      <div v-if="index > 0">
        <div v-if="isFromCustomer[index]">
          <div class="row">
            <div class="col ms-5 mb-2">
              <div class="card Card_Customer">
                <div class="card-body px-5 py-5">
                  <div class="mb-3">
                    <div class="header">
                      <small
                        >Répondu le
                        {{ moment(comment.created_at).format("LLLL") }}</small
                      >
                    </div>
                  </div>
                  <div v-html="comment.html_body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col mb-2">
              <div class="card Card_Support">
                <div class="card-body px-5 py-5">
                  <div class="mb-3">
                    <div class="header">
                      <small
                        >Répondu le
                        {{ moment(comment.created_at).format("LLLL") }}</small
                      >
                    </div>
                  </div>
                  <div v-html="comment.html_body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CommentsHistory",
  components: {},
  props: {
    zendeskId: Number,
  },
  data() {
    return {
      comments: [],
      count: 0,
      processing: true,
    };
  },
  created() {
    this.getComments();
    moment.locale("fr");
  },
  computed: {
    isFromCustomer() {
      return this.comments.map(function(item) {
        return item.via.source.to.name === "Cycleurope Industries";
      });
    },
    isInitMessage() {
      return this.comments.map(function(item) {
        return !item.via.source.to.length;
      });
    },
  },
  methods: {
    getComments() {
      this.processing = true;
      axios
        .get("/api/v2/zendesk/vinrdy/tickets/" + this.zendeskId + "/comments")
        .then((response) => {
          this.comments = response.data.comments;
          this.count = this.comments.count;
          this.processing = false;
        });
    },
  },
  moment,
};
</script>

<style lang="scss" scoped>
.Card_Customer {
  .header {
    padding: 0.5rem 1.25rem;
    border-radius: 0.35rem;
    background-color: rgb(199, 211, 229);
  }
}
.Card_Support {
  .header {
    padding: 0.5rem 1.25rem;
    border-radius: 0.35rem;
    background-color: rgb(217, 211, 152);
  }
}
</style>
