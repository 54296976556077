<template>
  <div class="row">
    <h2 class="mb-3">Que recherchez-vous ?</h2>
    <div class="col-xl-6 mb-3 d-grid">
      <button class="btn btn-pad" v-on:click="handleSelectSearchType('byBike')">
        Je recherche la liste des composants disponibles pour une référence de
        vélo.
      </button>
    </div>
    <div class="col-xl-6 mb-3 d-grid">
      <button
        class="btn btn-pad"
        v-on:click="handleSelectSearchType('byComponent')"
      >
        Je recherche les vélos compatibles pour une référence composant.
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchTypeSelector",
  methods: {
    handleSelectSearchType(searchType) {
      console.log(searchType);
      this.$store.dispatch("bom/SET_SEARCH_TYPE", searchType);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  &.btn-pad {
    background-color: white;
    padding: 2rem 6rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 500;
    &:hover {
      background-color: rgb(40, 148, 191);
      color: white;
    }
  }
}
</style>
