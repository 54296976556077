<template>
  <div class="card p-4 p-md-5">
    <div class="mb-3">
      <button type="button" class="btn btn-sm" @click.prevent="store.prevStep()"><i class="bi bi-chevron-left me-2"></i>Etape précédente</button>
    </div>
    <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Informations</span></h3>
    <div class="mb-3">
      <label for="contact" class="form-label"><span class="fw-semibold">Nom du technicien en atelier</span>&nbsp;<small>(Obligatoire)</small></label>
      <input id="contact" name="contact" type="text" class="form-control form-control-lg" v-model="store.contact"/>
    </div>
    <div class="mb-3">
      <label for="comment" class="form-label"><span class="fw-semibold">Description du problème </span>&nbsp;<small>(Obligatoire)</small></label>
      <div><small>({{ store.commentMinLength }} caractères minimum)</small></div>
      <textarea name="comment" id="comment" rows="3" class="form-control form-control-lg" v-model="store.comment"></textarea>
    </div>
    <div class="mb-3">
      <label for="actions" class="form-label"><span class="fw-semibold">Procédure(s) de recherche de panne(s) effectuée(s)</span></label>
      <textarea name="actions" id="actions" rows="3" class="form-control form-control-lg" v-model="store.actions"></textarea>
    </div>
    <div class="mb-3">
      <label for="error_codes" class="form-label"><span class="fw-semibold">Codes erreur affichés</span></label>
      <input id="error_codes" name="errors" type="text" class="form-control form-control-lg" v-model="store.errors"/>
    </div>
    <div class="mb-3">
      <label for="is_battery" class="col-form-label">
        <span class="fw-semibold">La batterie est-elle concernée par la demande ?</span>
      </label>
      <select name="is_battery" id="is_battery" class="form-control form-control-lg" @change="handleChangeBatteryOption" required>
        <option value="" selected disabled>Sélectionner</option>
        <option value="yes">Oui</option>
        <option value="no">Non</option>
      </select>
    </div>
    <div class="mb-3" v-if="store.isBattery === 'yes'">
      <label for="battery_sn" class="form-label"><span class="fw-semibold">Numéro de série de la batterie</span></label>
      <input type="text" class="form-control form-control-lg" name="battery_sn" v-model="store.batterySerial">
    </div>
    <div class="mb-3" v-if="store.isBattery === 'yes'">
      <label for="battery_amperage" class="form-label"><span class="fw-semibold">Ampérage de la batterie</span></label>
      <input type="text" maxlength="32" class="form-control form-control-lg" name="battery_amperage" width="240" v-model="store.batteryAmperage">
    </div>
    <div class="text-end">
      <button
        v-on:click.prevent="handleSubmit"
        class="btn btn-flat btn-primary"
        v-bind:class="{ disabled: !canSubmit }"
      >Etape suivante<i class="bi bi-chevron-right ms-2"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()

const canSubmit = computed(() => {
  return store.contact.length > 2 
    && store.comment.length > store.commentMinLength
    && (
      store.isBattery === "no" || (store.isBattery === "yes" && store.batterySerial.length > 10 && store.batteryAmperage.length)
    )
})

const handleSubmit = () => {
  store.nextStep()
}

const handleChangeBatteryOption = (e) => {
  store.isBattery = e.target.value
}
</script>
