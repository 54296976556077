export default {
    namespaced: true,
    state: {
        data: [],
        count: null,
        offset: 0,
        searchTerm: '',
        searchPerformed: false,
        isFetching: false,
    },
    getters: {
        getSearchTerm (state) {
            return state.searchTerm
        }
    },
    actions: {
        async GET_DATA ({state, commit}) {
            commit('setFetchingStatus', true)
            const response = (await axios.get(`/api/v6/sys/serials?&offset=${state.offset}&search_term=${state.searchTerm}`)).data
            commit('setFetchingStatus', false)
            commit('setData', response.data)
        },
        PERFORM_SEARCH (context, payload) {
            context.commit('setSearchPerformedStatus', true)
        },
        CLEAR_SEARCH (context, payload) {
            context.commit('setSearchPerformed', false)
            context.commit('setSearchTerm', '')
        },
        SET_SEARCH_TERM (context, payload) {
            context.commit('setSearchTerm', payload)
        }
    },
    mutations: {
        setData (state, payload) {
            state.data = [...state.data, ...payload] 
            state.count = payload.data.count
            state.offset++
        },
        setFetchingStatus (state, payload) {
            state.isFetching = payload
        },
        setSearchPerformedStatus (state, payload) {
            state.offset = 0
            state.searchPerformed = payload
        },
        setSearchTerm (state, payload) {
            state.searchTerm = payload
        }
    }
}