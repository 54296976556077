<template>
  <div class="card p-4 p-md-5">
    <div>
      <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Identification du vélo</span></h3>
      <div v-if="store.serial">
        <div id="Serial_Info">
          <div><img :src="`https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/${store.serial.product.mmitno.substring(0, 6)}.jpg`"/></div>
          <div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item py-1">{{ store.serial.code }}</li>
              <li class="list-group-item py-1">{{ store.serial.product.mmitno }}</li>
              <li class="list-group-item py-1">{{ store.serial.product.m3designation }}</li>
            </ul>
          </div>
        </div>
        <input type="hidden" class="form-control form-control-lg mb-2" name="serial" id="serialnumber" v-model="store.serial.code"/>
        <div class="text-end">
          <button
              type="button"
              role="button"
              class="btn btn-sm btn-danger"
              v-bind:class="{ disabled: !canSubmit }"
              @click.prevent="store.cancelSerial"><i class="bi bi-chevron-left me-2"></i>Retour</button>
        </div>
      </div>
      <div v-else-if="store.isFetchingSerial">
        <div class="alert alert-primary">Recherche du vélo ...</div>
      </div>
      <div v-else>
        <label for="serialnumber" class="form-label mb-2"><span class="fw-semibold">Numéro de chassis</span></label>
        <input type="text" class="form-control form-control-lg mb-2" name="serial" id="serialnumber" v-model="store.serialNumber"/>
        <button
            type="button"
            role="button"
            class="btn btn-primary"
            :class="{ disabled: !canSearchSerial }"
            @click.prevent="handleSearchSerial">Rechercher</button>
        <div class="text-danger fw-bold" v-if="store.serialFetchError">{{ store.serialFetchError }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useTransportDamageStore } from '@/store/useTransportDamageStore'
const store = useTransportDamageStore()

const handleSearchSerial = () => {
  console.log("Search serial ...")
  store.fetchSerial()
}

const canSearchSerial = computed(() => {
  return store.serialNumber.length >= 8
})

const canSubmit = computed(() => {
  return store.serial != null
})

const handleSubmit = () => {
  store.nextStep()
}

</script>


<style class="scss" scoped>
#Serial_Info {
  display: grid;
  grid-template-columns: 160px auto;
  @media (min-width: 768px) {
    grid-template-columns: 240px auto;
    gap: 2rem;
  }
}
</style>