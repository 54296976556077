<template>
  <div class="row">
    <div class="col-12">
      <div class="Card_Items">
        <div class="Card_Items__Body p-xl-5">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item px-0 py-1"
              v-for="item in selectedBom.items"
              :key="item.id"
            >
              <bom-item :item="item"></bom-item>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BomItem from "./BomItem.vue";
export default {
  name: "BomDetail",
  components: {
    BomItem,
  },
  computed: {
    ...mapState("bom", ["selectedBom"]),
  },
};
</script>

<style lang="scss" scoped>
.Card_Items {
  background-color: white;
  border-radius: 0;
  border: 1px solid white;
  //border: 1px solid hsl(220deg, 30%, 90%);
  &__Body {
    padding: 1.25rem 1.5rem;
  }
}
.list-group {
  .list-group-item {
    border: none;
  }
}
</style>
