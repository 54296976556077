<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="Card_Header">
        <div class="Card_Header__Body">
          <div class="row">
            <div v-if="selectedBrand" class="col-12 mb-3">
              <div class="mb-3">
                <span class="fw-bold">Marque sélectionnée : </span>
              </div>
              <div class="mb-3">
                <img
                  :src="selectedBrand.logo"
                  @error="noImageFound"
                  alt=""
                  v-if="selectedBrand.logo"
                />
              </div>
              <div>
                <button
                  v-show="selectedBrand && !selectedGroup"
                  v-on:click="handleUnsetBrand"
                  class="btn btn-flat btn-flat-sm btn-primary"
                >
                  <arrow-left-circle class="me-2"></arrow-left-circle>Retour
                </button>
              </div>
            </div>
            <div v-if="selectedGroup" class="col-12 mb-3">
              <div class="mb-3">
                <div class="mb-3">
                  <span class="fw-bold">Catégorie sélectionnée : </span>
                </div>
                <h4>{{ selectedGroup.name }}</h4>
              </div>
              <div>
                <button
                  v-show="selectedGroup && !selectedBom"
                  v-on:click="handleUnsetGroup"
                  class="btn btn-flat btn-flat-sm btn-primary"
                >
                  <arrow-left-circle class="me-2"></arrow-left-circle>Retour
                </button>
              </div>
            </div>
            <div v-if="selectedBom" class="col-12 mb-3">
              <div class="mb-3">
                <span class="fw-bold">Modèle sélectionné : </span>
              </div>
              <div class="mb-3">
                <div class="mb-3">
                  <img
                    :src="
                      'https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/' +
                        selectedBom.product.mmitno.substring(0, 6) +
                        '.jpg'
                    "
                    class="rounded"
                    @error="noImageFound"
                  />
                </div>
                <div>
                  <ul>
                    <li>{{ selectedBom.product.mmitno }}</li>
                    <li>{{ selectedBom.product.mmitds }}</li>
                  </ul>
                </div>
              </div>
              <div>
                <button
                  v-show="selectedBom"
                  v-on:click="handleUnsetBom"
                  class="btn btn-flat btn-flat-sm btn-primary"
                >
                  <arrow-left-circle class="me-2"></arrow-left-circle>Retour
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ArrowLeftCircle from "vue-material-design-icons/ArrowLeftCircle.vue";

export default {
  name: "Header",
  components: {
    ArrowLeftCircle,
  },
  computed: {
    ...mapState("bom", ["selectedBrand", "selectedGroup", "selectedBom"]),
  },
  methods: {
    handleUnsetBom() {
      this.$store.dispatch("bom/UNSET_BOM");
    },
    handleUnsetGroup() {
      this.$store.dispatch("bom/UNSET_GROUP");
    },
    handleUnsetBrand() {
      this.$store.dispatch("bom/UNSET_BRAND");
    },
    noImageFound(e) {
      e.target.src = "/img/bike-placeholder.svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.Card_Header {
  display: block;
  position: sticky;
  top: 32px;
  background-color: white;
  border: 1px solid white;
  border-radius: 0rem;
  &__Body {
    padding: 1.25rem 1.5rem;
  }
}
</style>
