<template>
  <div>
    <h3 class="mb-3">Quel type de vélo est-ce ?</h3>
    <div class="row">
      <div
        v-for="group in groupsWithBoms"
        :key="group.id"
        class="col-6 col-xl-3 mb-3 h-100"
      >
        <div class="Card_Group" v-on:click="handleSelectGroup(group)">
          <div class="Card_Group__Body">
            {{ group.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "GroupSelector",
  computed: {
    ...mapState("bom", ["groups"]),
    ...mapGetters("bom", ["groupsWithBoms"]),
  },
  methods: {
    handleSelectGroup(value) {
      this.$store.dispatch("bom/SET_GROUP", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.Card_Group {
  background-color: white;
  border: 1px solid white;
  border-radius: 0;
  &__Body {
    padding: 1.25rem 1.5rem;
  }
  &:hover {
    border: 1px solid hsl(220deg, 80%, 60%);
  }
}
</style>
