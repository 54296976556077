import Vue from 'vue';
import Vuex from 'vuex';

// Support
import messages from './modules/support/messages'
import returnorders from './modules/support/returnorders'
import expertises from './modules/support/expertises'
import labours from "./modules/support/labours"
import mandates from './modules/support/mandates'
import eurostarReturns from './modules/eurostar'

// Statamic
import statamicFaq from './modules/statamic/faq'
import statamicPosts from "./modules/statamic/posts"
import statamicVideos from "./modules/statamic/videos"
import statamicAddressbook from './modules/statamic/addressbook'
import statamicMessages from './modules/statamic/messages'

// La Poste
import laPosteOverhauls from "./modules/laposte/overhauls"

import pos from "./modules/pos"
import bom from './modules/bom'
import documents from "./modules/documents"

import adminLivefeed from './modules/admin/livefeed'
import adminDashboard from "./modules/admin/dashboard"
import adminEmails from './modules/admin/emails'
import zendeskSatisfaction from "./modules/zendesk/satisfaction"
import satisfaction from "./modules/satisfaction"
import metrics from "./modules/metrics"

import products from "./modules/products"
import serials from "./modules/serials"
import user from './modules/user'
import zendeskIdentities from './modules/zendesk/identities'

import systemBrands from './modules/system/brands'
import systemProducts from './modules/system/products'
import systemSerials from './modules/system/serials'

import ticket from './modules/ticket'

import returnorderForm from './modules/support/forms/returnorder'

import masterclasses from './modules/masterclasses'
Vue.use(Vuex)
Vue.config.devtools = true

const store = new Vuex.Store({
    state: {
      step: 0,
      show: false,
    },
    actions: {
      NEXT_STEP (context) {
        context.commit('nextStep')
      },
      PREV_STEP (context) {
        context.commit('previousStep')
      },
      SHOW_FORM (context) {
        context.commit('showForm')
      },
    },
    mutations: {
      nextStep (state) {
        state.step++
      },
      previousStep (state) {
          state.step--
      },
      setStep (state, value) {
        state.step = value
      },
      showForm(state) {
        state.show = true
      },
    },
    modules: {
      ticket,
      bom,
      labours,
      products,
      adminDashboard,
      metrics,
      pos,
      zendeskSatisfaction,
      laPosteOverhauls,
      serials,
      documents,
      messages,
      user,
      satisfaction,
      returnorders,
      zendeskIdentities,
      adminLivefeed,
      expertises,
      eurostarReturns,
      mandates,
      masterclasses,
      admin: {
        namespaced: true,
        modules: {
          emails: {
            ...adminEmails
          }
        }
      },
      statamic: {
        namespaced: true,
        modules: {
          messages: {
            ...statamicMessages
          },
          videos: {
            ...statamicVideos
          },
          posts: {
            ...statamicPosts
          },
          addressbook: {
            ...statamicAddressbook
          },
          faq: {
            ...statamicFaq
          }
        }
      },
      system: {
        namespaced: true,
        modules: {
          brands: {
            ...systemBrands
          },
          products: {
            ...systemProducts
          },
          serials: {
            ...systemSerials
          }
        }
      },
      laPoste: {
        namespaced: true,
        modules: {
          overhauls: {
            ...laPosteOverhauls
          }
        }
      },
      support: {
        namespaced: true,
        modules: {
          forms: {
            namespaced: true,
            modules: {
              returnorder: {
                ...returnorderForm
              }
            }
          }
        }
      }
    },
  });

export default store;