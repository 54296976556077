<template>
  <div class="row">
    <div class="col-xl-8">
      <h5>Identité du produit concerné</h5>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="fw-bold">Numéro de série : </span>{{ store.serialNumber }}
        </li>
        <li class="list-group-item">
          <span class="fw-bold">Marque : </span>{{ store.serial.brand }}
        </li>
        <li class="list-group-item">
          <span class="fw-bold">Référence : </span>{{ store.serial.reference }}
        </li>
        <li class="list-group-item">
          <span class="fw-bold">Désignation : </span>{{ store.serial.m3designation }}
        </li>
      </ul>
    </div>
    <div class="col-xl-4">
      <img :src="`https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/${store.serial.reference.substring(0, 6)}.jpg`"/>
    </div>
  </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
</script>

<style lang="scss" scoped>
.list-group {
  font-size: 0.85rem;
  .list-group-item {
    padding: 0.25rem 0;
  }
}
</style>
