<template>
  <div class="Media_Item">
    <span class="Media_Item_Name">{{ media.name }}</span>
    <button type="button" class="Button_Remove_Item" @click.prevent="handleDelete"><i class="bi bi-x-circle"></i></button>
  </div>
</template>

<script setup>
const props = defineProps({
  media: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['remove'])

const handleDelete = () => {
  emit('remove')
}
</script>

<style lang="scss" scoped>
.Media_Item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0369a1;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  .Button_Remove_Item {
    background-color: #e11d48;
    color: white;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 32px;
    height: 32px;
    align-items: center;
    border: 0;
    border-radius: 16px;
    transition: all .1s ease-in-out;
    &:hover {
      background-color: #f43f5e;
    }
  }
}
</style>