
window.Vue = require('vue')
window.axios = require('axios')
import '../bootstrap';
import VueCompositionApi from '@vue/composition-api'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueI18n from 'vue-i18n'
Vue.use(PiniaVuePlugin)
Vue.use(VueI18n)
Vue.use(VueCompositionApi);

const messages = {
    fr: {
        status: {
            incomplete: 'Incomplet',
            new: 'Nouveau',
            open: 'Ouvert',
            hold: 'En attente',
            solved: 'Résolu',
            closed: 'Cloturé',
        },
        support: {
            accepted: 'Accepté',
            rejected: 'Refusé',
            red: 'Lu',
            red_answered: 'Répondu',
            delegate: 'Expertise',
            undefined: 'ND',
        }
    },
    en: {
        status: {
            incomplete: 'Incomplete',
            new: 'New',
            open: 'Open',
            hold: 'Hold',
            solved: 'Solved',
            closed: 'Closed',
        },
        support: {
            accepted: 'Accepted',
            rejected: 'Rejected',
            red: 'Red',
            red_answered: 'Answered',
            delegate: 'Expertise',
            undefined: 'Undefined',
        }
    }
}

const i18n = new VueI18n({
    locale: window.User.locale,
    fallbackLocale: "fr",
    messages
});

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle as faRegularCircle, faSquareCheck, faCircleCheck as faRegularCircleCheck, faPaperPlane} from '@fortawesome/free-regular-svg-icons'
import { faArrowUp, faArrowDown, faArrowRight, faGears, faCircleCheck, faEye, faToggleOff, faToggleOn, faSort, faCircle as faSolidCircle, faPenToSquare, faCircleDot, faPlay, faStop, faChevronRight, faChevronLeft, faCircleCheck as faSolidCircleCheck, faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faArrowUp, faArrowDown, faArrowRight, faGears, faCircleCheck, faEye, faToggleOff, faToggleOn, faSort, faRegularCircle, faSolidCircle, faSquareCheck, faPenToSquare, faCircleDot, faPlay, faStop, faChevronRight, faChevronLeft, faSolidCircleCheck, faRegularCircleCheck, faPaperPlane, faPhoneFlip )

Vue.component('fa-icon', FontAwesomeIcon)

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X_CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
}
// Boilerplate
require('../bootstrap');
require('../plugins');

var _ = require('lodash');

//Vue.component('ticket-form-component', require('../components/Frontend/Support/Tickets/TicketFormComponent.vue').default)
//Vue.component('special-form-component', require('../components/Frontend/Support/Tickets/Special/SpecialFormComponent.vue').default)
Vue.component('ticket-metrics', require('../components/Frontend/Support/Tickets/TicketMetrics.vue').default)
Vue.component('comments-history', require('../components/Frontend/Support/Tickets/CommentsHistory.vue').default) 
//Vue.component('ready-to-return', require('../components/Frontend/Support/Tickets/ReadyToReturnButton.vue').default)
Vue.component('contact-form', require('../components/Frontend/Support/Contact/ContactFormComponent.vue').default)
//Vue.component('subscription-form', require('../components/Frontend/Masterclasses/SubscriptionForm.vue').default)
//Vue.component('video-searchbar', require('../components/Frontend/Video/VideoSearchBar.vue').default)
//Vue.component('videos-list', require('../components/Frontend/Video/VideosListComponent.vue').default)
//Vue.component('video-player', require('../components/Frontend/Video/VideoPlayerContainer.vue').default)
//Vue.component('video-item', require('../components/Frontend/Video/VideoItem.vue').default)

Vue.component('labour-request-form', require('../components/Frontend/Support/LabourRequest/LabourRequestForm').default)
Vue.component('labour-request-claimer', require('../components/Frontend/Support/LabourRequest/LabourRequestClaimer').default)
Vue.component('transport-damage-form', require('../components/Frontend/Support/TransportDamage/TransportDamageForm').default)
Vue.component('notifications-form', require('../components/Frontend/User/NotificationsFormComponent').default)
Vue.component('mail-notifications-indicator', require('../components/Frontend/User/MailNotificationsIndicator').default)
Vue.component('mobile-notifications-indicator', require('../components/Frontend/User/MobileNotificationsIndicator').default)
Vue.component('popin-announcement', require('../components/Frontend/Announcements/Popin').default);
//Vue.component('passport-clients',require('../components/passport/Clients.vue').default);
//Vue.component('passport-authorized-clients',require('../components/passport/AuthorizedClients.vue').default);
//Vue.component('passport-personal-access-tokens',require('../components/passport/PersonalAccessTokens.vue').default);
Vue.component('customer-tickets', require('../components/Frontend/Support/Tickets/CustomerTickets.vue').default)
Vue.component('reports-form', require('../components/Frontend/LaPoste/ReportsForm.vue').default)
//Vue.component('questions-component', require('../components/Frontend/FAQ/QuestionsComponent.vue').default)

//Vue.component('mandate-ready-button', require('../components/Frontend/Support/Mandates/MandateReadyButton.vue').default)

/* Contact */
Vue.component('messages-container', require('../components/Frontend/Support/Contact/MessagesContainer.vue').default)
/* Formations */
Vue.component('masterclass-subscription-form', require('../components/Frontend/Masterclasses/MasterclassSubscriptionForm.vue').default)

/* Point de vente */
Vue.component('shop-container', require('../components/Frontend/Shop/ShopContainer.vue').default)
Vue.component('shop-map', require('../components/Frontend/Shop/ShopMap.vue').default)


//Vue.component('tickets-wrapper', require('../components/Frontend/Support/Tickets/TicketsWrapper.vue').default)
//Vue.component('wrapper-next', require('../components/Frontend/Support/Tickets/v2/MainWrapper.vue').default)

//Vue.component('tickets-browser', require('../components/Frontend/Support/Tickets/v2/TicketsBrowser.vue').default)

//Vue.component('tickets-container', require('../components/Frontend/Support/Tickets/User/TicketsContainer.vue').default)
Vue.component('presentation-validation', require('../components/Frontend/User/presentationValidation.vue').default)

// 2022
// En développement
Vue.component('labour-wrapper', require('../components/Frontend/Support/Labour/LabourWrapper.vue').default)
Vue.component('labour-requests-form', require('../components/Frontend/Support/LabourRequest/LabourRequestForm.vue').default)
Vue.component('addressbook-container', require('../components/Frontend/Content/AddressBook/AddressBookContainer.vue').default)
Vue.component('addressbook-slider', require('../components/Frontend/Content/AddressBook/AddressBookSlider.vue').default)

// Développement - Février 2023
Vue.component('boms-container', require('../components/Frontend/Boms/BomsContainer.vue').default)


// En recette
Vue.component('request-form-component', require('../components/Frontend/Support/Requests/RequestFormComponent.vue').default)
Vue.component('transport-damage-form-component', require('../components/Frontend/Support/TransportDamage/TransportDamageFormComponent.vue').default)

Vue.component('bom-wrapper', require('../components/Frontend/BillOfMaterials/BomWrapper.vue').default)
Vue.component('documents-manager', require('../components/Frontend/Documents/DocumentsManager.vue').default)

/** Profil */
Vue.component('profile-container', require('../components/Frontend/User/ProfileContainer.vue').default)
Vue.component('scrolling-text-container', require('../components/Frontend/Content/ScrollingText/ScrollingTextContainer.vue').default)
Vue.component('information-container', require('../components/Frontend/Content/Information/InformationContainer.vue').default)
Vue.component('latest-tickets-container', require('../components/Frontend/Dashboard/Support/LatestTicketsContainer.vue').default)
Vue.component('latest-posts-container', require('../components/Frontend/Content/Blog/LatestPostsContainer.vue').default)
Vue.component('posts-container', require('../components/Frontend/Content/Blog/PostsContainer.vue').default)
Vue.component('latest-videos-container', require('../components/Frontend/Content/Videos/LatestVideosContainer.vue').default)

Vue.component('questions-container', require('../components/Frontend/Content/Faq/FaqContainer').default)

Vue.component('status-badge', require('../components/Common/Badges/StatusBadge.vue').default)
Vue.component('support-badge', require('../components/Common/Badges/SupportBadge.vue').default)


Vue.component('videos-container', require('../components/Frontend/Content/Videos/VideosContainer.vue').default)
Vue.component('overhauls-container', require('../components/Frontend/LaPoste/Overhauls/OverhaulsContainer.vue').default)
// En prod


// Nouveau formulaire de garantie 

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText)


// Nouveau formulaire de rapports de cadres La Poste (2024-04-18)
Vue.component('frame-reports-form', require('../components/Frontend/LaPoste/FrameReports/FrameReportsForm.vue').default)

import Vue from 'vue';
import store from '../store/index'

export const serverBus = new Vue();
const pinia = createPinia()

const app = new Vue({
    el: '#app',
    store,
    i18n,
    data() {
        return {

        }
    },
    pinia
});

$(document).ready(function() {
    $('#fs-menu-toggler').on('click', function() {
        $('#fs-menu').addClass('show');
    });
});