<template>
  <div class="Profile_Container">
    <div class="Profile_Container__Body">
        <h2>Mes informations principales</h2>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ProfileContainer',
    props: { user: { type: Object }}
}
</script>

<style lang="scss" scoped>
.Profile_Container {
    background: white;
    border-radius: 0.5rem;
    &__Body {
        padding: 1rem 1.5rem;
    }
}
</style>