<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info">
        Récupération des demandes de main d'œuvre ...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LabourPlaceholder",
};
</script>
