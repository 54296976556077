import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  actions: {
    async GET_DATA(context) {
      const emailsResponse = (await axios.get('/api/v6/emails'))
      console.log(emailsResponse.data.data)
      context.commit('setData', emailsResponse.data.data)
    }
  },
  mutations: {
    setData (state, payload) {
      state.data = [...payload, ...state.data]
    }
  },
}