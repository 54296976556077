<template>
  <div class="row">
    <AddressBookCard v-for="contact in publishedAddressBook" 
        class-attributes="col-xxl-6"
        :contact="contact" 
        :key="contact.id" />
  </div>
</template>

<script>
import AddressBookCard from './AddressBookCard.vue'
import { mapState} from 'vuex'
export default {
    components: { AddressBookCard },
    name: 'AddressBookContainer',
    computed: {
        ...mapState('statamic/addressbook', ['addressbook']),
        publishedAddressBook: function() {
            return this.addressbook.filter(a => a.status === 'published')
        }
    },
    created() {
        this.$store.dispatch('statamic/addressbook/GET_ADDRESSBOOK')
    }
}
</script>

<style>

</style>