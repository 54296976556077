import axios from "axios"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        tickets: {
            lastCalls: [],
            metrics: {}
        },
        expertises: {
            metrics: {}
        },
        boms: {
            total: null,
            recent: [],
        },
        livefeed: {}
    },
    getters: {},
    actions: {
        async GET_CALLS (context) {
            const calls = (await bearerTokenAuthAxios.get("/api/v4/support/tickets/calls")).data
            context.commit('setCalls', calls)
        },
        async GET_TICKETS_METRICS (context) {
            const metrics = (await bearerTokenAuthAxios.get("/api/v5/metrics/support/tickets")).data
            context.commit('setTicketsMetrics', metrics)
        },
        async GET_EXPERTISES_METRICS (context) {
            const metrics = (await bearerTokenAuthAxios.get("/api/v5/metrics/support/expertises")).data
            context.commit('setExpertisesMetrics', metrics)
        },
        async GET_RECENT_BOMS (context) {
            const boms = (await bearerTokenAuthAxios.get("/api/v5/boms/recent")).data.boms
            context.commit('setRecentBoms', boms)
        },
        async GET_LIVEFEED (context) {
            const livefeed = (await bearerTokenAuthAxios.get("/api/v5/_livefeed_")).data
            context.commit('setLiveFeed', livefeed)
        }
    },
    mutations: {
        setCalls (state, payload) {
            state.tickets.lastCalls = payload
        },
        setTicketsMetrics (state, payload) {
            state.tickets.metrics = payload
        },
        setExpertisesMetrics (state, payload) {
            state.expertises.metrics = payload
        },
        setRecentBoms (state, payload) {
            state.boms.recent = payload
        },
        setLiveFeed (state, payload) {
            state.livefeed = payload
        }
    }
}