<template>
  <div class="Latest_Tickets_Container" v-if="latestTickets.length">
    <div class="row">
        <h2 class="mb-3">Dernières demandes de garantie en cours</h2>
        <TicketCard v-for="t in latestTickets" :key="t.id" :ticket="t" />
        <a href="/support/tickets">Toutes les demandes de garantie</a>
    </div>
  </div>
</template>

<script>
import TicketCard from './TicketCard'
import { mapState } from 'vuex'
export default {
    components: { TicketCard },
    name: 'LatestTicketsContainer',
    props: { username: { type: String, default: null }},
    computed: {
        ...mapState('user', ['tickets']),
        latestTickets: function () {
            return this.tickets.filter(ticket => ticket.zendesk_id).slice(0, 3)
        }
    },
    created() {
        this.$store.dispatch('user/GET_TICKETS', this.username)
    }
}
</script>

<style>

</style>