export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        async INIT (context) {
            const endpoint = process.env.MIX_ZENDESK_ENDPOINT;
            const username = process.env.MIX_ZENDESK_USERNAME;
            const token = process.env.MIX_ZENDESK_TOKEN;
            let authData = Buffer(`${username}:${token}`, 'base64')
            console.log(authData)
            const response = (await axios.get('/api/v2/satisfaction-ratings/all')).data
            console.log(response)
        }
    },
    mutations: {},
}