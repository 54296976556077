<template>
  <div class="Badge_Status" :class="[size, this.status ]">{{ $t(`status.${this.status}`) }}</div>
</template>

<script>
export default {
    name: 'StatusBadge',
    props: { status: { type: String, default: '' }, size: { type: String, default: 'Medium'}},
    computed: {
        // label: function () {
        //     //return this.status.toUpperCase()
        //     return $t(`status.${this.status}`).toUpperCase()
        // },
    }
}
</script>

<style lang="scss" scoped>
.Badge_Status {
    display: inline-block;
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    background-color: hsl(250, 18%, 95%);
    color: hsl(250, 68%, 30%);
    font-weight: 500;
    &.Small {
        padding: 0.35rem 0.85rem;
        font-size: 0.8rem;
        border-radius: 0.3rem;
    }   
    &.Medium {
        font-size: 1rem;
    }
    &.Large {
        padding: 0.75rem 1.25rem;
        font-size: 1.15rem;
        border-radius: 0.45rem;
    }
    &.incomplete {
        background-color: hsl(46, 32%, 87%);
        color: hsl(46, 32%, 47%);
    }
    &.new {
        background-color: hsl(209, 100%, 95%);
        color: hsl(209, 100%, 55%);
    }
    &.open {
        background-color: hsl(209, 100%, 55%);
        color: hsl(209, 100%, 95%);
    }
    &.solved {
        background-color: hsl(260, 100%, 95%);
        color: hsl(260, 100%, 55%);
    }
    &.closed {
        background-color: hsl(260, 0%, 95%);
        color: hsl(260, 0%, 55%);
    }
}
</style>