const { default: Swal } = require("sweetalert2");

$(function () {
    /**
     * Checkbox tree for permission selecting
     */
    $('#tree :checkbox').on('click change', function (){
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        } else {
            $(this).siblings('ul').find('input[type="checkbox"]').removeAttr('checked').removeAttr('disabled');
        }
    });
    $('#tree :checkbox').each(function () {
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        }
    });

    /**
     * Disable submit inputs in the given form
     *
     * @param form
     */
    function disableSubmitButtons(form) {
        form.find('input[type="submit"]').attr('disabled', true);
        form.find('button[type="submit"]').attr('disabled', true);
    }

    /**
     * Enable the submit inputs in a given form
     *
     * @param form
     */
    function enableSubmitButtons(form) {
        form.find('input[type="submit"]').removeAttr('disabled');
        form.find('button[type="submit"]').removeAttr('disabled');
    }

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        disableSubmitButtons($(this));
        return true;
    });

    /**
     * Add a confirmation to a delete button/form
     */
    $('body')
        .on('submit', 'form[name=delete-item]', function(e) {
            e.preventDefault();
            Swal.fire({
                title: 'Are you sure you want to delete this item?',
                showCancelButton: true,
                confirmButtonText: 'Confirm Delete',
                cancelButtonText: 'Cancel',
                icon: 'warning'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('submit', 'form[name=confirm-item]', function (e) {
            e.preventDefault();

            Swal.fire({
                title: 'Are you sure you want to do this?',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                icon: 'warning'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('submit', 'form[name=confirm-delete-ticket]', function (e) {
            e.preventDefault();
            Swal.fire({
                title: 'Souhaitez-vous vraiment supprimer cette demande de garantie ?',
                showCancelButton: true,
                confirmButtonText: 'Oui, je veux supprimer cette demande',
                cancelButtonText: 'Annuler',
                icon: 'question'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('submit', 'form[name=confirm-delete-shop]', function (e) {
            e.preventDefault();
            Swal.fire({
                title: 'Souhaitez-vous vraiment supprimer ce point de vente ?',
                showCancelButton: true,
                confirmButtonText: 'Oui, je veux supprimer ce point de vente',
                cancelButtonText: 'Annuler',
                icon: 'question'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('click', 'a[name=confirm-item]', function (e) {
            e.preventDefault();
            Swal.fire({
                title: 'Are you sure you want to do this?',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                icon: 'info',
            })
            .then((result) => {
                result.value && window.location.assign($(this).attr('href'));
            });
        })
        .on('click', 'a[name=confirm-reset-expertise]', function (e) {
            e.preventDefault();
            Swal.fire({
                title: 'Confirmez-vous la réinitialisation de cette expertise ?',
                showCancelButton: true,
                confirmButtonText: 'Je confirme',
                cancelButtonText: 'Annuler',
                icon: 'info',
            });
        });

    // Remember tab on page load
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        let hash = $(e.target).attr('href');
        history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
    });

    let hash = window.location.hash;
    if (hash) {
        $('.nav-link[href="'+hash+'"]').tab('show');
    }

    // Enable tooltips everywhere

    $('[data-toggle="tooltip"]').tooltip();

    if ($("#mc-description").length) {
        $("#mc-description").summernote({height: 400});
        $("#mc-description").dropdown();
    }

    if ($("#mc-program").length) {
        $("#mc-program").summernote({
            height: 400,
            toolbar: [
            // [groupName, [list of button]]
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['insert', ['link']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['para', ['ul', 'ol', 'paragraph']],
            ]
        });
        $("#mc-program").dropdown();
    }

    if ($("#mc-content").length) {
        $("#mc-content").summernote({height: 400});
        $("#mc-content").dropdown();
    }
    if ($("#lfm").length) {
        $('#lfm').filemanager('file');
    }
    if ($("#lfm-cover").length) {
        $('#lfm-cover').filemanager('file');
    }
    if ($("#lfm-thumbnail").length) {
        $('#lfm-thumbnail').filemanager('file');
    }




    $('.scroll').on('click',function(e) {
        e.preventDefault();
        var offset = 0;
        var target = this.hash;
        if ($(this).data('offset') != undefined) offset = $(this).data('offset');
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset
        }, 500, 'swing', function() {
            // window.location.hash = target;
        });
    });


});
