<template>
  <div class="col-12 mb-3" v-if="isDisplayed">
    <div class="Information_Item h-100">
        <h4 class="mb-3">{{ message.title }}</h4>
        <div v-html="message.content"></div>
    </div>
  </div>
</template>

<script>
export default {
    name: "InformationItem",
    props: { message: { type: Object, default: null }},
    computed: {
        isDisplayed: function() {
            return !this.message.show_in_bar.value && this.message.status === "published"
        }
    }
}
</script>

<style lang="scss" scoped>
.Information_Item {
    background-color: white;
    padding: 1.5rem 2rem;
    border-radius: 0.4rem;
    transition: all 1s;
    border: 1px solid hsl(220deg, 30%, 90%);
    &:hover {
        //box-shadow:  0px 4px 16px rgba(0,0,0, 0.08);
    }
}
</style>