<template>
  <div>
    <h4 class="mb-1">Contexte</h4>
    <span>{{ store.selectedContext.label }}</span>
  </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()
</script>
