<template>
  <div>
    <div class="row" v-if="!fetchingLabours">
      <div
        v-for="labour in labours"
        :key="labour.ticket.zendesk_id"
        class="col-12 mb-3"
      >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-2 mb-3 mb-xl-0">
                <img
                  :src="
                    'https://img.tandem-cycles.fr/img/cycleurope/bikes/middle/' +
                      labour.ticket.product.reference.substring(0, 6) +
                      '.jpg'
                  "
                  class="rounded"
                  @error="noImageFound"
                />
              </div>
              <div class="col-xl-6 mb-3 mb-xl-0">
                <div>
                  <span class="fw-bold">{{ labour.ticket.zendeskId }}</span>
                </div>
                <div>{{ labour.ticket.product.serial }}</div>
                <div>
                  {{ labour.ticket.product.reference }}
                  {{ labour.ticket.product.designation }}
                </div>
                <div>{{ labour.support }}</div>
              </div>
              <div class="col-xl-4 mb-3 mb-xl-0">
                <div>{{ labour.annotation }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <labour-placeholder v-else></labour-placeholder>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LabourPlaceholder from "./LabourPlaceholder.vue";
export default {
  name: "LabourWrapper",
  components: { LabourPlaceholder },
  props: {
    user: { type: Object, default: null },
  },
  computed: {
    ...mapState("labour", ["labours", "fetchingLabours"]),
  },
  mounted() {
    this.$store.dispatch("labour/SET_USER", this.user.username);
    this.$store.dispatch("labour/GET_LABOURS");
  },
  methods: {
    noImageFound(e) {
      e.target.src = "/img/bike-placeholder.svg";
    },
  },
};
</script>
