<template>
  <div class="Container row">
    <div v-if="selectedPost" class="col-12 mb-5">
        <div class="row">
            <div class="col-xl-7">
                <h1 v-text="selectedPost.title" class="mb-4"></h1>
                <div v-html="selectedPost.content" class="mb-4"></div>
                <div>
                    <button class="Button_Back" v-on:click="handleDeselectPost">Retour</button>
                </div>
            </div>
            <!-- <div class="col-xl-5">
                <RelatedPost v-for="rp in selectedPost.related_posts" :id="rp" :key="rp" />
            </div> -->
        </div>
    </div>
    <div v-else>
        <PostsList/>
        <Pagination />
    </div>
  </div>
</template>

<script>
import Post from './Post.vue'
import Pagination from './Pagination/Pagination.vue'
import { mapState } from 'vuex'
import PostsList from './PostsList.vue'
import RelatedPost from './RelatedPost.vue'
export default {
    components: { Post, Pagination, PostsList, RelatedPost },
    name: 'PostsContainer',
    computed: {
        ...mapState('statamic/posts', ['selectedPost'])
    }, 
    methods: {
        handleDeselectPost() {
            history.pushState(null, 'Articles', '/articles')
            this.$store.dispatch('statamic/posts/SET_SELECTED_POST', null)

        }
    }
}
</script>

<style lang="scss" scoped>
.Button_Back {
    border: 0;
    background-color: hsl(220deg, 30%, 98%);
    color: hsl(220deg, 80%, 30%);
    padding: 0.25rem 0.75rem;
    border-radius: 0.15rem;
    transition: all .1s;
}
</style>