<template>
    <div class="Links" v-if="showPagination">
        <PageLink 
        class="Page_Link"
            v-for="(link, idx) in data.links" 
            :key="idx"
            :link="link" />
    </div>
</template>

<script>
import PageLink from './PageLink.vue'
import { mapState } from 'vuex'
export default {
    components: { PageLink },
    name: 'Pagination',
    created() {
        console.log(this.data)
    },
    computed: {
        ...mapState('statamicPosts', ['data']),
        showPagination: function() {
            return this.data && this.data.links?.length > 3
        }
    }
}
</script>

<style lang="scss" scoped>
.Links {
    display: flex;
    gap: 0.25rem;
}
</style>