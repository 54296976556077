import axios from "axios"

export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  actions: {
    async GET_DATA (context, masterclassId) {
      const masterclassResponse = (await axios.get(`/api/v6/masterclasses/${masterclassId}`))
      context.commit('setData', masterclassResponse.data.masterclass)
    },
    async STORE_ATTENDEE(context, payload) {
      const postPayload = { record: JSON.stringify(payload) }
      const recordResponse = (await axios.post(`/api/v6/masterclasses/records/${payload.recordId}`), postPayload)
      console.log(recordResponse.data)
    }
  },
  mutations: {
    setData (state, payload) {
      state.data = payload
    }
  }
}