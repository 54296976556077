<template>
    <div class="col-12 mb-3">
        <div class="Ticket_Card">
            <div class="row">
                <div class="col-xxl-8">
                    <div class="row">
                        <div class="col-12 mb-">
                                <h3>#{{ ticket.zendesk_id }}</h3>
                        </div>
                        <div class="col-md-3">
                                <img :src="productThumbnail" alt="">
                        </div>
                        <div class="col-md-9">
                            <ul>
                                <li>{{ ticket.serial.code }}</li>
                                <li>{{ ticket.serial.product.mmitno }}</li>
                                <li>{{ ticket.serial.product.label?.name ?? ticket.serial.product.mmitds }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4">
                    <div class="row">
                        <div class="col-6 col-xxl-12 mb-3">
                            <h6>Statut et prise en charge</h6>
                            <StatusBadge :status="ticket.status" size="Small" v-if="ticket.status"/>
                            <SupportBadge :support="ticket.support" size="Small" v-if="ticket.support"/>
                        </div>
                        <div class="col-6 col-xxl-12">
                            <RelatedExpertise :expertise="ticket.returnorder?.expertise" v-if="ticket.returnorder"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from './../../../Common/Badges/StatusBadge.vue'
import SupportBadge from './../../../Common/Badges/SupportBadge.vue'
import RelatedReturnOrder from './RelatedReturnOrder.vue';
import RelatedExpertise from './RelatedExpertise.vue';
export default {
    components: { StatusBadge, SupportBadge, RelatedExpertise, RelatedReturnOrder },
    name: 'TicketCard',
    props: { ticket: { type: Object, default: null }},
    computed: {
        productThumbnail() {
            return `https://img.tandem-cycles.fr/img/cycleurope/bikes/big/${this.ticket.serial.product.mmitno.substring(0, 6)}.jpg`;
        },
    }
}
</script>

<style lang="scss" scoped>
.Ticket_Card {
    background-color: white;
    padding: 1.5rem 2rem;
    border-radius: 0.35rem;
    border: 1px solid hsl(220deg, 30%, 90%);
    margin-bottom: -1px;
    transition: all 1s;

    &:hover {
        box-shadow:  0px 4px 16px rgba(0,0,0, 0.08);
    }
    .Product_Image {
        width: 160px;
    }
    .Badges {
        display: flex;
        gap: 0.5rem;
    }
    h6 {
        font-size: 0.8rem;
    }
}
</style>