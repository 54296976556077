<template>
  <div>
    <h2 class="mb-3">Metrics</h2>

    <div v-if="processing">
      <div class="alert alert-flat">
        Chargement ...
      </div>
    </div>
    <div v-if="metrics" class="card metrics-card mb-3">
      <div class="card-body">
        <div class="label mb-2">Demande résolue en</div>
        <div class="display mb-2">
          {{ metrics.first_resolution_time_in_minutes.business }} minutes
        </div>
        <div class="unit">
          {{ metrics.first_resolution_time_in_minutes.calendar }} minutes
          calendaires.
        </div>
      </div>
    </div>
    <div v-if="metrics" class="card metrics-card mb-3">
      <div class="card-body">
        <div class="label mb-2">Première réponse en</div>
        <div class="display mb-2">
          {{ metrics.reply_time_in_minutes.business }} minutes
        </div>
        <div class="unit">
          {{ metrics.reply_time_in_minutes.calendar }} minutes calendaires.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketMetrics",
  components: {},
  data() {
    return {
      processing: true,
      metrics: null,
    };
  },
  props: {
    ticket: Object,
  },
  created() {
    this.getMetrics();
  },
  methods: {
    getMetrics() {
      axios
        .get("/api/v2/metrics/ticket/" + this.ticket.zendesk_id)
        .then((response) => {
          this.metrics = response.data.ticket_metric;
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &.metrics-card {
    border: 0 !important;
    .card-body {
      padding: 2rem;
      background: #ffffff;
      .label {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
      .display {
        font-size: 3rem;
        line-height: 3rem;
        font-family: "Cera Pro Bold";
      }
      .unit {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }
  }
}
</style>
