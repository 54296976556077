<template>
  <div class="Card_Shop_Overview">
    <div class="Card_Shop_Overview__Body">
        <div class="Message mb-3" v-if="emptyInformation">
            Veuillez remplir les informations nécessaires au référencement de votre point de vente.
        </div>
        <div>
            <span v-if="getShopName.length"><h2>{{ getShopName }}</h2></span>
            <span v-else class="Text_Empty">Nom à renseigner</span>
        </div>
        <div>
            <span v-if="getShopAddress1.length">{{ getShopAddress1 }}</span>
            <span v-else class="Text_Empty">Addresse à renseigner</span>
        </div>
        <div>
            <span v-if="getShopAddress2.length">{{ getShopAddress2 }}</span>
            <span v-else class="Text_Empty">Addresse à renseigner</span>
        </div>
        <div>{{ getShopPostalCode }} {{ getShopCity }}</div>
        <div v-if="minimumInformationRequired">Point de vente valide</div>
        <div>
            <button :disabled="!minimumInformationRequired">Valider</button>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'ShopOverviewCard',
    computed: {
        ...mapGetters('user', ['getShopName', 'getShopAddress1', 'getShopAddress2', 'getShopPostalCode', 'getShopCity', 'getShopPhone', 'getShopEmail']),
        emptyInformation: function() {
            return !this.getShopName && !this.getShopAddress1 && !this.getShopAddress2 && !this.getShopCity && !this.getShopPhone && !this.getShopEmail
        },
        minimumInformationRequired: function() {
            return this.getShopName && this.getShopAddress1 && this.getShopPostalCode && this.getShopCity
        }
    }
}
</script>

<style lang="scss" scoped>
.Card_Shop_Overview {
    background-color: white;
    border-radius: 0.4rem;
    &__Body {
        padding: 2rem 2.5rem;
    }
}
.Message {
    background-color: hsl(240, 58%, 97%);
    color: hsl(240deg, 50%, 50%);
    padding: 1.25rem 1.5rem;
    border-radius: 0.4rem;
}
.Text_Empty {
    color: lightgray;
}
</style>