<template>
  <div class="Information_Container" v-if="messages.length">
    <div class="row">
        <h2 class="mb-3">Informations</h2>
        <InformationItem v-for="item in messages" :key="item.id" :message="item" />
    </div>
  </div>
</template>

<script>
import InformationItem from './InformationItem.vue'
import { mapState } from "vuex"
export default {
    components: { InformationItem },
    name: 'InformationContainer',
    computed: {
      ...mapState('statamic/messages', ['messages'])
    },
    created() {
        this.$store.dispatch('statamic/messages/GET_MESSAGES')
    }
}
</script>

<style>

</style>