<template>
  <div class="Alert_Fetching">Fetching ...</div>
</template>

<script>
export default {
    name: 'FetchingAlert',
}
</script>

<style lang="scss" scoped>
.Alert_Fetching {
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.25rem 1.5rem;
}
</style>