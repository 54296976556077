<template>
  <form :action="action" method="POST" @keydown.enter="$event.preventDefault()">
    <input type="hidden" name="_token" :value="csrfToken" />
    <div class="row" v-show="true">
      <div class="col-lg-8">
        <Steps />
        <CauseStep v-show="store.step == 1" />
        <ContextStep v-show="store.step == 2" />
        <SerialStep v-show="store.step == 3" />
        <InformationStep v-show="store.step == 4" />
        <AttachmentsStep v-show="store.step == 5" />
        <SummaryStep v-if="store.step == 6" />
        <div class="d-grid">
          <input type="submit" value="Valider la demande de garantie" class="btn btn-lg btn-primary" v-if="store.step == 6">
        </div>
      </div>
      <div class="col-lg-4">
        <ProgressCard v-if="store.step < 6"/>
      </div>
    </div>
  </form>
</template>

<script setup>
import Steps from "./steps/Steps.vue";
import CauseStep from "./partials/CauseStep.vue";
import ContextStep from "./partials/ContextStep.vue";
import SerialStep from "./partials/SerialStep.vue";
import InformationStep from "./partials/InformationStep.vue";
import AttachmentsStep from "./partials/AttachmentsStep.vue";
import SummaryStep from "./partials/SummaryStep.vue";
import ProgressCard from "./partials/progress/Progress.vue";

import { useClaimStore } from '../../../../store/useClaimStore.js'
import { ref } from "vue";
const store = useClaimStore()

const props = defineProps({
  skipIntro: {
    type: Boolean,
    default: false
  },
  user: {
    type: String,
    default: null
  },
  action: {
    type: String,
    required: false,
  }
})

const csrfToken = ref(document.querySelector('meta[name="csrf-token"]').getAttribute('content'))

</script>
