<template>
  <div class="MU_Wrapper">
    <div v-if="acceptInfo"><small class="mu_AcceptInfo">{{ acceptInfo }}</small></div>
    <div class="MU_FileUploadZone" v-if="canAddMedia">
      <label :for="id+'-file-input'" class="MU_PlusBox">{{ label }}</label>
      <input :id="id+'-file-input'" type="file" multiple hidden @change="fileChange" :accept="acceptFiles">
    </div>
    <div v-if="!allMediaList.length" class="mu_noMedia">
      <span>Aucun fichier</span>
    </div>
    <div v-else class="MU_MediaList">
      <ul>
        <li v-for="(item, i) in queueList" :key="i">
          <MediaItem :media="item" @remove="removeFromQueueList"/>
        </li>
        <li v-for="(item, i) in mediaList" :key="i">
          <MediaItem :media="item" @remove="removeFromMediaList"/>
        </li>
      </ul>
    </div>
    <input
      v-for="(media, i) in queueList"
      :key="i" type="text"
      name="media_queued[]"
      :value="media.name"
      hidden />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import MediaItem from './MediaItem.vue';
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  maxFiles: {
    type: Number,
    default: 1
  },
  uploadEndpoint: {
    type: String,
    required: true
  },
  acceptFiles: {
    type: String,
    required: false,
    default: "image/*,.pdf"
  },
  acceptInfo: {
    type: String,
    required: false,
    default: 'Fichiers acceptés : images, PDF'
  },
  label: {
    type: String,
    required: false,
    default: 'Upload files here'
  }
})

const emit = defineEmits(['update'])

const mediaList = ref([])
const queueList = ref([])

const allMediaList = computed(() => {
  return [...mediaList.value, ...queueList.value]
})

const canAddMedia = computed(() => {
  return allMediaList.value < props.maxFiles
})

const fileChange = async (event) => {
  let medias = event.target.files
  console.log(medias)
  for (var i = 0; i < medias.length; i++) {
    if (allMediaList.value.length < props.maxFiles) {
      let formData = new FormData
      let url = URL.createObjectURL(medias[i])
      formData.set('media', medias[i])
      const { data } = await axios.post(props.uploadEndpoint, formData)
      let newMedia = {
        url: url,
        name: data.name,
        size: medias[i].size,
        type: medias[i].type
      }
      queueList.value.push(newMedia)
      emit('update', queueList.value)
    }
  }
}

const removeFromMediaList = (index) => {
  mediaList.value.splice(index, 1)
}

const removeFromQueueList = async (index) => {
  let media = queueList.value.splice(index)
  queueList.value.splice(index, 1)
  emit('update', allMediaList.value)
}

</script>

<style class="scss" scoped>
.MU_Wrapper {
  .mu_AcceptInfo {
    color: #2563eb;
    font-weight: 500;
  }
  .MU_FileUploadZone {
    background-color: #c7d2fe;
    color: #1e1b4b;
    border-radius: 0.5rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    .MU_PlusBox {
      background: #c7d2fe;
      color: #1e1b4b;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      transition: all 200ms;
      cursor: pointer;
      &:hover {
        background: #818cf8;
      }
    }
  }
  .MU_MediaList {

  }
  .mu_noMedia {
    color: #7e22ce;
    font-weight: 500;
  }
}
</style>