import axios from "axios"
import { isObject } from "lodash"
import { bearerTokenAuthAxios } from "../../../clients"

export default {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {},
    actions: {
        async GET_LIVE_FEED (context, payload) {
            const livefeedResponse = (await bearerTokenAuthAxios.get(`/api/v6/livefeed`))
            context.commit('setData', livefeedResponse.data.data)
        },
        ADD_LIVE_NOTIFICATION (context, payload) {
            context.commit('setData', [payload])
        }
    },
    mutations: {
        setData (state, payload) {
            state.data = [...payload, ...state.data]
        },
    }
}