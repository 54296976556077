<template>
    <div>
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="email-notifications" :value="localEmailNotifications" v-model="localEmailNotifications" @change="handleEmailNotificationsValueChanged">
                    <label class="form-check-label" for="email-notifications">{{ $t('mailNotifications') }}</label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="text-notifications" :value="localTextNotifications" v-model="localTextNotifications" @change="handleTextNotificationsValueChanged">
                    <label class="form-check-label" for="text-notifications">{{ $t('textNotifications') }}</label>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="localTextNotifications" class="row mb-3">
                <label for="exampleFormControlInput1" class="col-lg-4 mb-3 col-form-label">Numéro de téléphone par défaut</label>
                <div class="col-lg-8 mb-3"><input type="text" class="form-control" v-model="localTextNumber" @input="handleTextNumberValueChanged" maxlength="10"></div>
                <div class="col-12">
                    <div class="alert alert-warning">Pour recevoir les notifications SMS lors du suivi de vos demandes de garantie, votre numéro de portable doit impérativement être renseigné avec le format <span class="fw-bold">0612345678</span>.</div>
                </div>
            </div>
        </transition>
        <div v-if="env == 'production'">
            <ul>
                <li>Email notifications : {{ localEmailNotifications }}</li>
                <li>Text Notifications : {{ localTextNotifications }}</li>
                <li>Text Number{{ localTextNumber.length }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import {serverBus} from '../../../frontend/app.js'
import Toast from "vue-toastification"
const options = {}
Vue.use(Toast, options)

export default {
    name: 'NotificationsFormComponent',
    data() {
        return {
            env: "development",
            localEmailNotifications: null,
            localTextNotifications: null,
            localTextNumber: '',
            localCurrentTextNumber: '',
        }
    },
    props: {
        emailNotifications: Number,
        textNotifications: Number,
        textNumber: String,
    },
    watch: {
        emailNotifications: function() {
            this.localEmailNotifications = this.emailNotifications
        },
        textNotifications: function() {
            this.localTextNotifications = this.textNotifications
        },
        textNumber: function() {
            this.localTextNumber = this.textNumber
        }
    },
    created() {
        this.localEmailNotifications = this.emailNotifications
        this.localTextNotifications = this.textNotifications
        this.localTextNumber = this.textNumber
    },
    methods: {
        handleEmailNotificationsValueChanged() {
            axios
                .post("/notifications/email", {
                    _method: 'patch',
                    notifications: this.localEmailNotifications,
                })
                .then((response) => {
                    if(response.status == 200) {
                        if(this.localEmailNotifications == true) {
                            this.$toast.success("Les notifications e-mail sont activées.")
                            serverBus.$emit('update-mail-notifications-status', 1);
                        } else {
                            this.$toast("Les notifications e-mail sont désactivées.")
                            serverBus.$emit('update-mail-notifications-status', 0)
                        }
                    }
                });
        },
        handleTextNotificationsValueChanged() {
            axios
                .post("/notifications/text", {
                    _method: 'patch',
                    notifications: this.localTextNotifications,
                })
                .then((response) => {
                    if(response.status == 200) {
                        if(this.localTextNotifications == true) {
                            this.$toast.success("Les notifications par SMS sont activées.")
                            serverBus.$emit('update-text-notifications-status', 1)
                        } else {
                            this.$toast("Les notifications par SMS sont désactivées.")
                            serverBus.$emit('update-text-notifications-status', 0)
                        }
                    }
                });
        },
        handleTextNumberValueChanged: function() {
            if(this.localTextNumber.length == 10) {
                console.log('Update Text Number')
                axios.post("/notifications/text/update-number", {
                    _method: 'patch',
                    number: this.localTextNumber,
                })
                .then((response) => {
                    if(response.status == 200) {
                        this.$toast.success("Vous recevrez maintenant les notifications SMS sur le "+this.localTextNumber+".");
                    }
                })
            } else if(this.localTextNumber.length == 0) {
                console.log('Update Text Number')
                axios.post("/notifications/text/update-number", {
                    _method: 'patch',
                    number: '',
                })
                .then((response) => {
                    if(response.status == 200) {
                        this.$toast("Vous n'avez plus de numéro par défaut pour recevoir les notifications SMS.");
                    }
                })
            }
        },
    }
}
</script>