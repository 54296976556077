<template>
  <button 
    class="Page_Link" 
    v-html="link.label" 
    :class="activeClass"
    v-on:click="getPostsFrom(link.url)"></button>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'PageLink',
    props: { link: { type: Object, default: null }},
    computed: {
        ...mapState('statamicPosts', ['data']),
        activeClass: function() {
            if (this.link.url === null) {
                return 'disabled'
            } else if (this.data.current_page == this.data.links.indexOf(this.data.links.find(link => link === this.link))) {
                return 'active'
            }
        }
    },
    methods: {
        getPostsFrom(url) {
            if (url !== null) {
                console.log(`getPostsFrom > ${url}`)
                this.$store.dispatch('statamicPosts/SET_POSTS_URL', url)
                this.$store.dispatch('statamicPosts/GET_DATA')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.Page_Link {
    border: 0;
    background-color: hsl(220deg, 30%, 98%);
    color: hsl(220deg, 80%, 30%);
    padding: 0.25rem 0.75rem;
    border-radius: 0.15rem;
    transition: all .1s;
    &:hover {
        background-color: hsl(220deg, 30%, 94%);
    }
    &.active {
        background-color: hsl(220deg, 90%, 44%);
        color: white;
    }
    &.disabled {
        background-color: hsl(220deg, 30%, 98%);
        color: hsl(220deg, 30%, 88%);
    }
}
</style>