<template>
  <div class="Card_Post h-100">
    <img :src="post.thumbnail?.permalink" alt="" v-if="post.thumbnail?.permalink">
    <div class="Card_Post__Body">
      <h3>{{ post.title }}</h3>
      <a :href="theLink">Lire l'article<fa-icon class="ms-2" icon="fa-solid fa-arrow-right" /></a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PostCard',
    props: { post: { type: Object, default: null }},
    computed: {
      theExcerpt: function() {
        return this.post.content.replace(/<[^>]*>?/gm, '').substr(0, 90) + '...'
      },
      theLink: function () {
        return `/posts/${this.post.slug}`
      }
    }
}
</script>

<style lang="scss" scoped>
.Card_Post {
    background-color: white;
    border-radius: 0.4rem;
    border: 1px solid hsl(220deg, 30%, 90%);
    transition: box-shadow 0.5s ease-out, border 0.3s ease-out;
    overflow: hidden;
    &__Body {
      padding: 1.5rem 2rem;
    }
    &:hover {
        //box-shadow:  0px 4px 16px rgba(0,0,0, 0.08);
        border: 1px solid hsl(220deg, 80%, 60%);

    }
    h3 {
      font-size: 1.25rem;
    }
    .Exceprt {
      font-size: 0.85rem;
    }
}
</style>