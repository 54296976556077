<template>
    <div class="card p-4 p-md-5">
        <div class="mb-3">
            <h3 class="mb-3"><span class="fw-semibold">1<i class="bi bi-chevron-compact-right"></i>Cause</span></h3>
            <select id="cause" name="cause" class="form-control form-control-lg" @change="handleChange" required>
                <option v-for="cause in store.causes" :value="cause.identifier" :key="cause.identifier">{{ cause.label }}</option>
                <option value="" selected disabled>Sélectionner</option>
            </select>
        </div>
        <div class="text-end">
            <button
                class="btn btn-lg btn-primary"
                v-on:click.prevent="handleSubmit"
                v-bind:class="{ disabled: !store.selectedCause }"
            >Etape suivante<i class="bi bi-chevron-right ms-2"></i>
            </button>
        </div>
    </div>
</template>

<script setup>
import { useClaimStore } from '@/store/useClaimStore'
const store = useClaimStore()

const handleChange = (e) => {
  store.setCause(e.target.value)
}

const handleSubmit = () => {
  store.nextStep()
}
</script>
