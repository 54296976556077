<template>
  <div class="Stepper">
    <step-card :active-step="1" title="Cause" />
    <step-card :active-step="2" title="Contexte" />
    <step-card :active-step="3" title="Identification du produit" />
    <step-card :active-step="4" title="Informations" />
    <step-card :active-step="5" title="Fichiers attachés" />
    <step-card :active-step="6" title="Récapitulatif" />
  </div>
</template>

<script setup>

import { useClaimStore } from '../../../../../store/useClaimStore'
const store = useClaimStore()

import { ref } from "vue";
import StepCard from "./StepCard.vue";
</script>

<style lang="scss" scoped>
.Stepper {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;  
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
  }
}
</style>