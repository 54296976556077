<template>
    <div>
        <available-labour-requests />
        <div>Formulaire de déclaration de MO.</div>
    </div>
</template>

<script>
import { getState } from 'vuex';
import AvailableLabourRequests from './AvailableLabourRequests.vue';
export default {
    components: { AvailableLabourRequests },
    name: 'LabourRequestForm',
}
</script>

<style>

</style>