<template>
    <div class="row">
        <div class="col-12 mb-3">
            <FetchingAlert v-show="isFetchingShop" />
        </div>
        <div class="col-xl-7 col-xxl-8 mb-3">
            <div class="Card_Form">
                <div class="Card_Form__Body">
                    <div class="form-group row">
                        <label for="name" class="col-form-label col-xxl-4 mb-2">Nom du point de vente :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control form-control-lg" id="name" v-model="name" /></div>
                    </div>
                    <div class="form-group row">
                        <label for="address1" class="col-form-label col-xxl-4 mb-2">Adresse (ligne 1) :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control" v-model="address1" /></div>
                    </div>
                    <div class="form-group row">
                        <label for="address2" class="col-form-label col-xxl-4 mb-2">Adresse (ligne 2) :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control" v-model="address2" /></div>
                    </div>
                    <div class="form-group row">
                        <label for="postalcode" class="col-form-label col-xxl-4 mb-2">Code postal :</label>
                        <div class="col-xxl-4 mb-3"><input class="form-control" v-model="postalcode" /></div>
                    </div>
                    <div class="form-group row">
                        <label for="city" class="col-form-label col-xxl-4 mb-2">Ville :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control" v-model="city" /></div>
                    </div>
                    <div class="Spacer"></div>
                    <div class="form-group row">
                        <label for="phone" class="col-form-label col-xxl-4 mb-2">Téléphone :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control" v-model="phone" /></div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="col-form-label col-xxl-4 mb-2">Adresse e-mail :</label>
                        <div class="col-xxl-8 mb-3"><input class="form-control" v-model="email" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-5 col-xxl-4">
            <ShopOverviewCard />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FetchingAlert from './FetchingAlert.vue'
import ShopOverviewCard from './ShopOverviewCard.vue'
export default {
    components: { FetchingAlert, ShopOverviewCard },
    name: 'ShopContainer',
    props: {user: { type: String, default: null }},
    computed: {
        ...mapState('user', ['isFetchingShop', 'shop']),
        name: {
            get() { return this.getShopName },
            set(value) { this.$store.dispatch('user/SET_SHOP_NAME', value) },
        },
        address1: {
            get() { return this.getShopAddress1 },
            set(value) { this.$store.dispatch('user/SET_SHOP_ADDRESS_1', value) },
        },
        address2: {
            get() { return this.getShopAddress2 },
            set(value) { this.$store.dispatch('user/SET_SHOP_ADDRESS_2', value) },
        },
        postalcode: {
            get() { return this.getShopPostalCode },
            set(value) { this.$store.dispatch('user/SET_SHOP_POSTALCODE', value) },
        },
        city: {
            get() { return this.getShopCity },
            set(value) { this.$store.dispatch('user/SET_SHOP_CITY', value) },
        },
        phone: {
            get() { return this.getShopPhone },
            set(value) { this.$store.dispatch('user/SET_SHOP_PHONE', value) },
        },
        email: {
            get() { return this.getShopEmail },
            set(value) { this.$store.dispatch('user/SET_SHOP_EMAIL', value) },
        }
    },
    created() {
        this.$store.dispatch('user/GET_SHOP', this.user);
    }
} 
</script>

<style lang="scss" scoped>
.Spacer {
    height: 2rem;
}
label {
    font-weight: 600;
}
.Card_Form {
    background-color: white;
    border-radius: 0.4rem;
    &__Body {
        padding: 2rem 2.5rem;
    }
}
</style>