<template>
    <div v-if="data" class="row">
        <Post v-for="p in data.data" :post="p" :key="p.id" />
    </div>
</template>

<script>
import Post from './Post.vue'
import { mapState } from 'vuex'
export default {
    components: { Post },
    name: 'PostsList',
    computed: {
        ...mapState('statamic/posts', ['data']),
    },
    created() {
        this.$store.dispatch('statamic/posts/GET_DATA')
    },
}
</script>

<style>

</style>