window._ = require('lodash');
window.Swal = require('sweetalert2');
import 'bootstrap'

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Pusher from "pusher-js"
Pusher.logToConsole = true;
import Echo from "laravel-echo"

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '6cb11ef83ac859d7fc32',
    cluster: 'eu',
    encrypted: false
});