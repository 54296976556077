<template>
  <div class="col-xl-4 mb-3">
    <div class="card Card--Labour">
        <div class="card-body">
            Labour Request
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LabourRequestCard',
    props: { labourRequest: { type: Object, default: null }},
}
</script>

<style scoped lang="scss">
.card {
    &.Card--Labour {
    cursor: pointer;
    border: 1px solid rgb(240, 242, 244) !important;
    border-radius: 0.35rem;
    &:hover {
        border: 1px solid rgb(13, 84, 160) !important;
    }
}
}
</style>