<template>
  <div class="Message_Card">
    <div class="Card_Body">
      <div class="row">
        <div class="col-xl-2">
          <h2>{{ message.zendesk_id }}</h2>
        </div>
        <div class="col">
          <h4>{{ message.subject }}</h4>
          <div>{{ message.comment }}</div>
        </div>
        <div class="col-xl-3">
          <div class="Message_Datetime mb-2">
            {{ moment(message.created_at).format("LLLL") }}
          </div>
          <status-badge :status="message.status"></status-badge>
          <support-badge :support="message.support"></support-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "../Tickets/User/StatusBadge.vue";
import SupportBadge from "../Tickets/User/SupportBadge.vue";
import moment from "moment";

export default {
  name: "MessageCard",
  components: { StatusBadge, SupportBadge },
  props: { message: Object },
  created() {
    moment.locale("fr");
  },
  moment,
};
</script>

<style lang="scss" scoped>
.Message_Card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 0.5rem;
  .Card_Body {
  }
  .Message_Datetime {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  h2 {
    font-family: "Cera Pro Bold";
  }
}
</style>
