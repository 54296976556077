<script setup>
import {ref, computed} from "vue";
import MediaUploader from "./MediaUploader.vue";

const queueList = ref([])
const updateQueueList = (queue) => {
  queueList.value = queue
}

const canSubmit = computed(() => {
  return queueList.value.length > 0
})

</script>

<template>
  <div class="card-body">
    <h4 class="text-primary mb-3">Déclaration par lots de rapports de cadres VAE La Poste</h4>
    <div class="mb-3">
      <div class="Alert">
        <p>
          <a href="/laposte/Tableau_Rapports_Modele.xlsx">Téléchargez le fichier de rapports en exemple</a> et suivez
          les instructions avant de le téléverser.
          <br/>Le traitement du fichier de rapports de cadres VAE La Poste peut durer quelques instants suivant le
          nombre de lignes déclarées.</p>
      </div>
    </div>
    <div class="mb-3">
      <label for=""><span class="fw-semibold">Fichier à téléverser :</span></label>
      <MediaUploader
          id="reports-mu"
          upload-endpoint="/support/upload/media"
          accept-files="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          accept-info="Fichiers acceptés : .xls, .xlsx"
          label="Choisir le fichier à téléverser"
          @update="updateQueueList"
      />
    </div>
    <div class="mb-3">
      <input type="submit" :value="canSubmit ? 'Envoyer le rapport de cadre' : 'Complétez le formulaire...'"
             class="btn btn-lg btn-primary" :disabled="!canSubmit">
    </div>
    <input type="hidden" name="declaration_type" value="multiple"/>
  </div>
</template>

<style lang="scss" scoped>
.Alert {
  padding: 1.5rem 2rem;
  background-color: #f1f5f9;
  color: #172554;
  border-radius: 0.35rem;
}
</style>