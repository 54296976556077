<template>
  <div class="Documents_List">
    <draggable v-model="selectedDocuments" ghost-class="ghost" handle=".handle">
        <DocumentItem v-for="doc in selectedDocuments" :document="doc" :key="doc.id" />
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DocumentItem from './DocumentItem.vue'
import { mapState, mapGetters } from 'vuex'
export default {
    components: { DocumentItem, draggable },
    name: 'DocumentsList',
    computed: {
        ...mapState('documents', ['selectedDocuments']),
        ...mapGetters('documents', ['getSelectedDocuments']),
        selectedDocuments: {
            get() {
                return this.getSelectedDocuments
            },
            set(value) {
                this.$store.dispatch('documents/UPDATE_DOCUMENTS_ORDER', value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.ghost {
    background-color: rgb(67, 99, 195);
    color: white;
}
</style>