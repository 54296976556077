import { ref, computed } from "vue"
import { defineStore } from "pinia"
export const useTransportDamageStore = defineStore('claim', () => {

    const step = ref(1)

    const serialNumber = ref('')
    const serial = ref(null)
    const serialFetchError = ref('')
    const isFetchingSerial = ref(false)

    const mediaList1 = ref([])
    const mediaList2 = ref([])
    const mediaList3 = ref([])
    const mediaList4 = ref([])

    const mediaUploadsCompleted = computed(() => {
        return mediaList1.value.length && mediaList2.value.length && mediaList3.value.length && mediaList4.value.length
    })

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        step.value--
    }


    const fetchSerial = async () => {
        serialFetchError.value = ''
        isFetchingSerial.value = true
        const response = await fetch(`/api/v2/products/search/${serialNumber.value}`)
        const responseData = await response.json()
        serialFetchError.value = !responseData.reference && 'Aucun chassis ne correspond au code renseigné.'
        serial.value = responseData.serial
        isFetchingSerial.value = false
    }

    const cancelSerial = () => {
        serial.value = null
        serialFetchError.value = ''
    }

    return {
        step,

        serialNumber,
        serial,
        isFetchingSerial,
        serialFetchError,

        mediaList1,
        mediaList2,
        mediaList3,
        mediaList4,

        mediaUploadsCompleted,

        nextStep,
        prevStep,
        fetchSerial,
        cancelSerial
    }
})